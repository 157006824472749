@tailwind base;
@tailwind components;
@tailwind utilities;


/*
  Theme Name: Zoso - SEO & Digital Marketing Agency React Template
  Theme URL: https://react.mediacity.co.in/zoso
  Author: Media City
  Author URI: https://mediacity.co.in/
  Creation Date: 29 April 2024
  Description: A default stylesheet for Zoso - SEO & Digital Marketing Agency React Template
  Version: 1.0
*/
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
* Variable
* Common
* Animations
* Header
* Banner
* About
* Whychoose
* Joinus
* Testimonial
* Cta
* Blog
* Brand
* Footer
* Mobile-Drawer
* features
* Service
* Side-drawer
* Search-popup
* Partener
* Fact
* Case
* Video
* Process
* Peoject
* Business-grow
* Package
* Quoye
* Page-header
* Team-member
* Digital-market
* Projects
* Faq
* Blog-page
* Sidebar
* Blog-Detail-page
* Shop
* Author
* Comment
* Checkout
* Contact-page
--------------------------------------------------------------*/
/***********************
* Variable
***********************/
:root {
    --zoso-secondary-light: #5F5F5F;
    --zoso-secondary-light-two: #555555;
    --zoso-secondary-grey: #696969;
    --zoso-text-dark: #0F110F;
    --zoso-text-black: #000000;
    --zoso-text-dark-two: #0f110f1a;
    --zoso-text: #7A7A7A;
    --zoso-text-green: #00C39A;
    --zoso-text-yellow: #ffdb4a;
    --zoso-text-yellow-10: #E9D1751a;
    --zoso-text-yellow-two: #f5cf6f1a;
    --zoso-text-yellow-gradient: #F5CF6F;
    --zoso-text-blue: #9896FF;
    --zoso-text-dark-blue: #5C00EC;
    --zoso-text-dark-blue-gradient: #9896FF;
    --zoso-text-blue-two: #9896FF1a;
    --zoso-text-blue-gradient: #8358FF;
    --zoso-bg-primary: #F17400;
    --zoso-text-primary: #F8466D;
    --zoso-text-primary-two: #F6466E;
    --zoso-text-primary-gradient: #FC466B;
    --zoso-text-primary-four: #FF6C6C;
    --zoso-text-light: #ffffff;
    --zoso-text-light-two: #ffffff8c;
    --zoso-text-light-three: #ffffff1a;
    --zoso-bg-grey: #F2F0E6;
    --zoso-bg-green: #60CBA1;
    --zoso-bg-green-three: #92EBC9;
    --zoso-bg-green-two: #93D5E1;
    --zoso-bg-cyan: #66EDFF;
    --zoso-bg-cyan-10: #66EDFF1a;
    --zoso-bg-cyan-gradient: #01CBDF;
    --zoso-bg-cyan-15: #66edff26;
    --zoso-bg-blue: #CA9BF9;
    --zoso-bg-purple: #F27AFF;
    --zoso-bg-purple-gray: #E2F2EC;
    --zoso-bg-purple-10: #f27aff1a;
    --zoso-gradient-one: linear-gradient(92deg, var(--zoso-text-primary-gradient) 0%, var(--zoso-text-blue-gradient) 100.14%);
    --zoso-gradient-two: linear-gradient(81deg, var(--zoso-text-yellow-gradient) 16.42%, var(--zoso-text-green) 80.31%);
    --zoso-gradient-three: linear-gradient(110deg, var(--zoso-bg-cyan-gradient) 15.93%, var(--zoso-text-blue-gradient) 72.14%);
    --zoso-bg-light: #ffffff;
    --bs-font-DM-Sans: "DM Sans", system-ui, -apple-system, Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-Inter: "Inter", system-ui, -apple-system, Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-lexend-deca: "Lexend Deca", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

html {
    scroll-behavior: smooth;
}

/*******************
* Preloader
*******************/
.preloader {
    position: fixed;
    background-color: #0f110ff7;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
}

.preloader-circle {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
}

.preloader-circle::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 150px;
    height: 150px;
    background: url(../images/resources/preloader.png)no-repeat;
    background-size: cover;
    animation: preloader-rotate 1s linear infinite;

}

@keyframes preloader-rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*************************
* Custom-cursor 
*************************/

.custom-cursor-one {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 1px solid var(--zoso-text-primary);
    -webkit-transition: all 200ms ease-out;
    transition: all 200ms ease-out;
    position: fixed;
    pointer-events: none;
    left: 0;
    top: 0;
    -webkit-transform: translate(calc(-50% + 5px), -50%);
    transform: translate(calc(-50% + 5px), -50%);
    z-index: 999991;
}

.custom-cursor-two {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: var(--zoso-text-primary);
    opacity: .3;
    position: fixed;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    pointer-events: none;
    -webkit-transition: width .3s, height .3s, opacity .3s;
    transition: width .3s, height .3s, opacity .3s;
    z-index: 999991;
}

.custom-cursor-hover {
    background-color: var(--zoso-text-primary-four);
    opacity: 0.4;
}

.custom-cursor-innerhover {
    width: 25px;
    height: 25px;
    opacity: .4;
}

/***********************
* Common
***********************/
body {
    font-family: var(--bs-font-DM-Sans);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

img {
    max-width: 100%;
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.2px;
    line-height: 27px;
    text-decoration: none;
    color: var(--zoso-text);
}

h1,
.h1 {
    font-size: 65px;
    font-weight: 700;
    color: var(--zoso-text-dark);
    line-height: 77px;
    margin-bottom: 50px;
    letter-spacing: -0.3px;
}

h2,
.h2 {
    font-size: 45px;
    color: var(--zoso-text-dark);
    font-weight: 700;
    line-height: 54px;
    letter-spacing: -0.3px;
    margin-bottom: 0;
}

h3,
.h3 {
    font-size: 27px;
    font-weight: 600;
    line-height: 41px;
    letter-spacing: 0.2px;
    color: var(--zoso-text-dark);
    margin-bottom: 0;
}

h4,
.h4 {
    font-size: 24px;
    font-weight: 600;
    color: var(--zoso-text-dark);
    line-height: 1;
    letter-spacing: -0.3px;
    margin-bottom: 0;
}

h5,
.h5 {
    margin-bottom: 0;
    color: var(--zoso-dark);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.48px;
}

h6,
.h6 {
    margin-bottom: 0;
}

p {
    font-size: 20px;
    font-family: var(--bs-font-lexend-deca);
    color: var(--zoso-secondary-light);
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    margin-bottom: 0;
}

.thm-btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    vertical-align: middle;
    -webkit-appearance: none;
    outline: none !important;
    font-size: 14px;
    font-weight: 700;
    padding: 20px 30px;
    overflow: hidden;
    transition: all 0.5s linear;
    z-index: 1;
    letter-spacing: 0;
}

.thm-btn i {
    color: var(--zoso-text-dark);
    font-size: 11px;
    font-weight: 600;
    transition: all 0.3s;
}

.thm-btn:hover i {
    transform: rotate(-45deg);
}

.main-menu-btn {
    padding: 14px 30px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    color: var(--zoso-text-light);
    background: var(--zoso-text-dark);
    border: 1px solid var(--zoso-text-dark);
    transition: all 0.3s;
    border-radius: 12px;
}

.main-menu-btn:hover {
    background-color: var(--zoso-text-yellow);
    border: 1px solid var(--zoso-text-yellow);
    color: var(--zoso-text-hover-dark);
}

.main-black-btn i,
.main-menu-btn i {
    color: var(--zoso-text-light);
}


.main-black-btn:hover i,
.main-menu-btn:hover i {
    color: var(--zoso-text-dark);
}

.main-black-btn {
    padding: 16px 40px;
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    color: var(--zoso-text-light);
    background: var(--zoso-text-dark);
    border: 1px solid var(--zoso-text-dark);
    transition: all 0.3s;
    border-radius: 12px;
}

.main-black-btn:hover {
    background-color: var(--zoso-text-yellow);
    border: 1px solid var(--zoso-text-yellow);
    color: var(--zoso-text-hover-dark);
}

.main-btn {
    padding: 16px 40px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21PX;
    background-color: var(--zoso-text-blue);
    border: 1px solid var(--zoso-text-blue);
    color: var(--zoso-text-dark);
    transition: all 0.3s;
    border-radius: 10px;
}

.main-btn:hover {
    background-color: var(--zoso-text-green);
    color: var(--zoso-text-light);
    border: 1px solid var(--zoso-bg-green);
}

.main-btn:hover i {
    color: var(--zoso-text-light);
}

.read-more-btn {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    color: var(--zoso-text-dark);
    font-weight: 700;
    line-height: 21px;
    transition: all 0.3s;
}

.read-more-btn:hover {
    color: var(--zoso-text);
}

.read-more-btn i {
    font-size: 12px;
    font-weight: 600;
    transition: all 0.3s;
    color: var(--zoso-text-dark);
}

.read-more-btn:hover i {
    color: var(--zoso-text);
}

.section-title h2 {
    font-size: 45px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: -0.3px;
    margin-bottom: 0;
    color: var(--zoso-text-dark);
}

.section-details p {
    font-size: 18px;
    font-family: var(--bs-font-lexend-deca);
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 0;
}

.row {
    --bs-gutter-x: 1.875rem;
}

.row>* {
    padding-left: 15px;
    padding-right: 15px;
}


/***********************
* Header-one
***********************/


.main-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
}

.main-header.sticky-fixed {
    box-shadow: 0 7px 50px 0 rgba(0, 0, 0, .1);
    background-color: var(--zoso-bg-light);
    position: fixed;
    transform: translateY(0);
    z-index: 999;
    transition: all 1.5s;
}

.sticky-header--cloned {
    position: fixed;
    z-index: 991;
    top: 0;
    left: 0;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-120%);
}

.sticky-header--cloned.sticky-fixed {
    transform: translateY(0);
    opacity: 1;
    transition: all 0.5s;
    visibility: visible;
}

.main-header-two.sticky-fixed {
    box-shadow: 0 7px 50px 0 rgba(0, 0, 0, .1);
    background-color: var(--zoso-text-dark);
    position: fixed;
    transform: translateY(0);
}

.main-header .container-fluid {
    max-width: 1534px;
}

.main-header-two .container-fluid {
    max-width: 1644px;
}

.main-header.sticky-fixed .main-menu {
    margin-top: 0;
    margin-bottom: 0;
}

.main-header.sticky-fixed .main-menu>ul>li {
    padding: 30px 0;
}

.main-header-two.sticky-fixed .main-menu-left>ul>li {
    padding: 30px 0;
}

.main-header-five {
    background-color: var(--zoso-text-dark);
}

.main-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.main-menu-logo a img,
.footer-bottom-inner-two-logo {
    width: 122px;
}

.main-menu-list {
    display: flex;
}

.menu-item-children {
    position: relative;
}

.menu-item-children>a>i {
    font-size: 8px;
    padding-left: 5px;
    margin-top: 3px;
}

.main-menu-list .menu-item-children>i::before {
    color: var(--zoso-text);
    transition: all 0.3s;
}

.main-menu-list-two .menu-item-children>i::before {
    color: var(--zoso-text-light);
    transition: all 0.3s;
}

.main-menu-list>li {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
}

.main-menu-list>li>i {
    font-size: 8px;
    margin-left: 5px;
    padding-top: 3px;
}

.main-menu-list>li+li {
    margin-left: 40px;
}

.main-menu-right {
    display: flex;
    align-items: center;
}

.main-menu .main-menu-right a+a {
    margin-left: 30px;
}

.main-menu-two .main-menu-right a+a {
    margin-left: 40px;
}

.main-menu-signup-btn {
    font-size: 18px;
    font-style: normal;
    color: var(--zoso-text-light);
    font-weight: 700;
    line-height: 23px;
}

.main-menu-right-number i {
    margin-right: 15px;
}

.main-menu-right-number:hover {
    color: var(--zoso-bg-2-1);
}

.main-menu-list>li>ul,
.main-menu-list>li>ul>li>ul {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 260px;
    list-style: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    opacity: 0;
    visibility: hidden;
    transform-origin: top center;
    transform: scaleY(0) translateZ(100px);
    transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease;
    z-index: 999;
    padding: 0;
    background-color: var(--zoso-bg-dark);
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.09);
    transition: all 0.5s;
}

.main-menu-list>li:hover>a {
    color: var(--zoso-text-dark);
}

.main-menu-list-two>li:hover>a {
    color: var(--zoso-text-primary);
}

.main-menu-list>li>ul>li:hover>a,
.main-menu-list>li>ul>li>ul>li:hover>a {
    padding: 12px 20px 12px 30px;
}

.main-menu-list>li:hover>i::before {
    color: var(--zoso-text-dark);
}

.main-menu-list-two>li:hover>i::before {
    color: var(--zoso-text-primary);
}

.main-menu-list>li>a {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    transition: all 0.3s;
}

.main-menu-list>li>ul>li>a,
.main-menu-list>li>ul>li>ul>li>a {
    position: relative;
    font-size: 18px;
    line-height: 30px;
    text-decoration: none;
    color: var(--zoso-dark);
    font-weight: 500;
    padding: 12px 20px 12px 20px;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-menu-list>li>ul>li>ul>li>a {
    border-bottom: 1px solid transparent;
}

.main-menu-list>li>a {
    background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 100%/0px 1px no-repeat;
    color: var(--zoso-text-dark);
    transition: all 500ms ease;
    text-decoration: none;
}

.main-menu-list>li.current>a,
.main-menu-list>li:hover>a {
    background-size: 100% 1px;
}

.main-menu-list-two>li.current>a,
.main-menu-list-two>li:hover>a {
    background-size: 100% 1px;
}

.main-menu-list>li.current>i::before {
    color: var(--zoso-text-dark);
}

.main-menu-list-two>li.current>i::before {
    color: var(--zoso-text-primary);
}

.main-menu-list>li.current>a {
    color: var(--zoso-text-dark);
}

.main-menu-list-two>li.current>a {
    color: var(--zoso-text-primary);
}

.main-menu-list>li>ul>li>a>i {
    font-size: 18px;
    transition: all 0.3s;
    color: var(--zoso-text-dark);
}

.main-menu-list>li>a {
    font-weight: 600;
    color: var(--zoso-text);
}

.main-menu-list-two>li>a {
    font-weight: 600;
    color: var(--zoso-text-light);
}

.main-menu-list>li>ul>li>a>i::before {
    transition: all 0.3s;
}

.main-menu-list>li>ul>li:hover,
.main-menu-list>li>ul>li>ul>li:hover {
    background-color: var(--zoso-text-black);
}

.main-menu-list>li>ul>li:hover a>i::before,
.main-menu-list>li>ul>li>ul>li:hover a>i::before {
    color: var(--zoso-text-light);
}

.main-menu-list>li>ul>li:hover a>i,
.main-menu-list>li>ul>li>ul>li:hover a>i {
    transform: rotate(-90deg);
}

.main-menu-list>li>ul>li:hover>a,
.main-menu-list>li>ul>li>ul>li:hover>a {
    color: var(--zoso-text-light);
}

.main-menu-list>li:hover>ul,
.main-menu-list>li>ul>li:hover>ul {
    opacity: 1;
    transform: scaleY(1) translateZ(0px);
    visibility: visible;
}

.main-menu-list>li>ul>li,
.main-menu-list>li>ul>li>ul>li {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    width: 100%;
    position: relative;
    background-color: var(--zoso-text-light);
    transition: all 0.3s;
}

.main-menu-list>li>ul>li+li,
.main-menu-list>li>ul>li>ul>li+li {
    border-top: 1px solid var(--zoso-text-dark-two);
}

.main-menu-list>li>ul>li>ul {
    top: -1px;
    left: 100%;
}

.header-right-end {
    position: relative;
    width: 28px;
    height: 21px;
    margin-left: 30px;
    cursor: pointer;
    display: none;
}

.header-right-end span:nth-child(1) {
    position: absolute;
    width: 20px;
    height: 3px;
    background-color: var(--zoso-text-dark);
    border-radius: 10px;
    left: 0;
    top: 0;
}

.header-right-end span:nth-child(2) {
    position: absolute;
    width: 20px;
    height: 3px;
    background-color: var(--zoso-text-dark);
    border-radius: 10px;
    right: 0;
    bottom: 9px;
}

.header-right-end span:nth-child(3) {
    position: absolute;
    width: 20px;
    height: 3px;
    background-color: var(--zoso-text-dark);
    border-radius: 10px;
    left: 0;
    bottom: 0;
}

.header-right-end-two span:nth-child(1) {
    background-color: var(--zoso-text-light);
}

.header-right-end-two span:nth-child(2) {
    background-color: var(--zoso-text-light);
}

.header-right-end-two span:nth-child(3) {
    background-color: var(--zoso-text-light);
}

body.locked {
    overflow: hidden;
}

/***********************
* Mobile-Drawer
***********************/
.mobile-nav-wrapper {
    position: fixed;
    z-index: 991;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transform-origin: left center;
    transform-origin: left center;
    -webkit-transition: visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
    transition: visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
    transition: transform 500ms ease 500ms, visibility 500ms ease 500ms;
    transition: transform 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
    visibility: hidden;
    z-index: 9999;
}

.mobile-nav-wrapper.expanded {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    visibility: visible;
    -webkit-transition: visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
    transition: visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
    transition: transform 500ms ease 0ms, visibility 500ms ease 0ms;
    transition: transform 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
}

.mobile-nav-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000090;
    opacity: 0.9;
}

.mobile-nav-content {
    width: 100%;
    max-width: 300px;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    background-color: var(--zoso-bg-grey);
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 30px 15px;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
    transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
    transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, transform 500ms ease 0ms;
    transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, transform 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
}

.mobile-nav-content::-webkit-scrollbar {
    display: none;
}

.mobile-nav-wrapper.expanded .mobile-nav-content {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
    transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
    transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, transform 500ms ease 500ms;
    transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, transform 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
}

.mobile-menu-list {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 30px;
    margin-bottom: 10px;
}

.mobile-menu-list .menu-item-has-children button {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%)rotate(-90deg);
    width: 30px;
    height: 30px;
    background-color: var(--zoso-bg-4-1);
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--zoso-bg-grey);
    border: none;
    outline: none;
    float: right;
    transition: all 0.3s;
}

.mobile-menu-list .menu-item-has-children button.expanded {
    transform: translateY(-50%)rotate(0deg);
    background-color: var(--zoso-bg-3-1);


}

.mobile-menu-list .menu-item-has-children button.expanded i {
    color: var(--zoso-text-light);
}

.mobile-menu-list .menu-item-has-children button i {
    color: var(--zoso-text-dark);
}

.mobile-menu-list li a.expanded {
    color: var(--zoso-text-light);
    background-color: var(--zoso-text-blue);
}

.mobile-menu-list>li+li,
.mobile-menu-list>li>ul>li+li,
.mobile-menu-list>li>ul>li>ul>li+li {
    border-top: 1px solid var(--zoso-bg-grey);
}

.mobile-menu-list>li>a {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    color: var(--zoso-dark);
    display: block;
    line-height: 59px;
    padding: 0 27px;
    background-color: var(--zoso-bg-light);
    position: relative;
    transition: all 0.3s;
}

.mobile-menu-list>li>ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: none;
}

.mobile-menu-list>li>ul>li>a {
    position: relative;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    color: var(--zoso-dark);
    display: block;
    line-height: 59px;
    padding: 0 27px;
    background-color: var(--zoso-bg-light);
    transition: all 0.3s;
}

.mobile-menu-list>li>ul>li>ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: none;
}

.mobile-menu-list>li>ul>li>ul>li>a {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    color: var(--zoso-dark);
    display: block;
    line-height: 59px;
    padding: 0 27px;
    background-color: var(--zoso-bg-light);
    transition: all 0.3s;
}

.mobile-menu-list>li>ul>li>ul>li>ul {
    display: none;
}

.mobile-nav-contact {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 20px;
    margin-top: 30px;
}

.mobile-nav-contact li+li {
    margin-top: 10px;
}

.mobile-nav-contact li {
    display: flex;
    align-items: center;
    position: relative;
    font-size: 16px;
    line-height: 36px;
}

.mobile-nav-contact li a {
    color: var(--zoso-text);
    font-weight: 600;
    font-size: 20px;
    margin-left: 15px;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.mobile-nav-contact li a:hover {
    color: var(--zoso-bg-3-1);
}

.mobile-nav-close {
    width: 25px;
    height: 25px;
    display: block;
    position: absolute;
    top: 42px;
    right: 15px;
}

.mobile-nav-close span {
    width: 3px;
    height: 100%;
    display: block;
    position: absolute;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    background-color: var(--zoso-text-dark);
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
}

.mobile-nav-close span:nth-child(2) {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
}

.mobile-nav-content .logo-box img {
    width: 150px;
}

.mobile-nav-two-content .main-menu-list>li>a>button,
.mobile-nav-two-content .main-menu-list>li>ul>li>a>button,
.mobile-nav-two-content .main-menu-list>li>ul>li>ul>li>a>button {
    width: 30px;
    height: 30px;
    background-color: var(--twonet-text-light);
    border: none;
    outline: none;
    color: var(--twonet-yellow-light);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transition: -webkit-transform 500ms ease;
    transition: -webkit-transform 500ms ease;
    transition: transform 500ms ease;
    transition: transform 500ms ease, -webkit-transform 500ms ease;
    padding: 0;
}

.mobile-nav-two-content .main-menu-list>li>a>button.expanded,
.mobile-nav-two-content .main-menu-list>li>ul>li>a>button.expanded,
.mobile-nav-two-content .main-menu-list>li>ul>li>ul>li>a>button.expanded {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    background-color: var(--twonet-yellow-light);
    color: var(--twonet-text-light);
}

.mobile-nav-two-content .main-menu-list>li>ul>li>ul>li>a>button,
.mobile-nav-two-content .main-menu-list>li>ul>li>ul>li>ul {
    display: none !important;
}

.mobile-nav-two-content .main-menu-list li.cart-btn span {
    position: relative;
    top: auto;
    right: auto;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}

/***********************
* banner-one
***********************/

.banner-one {
    position: relative;
    padding-top: 128px;
    background: url(../images/background/banner-one-bg.png);
    background-size: cover;
    background-position: center;
    background-color: var(--zoso-bg-grey);
    overflow: hidden;
}

.banner-one-shape-1 {
    position: absolute;
    background: url(../images/shapes/banner-one-shape-1.png) no-repeat;
    background-size: cover;
    width: 25px;
    height: 25px;
    left: 10%;
    top: 20%;
    animation: 4s linear infinite banner-one-shape-1;
}

@keyframes banner-one-shape-1 {
    0% {
        transform: scale(0) rotate(0deg);
    }

    50% {
        transform: scale(1) rotate(180deg);
    }

    100% {
        transform: scale(0) rotate(360deg);
    }
}

.banner-one-shape-2 {
    position: absolute;
    background: url(../images/shapes/banner-one-shape-2.png) no-repeat;
    background-size: cover;
    width: 20px;
    height: 20px;
    left: 8%;
    bottom: 20%;
    animation: 6s linear infinite banner-one-shape-2;
}

@keyframes banner-one-shape-2 {
    0% {
        transform: translate(0) rotate(0deg);
    }

    50% {
        transform: translate(100px, 50px) rotate(240deg);
    }

    100% {
        transform: translate(0) rotate(0deg);
    }
}

.banner-one-shape-3 {
    position: absolute;
    width: 222px;
    height: 175px;
    top: 0;
    right: -20px;
}

.banner-one-shape-3-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 222px;
    height: 175px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.8);
}

.banner-one-shape-4 {
    position: absolute;
    width: 662px;
    height: 190px;
    top: 30px;
    right: 0;
}

.banner-one-shape-4-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 662px;
    height: 190px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.8);
}

.banner-one-shape-5 {
    position: absolute;
    width: 496px;
    height: 172px;
    transform: rotate(7deg);
    bottom: -40px;
    left: -10px;
}

.banner-one-shape-5-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 496px;
    height: 172px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.8);
}

.banner-one-left {
    position: relative;
}

.banner-one-left-shape {
    position: absolute;
    background: url(../images/shapes/banner-one-left-shape.png) no-repeat;
    background-size: cover;
    width: 60px;
    height: 46px;
    top: -50px;
    right: -50px;
    animation: 5s linear infinite banner-one-left-shape;
}

@keyframes banner-one-left-shape {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(15px);
    }

    100% {
        transform: translateX(0);
    }
}

.section-title-shape-1 {
    background-color: var(--zoso-text-yellow);
    border-radius: 16px;
    padding: 0 15px;
}

.section-title-shape-2 {
    background-color: var(--zoso-text-yellow);
    border-radius: 8px;
    padding: 0 10px;
}

.banner-one p {
    margin-bottom: 38px;
}

.banner-one-users {
    margin-top: 35px;
}

.banner-one-users h4 {
    margin-bottom: 20px;
    font-family: var(--bs-font-Inter);
    color: var(--zoso-text-dark);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.4px;
}

.banner-one-users i {
    color: var(--zoso-bg-primary);
}

.banner-one-users span {
    margin-left: 10px;
    font-family: var(--bs-font-Inter);
    color: var(--zoso-secondary-light-two);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
}

.banner-one-img-one {
    position: relative;
    z-index: 9;
}

.banner-one-img-one>img {
    width: 562px;
    height: 906px;
    margin-bottom: -120px;
    margin-left: 165px;
}

.banner-one-img-shape-1 {
    position: absolute;
    background: url(../images/shapes/banner-one-img-shape-1.png);
    background-size: cover;
    width: 490px;
    height: 490px;
    top: 25%;
    right: -55px;
    z-index: -1;
    animation: 6s linear infinite banner-one-img-shape-1;
}

@keyframes banner-one-img-shape-1 {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(30px);
    }

    100% {
        transform: translateX(0);
    }
}

.banner-one-img-shape-2 {
    position: absolute;
    background: url(../images/shapes/banner-one-img-shape-2.png);
    background-size: cover;
    width: 490px;
    height: 490px;
    top: 21%;
    right: -35px;
    z-index: -1;
    animation: 6s linear infinite banner-one-img-shape-2;
}

@keyframes banner-one-img-shape-2 {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(30px);
    }

    100% {
        transform: translateX(0);
    }
}

.banner-one-img-shape-3 {
    position: absolute;
    background: url(../images/shapes/banner-one-img-shape-3.png);
    background-size: cover;
    width: 617px;
    height: 521px;
    top: 100px;
    right: -293px;
    z-index: -1;
    animation: 3s linear infinite banner-one-img-shape-3;
}

@keyframes banner-one-img-shape-3 {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(40px);
    }

    100% {
        transform: translateY(0);
    }
}

.banner-one-img-text {
    position: absolute;
    width: 335px;
    height: 90px;
    left: 0;
    bottom: 226px;
    border-radius: 20px;
    background-color: var(--zoso-text-light);
    box-shadow: 0 0 60px 10px var(--zoso-box-shadow);
    padding: 20px 30px;
    display: flex;
}

.banner-one-img-text span {
    margin-left: 20px;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: -0.54px;
}

/***********************
* features-one
***********************/

.features-one {
    padding-top: 130px;
    padding-bottom: 130px;
    position: relative;
}

.features-one-shape-1 {
    position: absolute;
    background: url(../images/shapes/testimonial-one-shape-4.png)no-repeat;
    background-size: cover;
    width: 49px;
    height: 60px;
    top: 17%;
    left: 25%;
}

.features-one-shape-2 {
    position: absolute;
    background: url(../images/shapes/features-one-shape-2.png)no-repeat;
    background-size: cover;
    width: 45px;
    height: 50px;
    top: 17%;
    right: 25%;
}

.features-one .section-title {
    margin-bottom: 30px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.features-one .section-details {
    margin-bottom: 40px;
}

.features-one-box {
    padding: 35px 30px;
    border-radius: 12px;
    margin-top: 30px;
}

.features-one-box-1 {
    background-color: var(--zoso-text-blue-two);
    transition: all 0.3s;
}

.features-one-box-2 {
    background-color: var(--zoso-text-yellow-10);
    transition: all 0.3s;
}

.features-one-box-3 {
    background-color: var(--zoso-bg-cyan-15);
    transition: all 0.3s;
}

.features-one-box-4 {
    background-color: var(--zoso-bg-purple-10);
    transition: all 0.3s;
}

.features-one-icon-box {
    position: relative;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.3s;
}

.features-one-icon-box.icon-box-1 {
    background-color: var(--zoso-text-blue);
}

.features-one-icon-box.icon-box-2 {
    background-color: var(--zoso-text-yellow);
}

.features-one-icon-box.icon-box-3 {
    background-color: var(--zoso-bg-cyan);
}

.features-one-icon-box.icon-box-4 {
    background-color: var(--zoso-bg-purple);
}

.features-one-box:hover .features-one-icon-box.icon-box-1,
.features-one-box:hover .features-one-icon-box.icon-box-2,
.features-one-box:hover .features-one-icon-box.icon-box-3,
.features-one-box:hover .features-one-icon-box.icon-box-4 {
    background-color: var(--zoso-text-dark);
}

.features-one-box:hover .features-one-icon-box.icon-box-1 i {
    color: var(--zoso-text-blue);
}

.features-one-box:hover .features-one-icon-box.icon-box-2 i {
    color: var(--zoso-text-yellow);
}

.features-one-box:hover .features-one-icon-box.icon-box-3 i {
    color: var(--zoso-bg-cyan);
}

.features-one-box:hover .features-one-icon-box.icon-box-4 i {
    color: var(--zoso-bg-purple);
}

.features-one-icon-box i {
    position: absolute;
    content: "";
    font-size: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--zoso-text-dark);
    transition: all 0.3s;
}

.features-one-title-box h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.6px;
    margin-bottom: 30px;
}

.features-one-detail-box p {
    font-family: "Lexend Deca";
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.2px;
}

.features-one-box-1:hover {
    background-color: var(--zoso-text-blue);
}

.features-one-box-2:hover {
    background-color: var(--zoso-text-yellow);
}

.features-one-box-3:hover {
    background-color: var(--zoso-bg-cyan);
}

.features-one-box-4:hover {
    background-color: var(--zoso-bg-purple);
}

/***********************
* About-one
***********************/
.about-one {
    padding-bottom: 130px;
    position: relative;
}

.about-one-left {
    position: relative;
}

.about-one-left-shape {
    position: absolute;
    background: url(../images/shapes/about-one-left-shape.png)no-repeat;
    background-size: cover;
    width: 150px;
    height: 100px;
    top: 97.5%;
    left: 15%;
    z-index: 1;
    animation: 4s linear infinite about-one-left-shape;
}

@keyframes about-one-left-shape {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(20px);
    }

    100% {
        transform: translateY(0);
    }
}

.about-one-left-img {
    width: 100%;
    height: 510px;
    position: relative;
}

.about-one-left-img img {
    border-radius: 185px;
    width: 100%;
}

.about-one-left-img .tile {
    border-radius: 185px;
    overflow: hidden;
}

.about-one-inner {
    margin-left: 40px;
}

.about-one-inner-icon-box {
    position: relative;
    width: 201px;
    height: 201px;
    margin-bottom: 39px;
}

.about-one-inner-icon-box::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    width: 201px;
    height: 201px;
    background: url(../images/resources/about-one-inner-img.png)no-repeat;
    background-size: contain;
    animation: 25s linear infinite about-one-inner-icon-box;
}

@keyframes about-one-inner-icon-box {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-360deg);
    }
}

.about-one-inner-icon-box i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    z-index: 9;
}

.about-one-inner-title-box {
    margin-bottom: 38px;
}

.about-one-inner-title-box .section-title {
    width: 85%;
}

.about-one-inner-details-box {
    margin-bottom: 41px;
}

.about-one-inner-details-box p {
    font-size: 16px;
    color: var(--zoso-secondary-grey);
    font-weight: 400;
    line-height: 30px
}

.about-one-right {
    position: relative;
    margin-top: 295px;
    margin-left: -40px;
    z-index: 9;
}

.about-one-right-shape-1 {
    position: absolute;
    background: url(../images/shapes/about-one-right-shape-1.png)no-repeat;
    background-size: cover;
    width: 54px;
    height: 54px;
    top: -52%;
    left: 33%;
    z-index: -1;
}

.about-one-right-shape-2 {
    position: absolute;
    background: url(../images/shapes/about-one-right-shape-2.png)no-repeat;
    background-size: cover;
    width: 250px;
    height: 250px;
    top: -45%;
    left: 20%;
    z-index: -1;
    animation: 4s linear 2s infinite about-one-left-shape;
}

.about-one-right-img {
    max-width: 200px;
    width: 100%;
    height: 370px;
    position: relative;
}

.about-one-right-img img {
    border-radius: 100px;
    width: 100%;
}

.about-one-right-img .tile {
    border-radius: 100px;
    overflow: hidden;
}

/***********************
* Service-one
***********************/

.service-one {
    position: relative;
    padding-top: 128px;
    padding-bottom: 130px;
    background: url(../images/background/services-one-bg.png);
    background-size: cover;
    background-position: center;
    background-color: var(--zoso-text-dark);
    overflow: hidden;
}

.service-one-shape-1 {
    position: absolute;
    width: 45px;
    height: 50px;
    top: 11%;
    left: 12%;
    z-index: 0;
}

.service-one-shape-1-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 45px;
    height: 50px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.8);
}

.service-one-shape-2 {
    position: absolute;
    width: 26px;
    height: 26px;
    bottom: 28%;
    left: 8%;
    z-index: 0;
}

.service-one-shape-2-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 26px;
    height: 26px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.8);
}

.service-one-shape-3 {
    position: absolute;
    width: 223px;
    height: 135px;
    top: 8%;
    right: 9%;
    z-index: 0;
}

.service-one-shape-3-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 223px;
    height: 135px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.8);
}

.service-one-shape-4 {
    position: absolute;
    width: 70px;
    height: 85px;
    bottom: 20%;
    right: 4%;
    z-index: 0;
}

.service-one-shape-4-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 70px;
    height: 85px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.8);
}

.service-one .section-title {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
}

.service-one .section-title h2,
.service-one .section-details p {
    color: var(--zoso-text-light);
}

.service-one .section-details {
    margin-bottom: 35px;
}

.service-one-bottom {
    margin-top: 100px;
}

.service-one-box {
    margin-top: 30px;
    padding: 35px 30px;
    border-radius: 20px;
    height: 170px;
}

.service-one-box-1 {
    background-color: var(--zoso-text-blue);
}

.service-one-box-2 {
    background-color: var(--zoso-text-yellow);
}

.service-one-box-3 {
    background-color: var(--zoso-bg-cyan);
}

.service-one-box-4 {
    background-color: var(--zoso-bg-purple);
}

.service-one-box-5 {
    background-color: var(--zoso-bg-green-three);
}

.service-one-box-6 {
    background-color: var(--zoso-text-primary-four);
}

.service-one-img-box,
.service-one-title-box {
    margin-bottom: 27px;
}

.service-one-img-box img {
    border-radius: 12px;
    width: 100%;
}

.service-one-title-box,
.service-one-btn-box {
    margin-left: 10px;
}

.service-one-title-box h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.3px;
}

.service-one-bottom-icon-box {
    display: inline-flex;
    align-items: center;
    margin-bottom: 7px;
}

.service-one-bottom-icon-box i {
    position: relative;
    display: inline-block;
    width: 25px;
    height: 25px;
    font-size: 11px;
    background-color: var(--zoso-text-yellow);
    border-radius: 50%;
}

.service-one-bottom-icon-box i::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--zoso-text-dark);
}

.service-one-bottom-icon-box h2 {
    color: var(--zoso-text-yellow);
    margin-left: 15px;
}

.service-one-bottom-inner-2 {
    margin-left: 30px;
}

.service-one-bottom-inner-3 {
    margin-left: 60px;
}

.service-one-bottom-inner-4 {
    margin-left: 125px;
}

.service-one-bottom-inner p {
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    color: var(--zoso-text-light);
}

/***********************
* Testimonial-one
***********************/

.testimonial-one {
    padding-top: 100px;
    padding-bottom: 173px;
    position: relative;
}

.testimonial-one-shape-1 {
    position: absolute;
    background-size: contain;
    width: 171px;
    height: 124px;
    top: 30%;
    left: 3%;
}

.testimonial-one-shape-1-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 171px;
    height: 124px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.7);
}

.testimonial-one-shape-2 {
    position: absolute;
    background-size: contain;
    width: 300px;
    height: 355px;
    top: 45%;
    left: 3%;
    z-index: 1;
}

.testimonial-one-shape-2-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 300px;
    height: 355px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.8);
}

.testimonial-one-shape-3 {
    position: absolute;
    width: 230px;
    height: 230px;
    top: 51%;
    left: 9%;
    border: 1px solid #20323620;
    border-radius: 50%;
    z-index: 0;
}

.testimonial-one-shape-4 {
    position: absolute;
    background: url(../images/shapes/testimonial-one-shape-4.png)no-repeat;
    background-size: cover;
    width: 49px;
    height: 60px;
    top: 15%;
    left: 19%;
}

.testimonial-one-shape-5 {
    position: absolute;
    background: url(../images/shapes/testimonial-one-shape-5.png)no-repeat;
    background-size: cover;
    width: 45px;
    height: 50px;
    top: 15%;
    right: 19%;
}

.testimonial-one-shape-6 {
    position: absolute;
    background-size: cover;
    width: 284px;
    height: 338px;
    top: 30%;
    right: 3%;
    z-index: 9;
}

.testimonial-one-shape-6-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 284px;
    height: 338px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.8);
}

.testimonial-one-shape-7 {
    position: absolute;
    width: 200px;
    height: 200px;
    top: 38%;
    right: 3%;
    border: 1px solid #20323620;
    border-radius: 50%;
    z-index: 0;
}

.testimonial-one-shape-8 {
    position: absolute;
    background-size: contain;
    width: 145px;
    height: 77px;
    top: 75%;
    right: 14%;
}

.testimonial-one-shape-8-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 145px;
    height: 77px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.8);
}

.testimonial-one .section-title {
    margin-bottom: 30px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.testimonial-one-box {
    width: 770px;
    margin: 70px auto 0 auto;
    border-radius: 20px;
    background-color: var(--zoso-bg-purple-gray);
    overflow: hidden;
}

.testimonial-one-single {
    padding: 40px 81px 35px;
    border-radius: 20px;
}

.testimonial-one-single-title-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.testimonial-one-single-title-img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
}

.testimonial-one-single-title-img img {
    border-radius: 50%;
}

.testimonial-one-single-title h3 span {
    color: var(--zoso-secondary-grey);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
}

.testimonial-one-single-detaile p {
    position: relative;
    color: var(--zoso-secondary-grey);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    padding-bottom: 50px;
    margin-bottom: 35px;
}

.testimonial-one-single-detaile p::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background-color: var(--zoso-text-dark-two);
    left: 0;
    bottom: 0;
}

.testimonial-one-single-rating-star {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 8px;
}

.testimonial-one-single-rating-star h4 {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.2px;
}

.testimonial-one-single-rating-star-shape-1,
.testimonial-one-single-rating-star-shape-2 {
    position: absolute;
    font-size: 30px;
    top: -5px;
}

.testimonial-one-single-rating-star-shape-1 {
    left: -12px;
}

.testimonial-one-single-rating-star-shape-2 {
    right: -12px;
}

.testimonial-one-single-rating-star .star-icon {
    margin-left: 20px;
}

.testimonial-one-single-rating-star .star-icon i {
    color: var(--zoso-bg-primary);
}

.testimonial-one-single-rating {
    display: flex;
    justify-content: space-between;
}

.testimonial-one-single-rating-icon i {
    font-size: 40px;
}

.testimonial-one-box .owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 10px;
    position: absolute;
    left: 50%;
    bottom: -80px;
    transform: translate(-50%, -50%);
}

.testimonial-one-box .testimonial-one-slider {
    position: unset;
}

.owl-theme .owl-dots .owl-dot {
    display: inline-block;
    border: 1px solid transparent;
    zoom: 1;
    border-radius: 50%;
    margin: 5px;
}

.testimonial-one-box .owl-theme .owl-dots .owl-dot.active {
    border: 1px solid var(--zoso-text-blue);
}

.testimonial-one-box .owl-theme .owl-dots .owl-dot span {
    background-color: var(--zoso-text-blue);
}

.testimonial-one-box .owl-theme .owl-dots .owl-dot span {
    margin: 4px 4px;
    width: 5px;
    height: 5px;
}

/***********************
* Joinus-one
***********************/

.joinus-one {
    padding-bottom: 130px;
}

.joinus-one-left-box {
    position: relative;
}

.joinus-one-left-shape {
    position: absolute;
    width: 360px;
    height: 360px;
    bottom: 0;
    left: -52px;
    border: 1px solid #20323620;
    border-radius: 50%;
    z-index: -1;
}

.joinus-one-left-img-one,
.joinus-one-left-img-two {
    overflow: hidden;
    border-radius: 12px;
}

.joinus-one-left-img-one img,
.joinus-one-left-img-two img {
    transition: all 0.5s;
    transform: scale(1.1);
    border-radius: 12px;
}

.joinus-one-left-img-one img:hover,
.joinus-one-left-img-two img:hover {
    transform: scale(1);
}

.joinus-one-icon-box {
    position: relative;
    width: 201px;
    height: 201px;
    margin-bottom: 30px;
}

.joinus-one-icon-box i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    z-index: 9;
}

.joinus-one-icon-box::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    width: 201px;
    height: 201px;
    background: url(../images/resources/joinus-one-icon-img.png)no-repeat;
    background-size: contain;
    animation: 25s linear infinite about-one-inner-icon-box;
}

.joinus-one-right-box {
    margin-left: 80px;
}

.joinus-one-right-box .section-title {
    margin-bottom: 25px;
}


.joinus-one-right-box .section-details {
    margin-bottom: 32px;
}

.joinus-one-right-box .section-details p {
    font-size: 16px;
    line-height: 30px;
}

.joinus-one-list-box {
    margin-bottom: 40px;
}

.joinus-one-list-detali {
    display: flex;
    align-items: center;
}

.joinus-one-list-box .joinus-one-list-detali+.joinus-one-list-detali {
    margin-top: 20px;
}

.joinus-one-list-detali i {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    font-size: 12px;
    background-color: var(--zoso-text-blue);
    border-radius: 50%;
    margin-right: 15px;
}

.joinus-one-list-detali i::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--zoso-text-dark);
}

.joinus-one-list-detali p {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.3px;
    font-family: var(--bs-font-DM-Sans);
    color: var(--zoso-text-dark);
}

/***********************
* Blog-one
***********************/

.blog-one {
    position: relative;
    padding-top: 130px;
    padding-bottom: 130px;
    background: url(../images/background/banner-one-bg.png);
    background-size: cover;
    background-position: center;
    background-color: var(--zoso-bg-grey);
    overflow: hidden;
    z-index: 9;
}

.blog-one .section-title {
    margin-bottom: 30px;
    width: 65%;
    margin-left: auto;
    margin-right: auto;
}

.blog-one .section-details {
    margin-bottom: 35px;
}

.blog-one-shape-1 {
    position: absolute;
    background: url(../images/shapes/testimonial-one-shape-4.png)no-repeat;
    background-size: cover;
    width: 49px;
    height: 60px;
    top: 12%;
    left: 24%;
    z-index: -1;
}

.blog-one-shape-2 {
    position: absolute;
    background: url(../images/shapes/testimonial-one-shape-5.png)no-repeat;
    background-size: cover;
    width: 45px;
    height: 50px;
    top: 12%;
    right: 24%;
    z-index: -1;
}

.blog-one-shape-3 {
    position: absolute;
    width: 26px;
    height: 26px;
    bottom: 340px;
    left: 10%;
    z-index: -1;
}

.blog-one-shape-3-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 26px;
    height: 26px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.8);
}

.blog-one-shape-4 {
    position: absolute;
    width: 145px;
    height: 77px;
    top: 308px;
    right: 10%;
    z-index: -1;
}

.blog-one-shape-4-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 145px;
    height: 77px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.8);
}

.blog-one-shape-5 {
    position: absolute;
    width: 360px;
    height: 360px;
    bottom: 130px;
    right: 8%;
    z-index: -1;
}

.blog-one-shape-5-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    border: 1px solid #20323620;
    border-radius: 50%;
    width: 360px;
    height: 360px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.8);
}

.blog-one-shape-6 {
    position: absolute;
    width: 496px;
    height: 172px;
    transform: rotate(7deg);
    bottom: -40px;
    left: -50px;
    z-index: -1;
}

.blog-one-shape-6-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 496px;
    height: 172px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.8);
}

.blog-one-single {
    margin-top: 30px;
}

.blog-one-single .blog-one-img-box {
    margin-right: 35px;
}

.blog-one-single .blog-one-img-box img {
    border-radius: 12px;
}

.blog-one-content-box {
    position: relative;
    z-index: 1;
    background-color: var(--zoso-bg-light);
    box-shadow: 0 10px 60px 0 #7070701a;
    padding: 20px 30px 20px;
    border-radius: 12px;
    margin-left: 50px;
    margin-top: -155px;
}

.blog-one-date p {
    font-size: 16px;
    color: var(--zoso-secondary-grey);
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 6px;
}

.blog-one-title h4 a {
    display: inline;
    font-size: 22px;
    color: var(--zoso-text-dark);
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.66px;
    background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 100% / 0px 1px no-repeat;
    transition: all 0.3s;
}

.blog-one-single:hover .blog-one-title h4 a {
    background-size: 100% 1px;
}

.blog-one-title {
    padding-bottom: 27px;
    border-bottom: 1px solid var(--zoso-text-dark-two);
    margin-bottom: 17px;
}

.blog-one-user-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.blog-one-user-detail {
    display: flex;
    align-items: center;
}

.blog-one-user-img img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
}

.blog-one-user-name h5 {
    font-size: 16px;
    color: var(--zoso-secondary-grey);
    font-family: "Lexend Deca";
    font-weight: 400;
    line-height: 30px;
}

/***********************
* Cta-one
***********************/
.cta-one {
    padding-top: 122px;
}

.cta-one-box .section-title {
    margin-left: 0;
    margin-bottom: 30px;
    width: 71%;
}

.cta-one-box .section-details {
    margin-bottom: 0;
}

.cta-one-box .section-details p {
    font-family: var(--bs-font-Inter);
}

.cta-one-btn {
    margin-left: 115px;
}

.cta-one-btn .main-black-btn {
    margin-bottom: 30px;
}

.cta-one-btn .cta-one-btn-checkout {
    display: flex;
    align-items: center;
}

.cta-one-btn .cta-one-btn-checkout i {
    position: relative;
    width: 25px;
    height: 25px;
    background-color: var(--zoso-text-yellow);
    border-radius: 50%;
    font-size: 11px;
    margin-right: 12px;
}

.cta-one-btn .cta-one-btn-checkout i::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cta-one-btn .cta-one-btn-checkout p {
    font-size: 16px;
    color: var(--zoso-secondary-grey);
    margin-bottom: 0;
    font-weight: 500;
    letter-spacing: -0.2px;
    font-family: var(--bs-font-Inter);
    line-height: 1.53;
}

/***********************
* Footer-one
***********************/
.site-footer {
    overflow: hidden;
    position: relative;
    z-index: 9;
    background: #080A10;
}

.site-footer-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url(../images/background/footer-one-bg.png) no-repeat;
    background-color: var(--zoso-text-dark);
    background-size: cover;
    background-position: center;
    z-index: 0;
}

.footer-main {
    padding-top: 100px;
    padding-bottom: 100px;
}

.footer-widget-about-logo {
    margin-bottom: 40px;
}

.footer-widget-about-logo a img {
    width: 122px;
}

.footer-widget-about-detail p {
    font-family: var(--bs-font-Inter);
    font-size: 16px;
    color: var(--zoso-text-light-two);
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 40px;
}

.footer-widget-social,
.mobile-nav-social {
    display: flex;
    align-items: center;
}

.footer-widget-social li+li,
.mobile-nav-social li+li {
    margin-left: 10px;
}

.footer-widget-social li a,
.mobile-nav-social li a {
    display: inline-block;
    background-color: var(--zoso-text-blue);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    transition: all 0.3s;
}

.footer-widget-social li a i,
.mobile-nav-social li a i {
    position: absolute;
    font-size: 14px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: var(--zoso-text-dark);
    transition: all 0.3s;
}

.footer-widget-social li a:hover,
.mobile-nav-social li a:hover {
    background-color: var(--zoso-text-yellow);
}

.footer-main-four .footer-widget-social li a:hover i {
    color: var(--zoso-text-light);
}

.footer-widget-title {
    margin-bottom: 40px;
    font-family: var(--bs-font-DM-Sans);
    font-size: 22px;
    color: var(--zoso-text-light);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.2px;
}

.footer-widget-link {
    margin-left: 50px;
}

.footer-widget-link .footer-widget-link-details li+li {
    margin-top: 15px;
}

.footer-widget-link .footer-widget-link-details li a {
    font-family: Lexend Deca;
    font-size: 16px;
    color: var(--zoso-text-light-two);
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    transition: all 0.3s;
}

.footer-widget-link .footer-widget-link-details li a:hover {
    color: var(--zoso-text-yellow);
}

.footer-widget-address {
    margin-left: -15px;
}

.footer-widget-address-details li+li {
    margin-top: 20px;
}

.footer-widget-address-details li {
    display: flex;
    align-items: flex-start;
}

.footer-widget-address .footer-widget-address-details li i {
    color: var(--zoso-text-yellow);
    font-size: 16px;
    line-height: 30px;
    width: 20px;
}

.footer-widget-address .footer-widget-address-details li i.icon-location {
    font-size: 22px;
}

.footer-widget-address .footer-widget-address-details li p,
.footer-widget-address-details li a {
    font-family: Lexend Deca;
    font-size: 16px;
    color: var(--zoso-text-light-two);
    font-weight: 400;
    line-height: 30px;
    transition: all 0.3s;
    margin-left: 15px;
}

.footer-widget-address .footer-widget-address-details li .footer-widget-number {
    font-size: 20px;
    font-weight: 600;
}

.footer-widget-address .footer-widget-address-details li a:hover {
    color: var(--zoso-text-light);
}

.footer-widget-newsletter .footer-widget-newsletter-detail {
    font-family: Lexend Deca;
    font-size: 16px;
    color: var(--zoso-text-light-two);
    font-weight: 400;
    line-height: 30px;
    transition: all 0.3s;
    margin-bottom: 30px;
}

.footer-widget-newsletter .newsletter-email-box {
    position: relative;
    display: flex;
    align-items: center;
}

.footer-widget-newsletter .newsletter-email-box input[type="email"] {
    position: relative;
    width: 100%;
    padding: 20px 25px;
    background-color: var(--zoso-text-light);
    border: none;
    border-radius: 10px;
    line-height: 1;
    outline: none;
}

.footer-widget-newsletter .newsletter-email-box .newsletter-send-box:hover {
    background-color: var(--zoso-text-yellow);
}

.footer-widget-newsletter .newsletter-email-box .newsletter-send-box {
    margin-left: -59px;
    position: relative;
    z-index: 9;
    background-color: var(--zoso-text-blue);
    border-radius: 10px;
    width: 52px;
    height: 50px;
    border: none;
    outline: none;
    transition: all 0.3s;
}

.footer-widget-newsletter .newsletter-email-box .newsletter-send-box i {
    color: var(--zoso-text-dark);
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    transition: all 0.3s;
}

.footer-bottom {
    padding-top: 40px;
    padding-bottom: 40px;
    border-top: 1px solid var(--zoso-text-light-three);
    position: relative;
}

.footer-bottom-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-bottom-inner .footer-bottom-inner-item {
    display: flex;
    align-items: center;
}

.footer-bottom-inner .footer-bottom-inner-item li+li {
    margin-left: 35px;
}

.footer-bottom-inner .footer-bottom-inner-item li>a:hover {
    color: var(--zoso-text-light);
}

.footer-bottom-inner .footer-bottom-inner-item li>a {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    font-family: var(--bs-font-Inter);
    background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 100%/0px 1px no-repeat;
    color: var(--zoso-text-light-two);
    transition: all 500ms ease;
    text-decoration: none;
}

.footer-bottom-inner .footer-bottom-inner-item li:hover>a {
    background-size: 100% 1px;
}

.footer-bottom-inner .copyright p {
    font-family: Lexend Deca;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    color: var(--zoso-text-light-two);
}

.footer-bottom-inner .copyright p a {
    font-family: Lexend Deca;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    color: var(--zoso-text-light-two);
    transition: all 0.3s;
}

.footer-bottom-inner .copyright p a:hover {
    color: var(--zoso-text-yellow);
}

/*-----------------------------
------------ page-2 -----------
-------------------------------*/


/***********************
* Header-two
***********************/

.main-menu-two {
    margin-top: 39px;
}

.page-wrapper-two {
    background-color: #080A10;
}

.main-btn-two {
    display: inline-flex;
    position: relative;
    padding: 16px 40px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    color: var(--zoso-text-light);
    background-image: var(--zoso-gradient-one);
    border-radius: 12px;
}

.main-btn-two i {
    color: var(--zoso-text-light);
    font-size: 11px;
    font-weight: 600;
    transition: all 0.3s;
}

.main-btn-two:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-image: linear-gradient(92deg, #3F5EFB 0.14%, #FC466B 100%);
    transition: opacity 0.3s ease-out;
    z-index: -1;
    opacity: 0;
}

.main-btn-two:hover:after {
    opacity: 1;
}

.main-menu-left {
    display: flex;
    align-items: center;
}

.main-menu-two .main-menu-list {
    margin-left: 105px;
}

.section-two-title .section-two-title-text {
    margin-bottom: 17px;
}

.section-two-title .section-two-title-text p {
    font-size: 18px;
    display: inline-block;
    font-family: Lexend Deca;
    font-weight: 500;
    line-height: 23px;
    background: var(--zoso-gradient-one);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
}

.section-two-title .section-two-title-title h1 {
    color: var(--zoso-text-light);
    text-align: center;
    font-family: var(--bs-font-DM-Sans);
    font-size: 65px;
    font-style: normal;
    font-weight: 700;
    line-height: 77px;
    letter-spacing: -0.3px;
    margin-bottom: 30px;
}

.section-two-title .section-two-title-title h2 {
    color: var(--zoso-text-light);
    font-family: var(--bs-font-DM-Sans);
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: -0.3px;
    margin-bottom: 30px;
}

/***********************
* Banner-two
***********************/
.banner-two {
    position: relative;
    padding-top: 262px;
    padding-bottom: 175px;
    background: linear-gradient(0deg, rgba(69, 93, 245, 0.07) 0.36%, rgba(15, 19, 31, 0.00) 28.72%);
    overflow: hidden;
    z-index: 9;
}

.banner-two-shape-1 {
    position: absolute;
    width: 166px;
    height: 128px;
    top: 188px;
    left: 122px;
    background: url(../images/shapes/banner-two-shape-img-1.png)no-repeat;
    background-size: contain;
    z-index: 1;
}

.banner-two-shape-2 {
    position: absolute;
    width: 250px;
    height: 280px;
    left: 70px;
    top: 364px;
    background: url(../images/shapes/banner-two-shape-img-2.jpg)no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    z-index: 1;
}

.banner-two-shape-3 {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 260px;
    top: 594px;
    background: url(../images/shapes/banner-two-shape-img-3.png)no-repeat;
    background-size: contain;
    z-index: 2;
}

.banner-two-shape-3-inner {
    position: relative;
    width: 100px;
    height: 100px;
}

.banner-two-shape-3::before,
.banner-two-shape-3::after,
.banner-two-shape-3-inner::before,
.banner-two-shape-3-inner::after {
    position: absolute;
    content: "";
    width: 100px;
    height: 100px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid var(--zoso-text-primary-four);
    border-radius: 50%;
}

.banner-two-shape-3::before {
    animation: 8s linear infinite banner-two-shape-3;
}

.banner-two-shape-3::after {
    animation: 8s linear 2s infinite banner-two-shape-3;
}

.banner-two-shape-3-inner::before {
    animation: 8s linear 4s infinite banner-two-shape-3;
}

.banner-two-shape-3-inner::after {
    animation: 8s linear 6s infinite banner-two-shape-3;
}

@keyframes banner-two-shape-3 {
    0% {
        width: 100px;
        height: 100px;
    }

    50% {
        width: 150px;
        height: 150px;
    }

    100% {
        opacity: 0.1;
        width: 200px;
        height: 200px;
    }
}

.banner-two-shape-4 {
    position: absolute;
    width: 100px;
    height: 100px;
    right: 130px;
    top: 218px;
    background: url(../images/shapes/banner-two-shape-img-4.png)no-repeat;
    background-size: contain;
    z-index: 2;
}

.banner-two-shape-4-inner {
    position: relative;
    width: 100px;
    height: 100px;
}

.banner-two-shape-4::before,
.banner-two-shape-4::after,
.banner-two-shape-4-inner::before,
.banner-two-shape-4-inner::after {
    position: absolute;
    content: "";
    width: 100px;
    height: 100px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid var(--zoso-text-yellow);
    border-radius: 50%;
}

.banner-two-shape-4::before {
    animation: 8s linear infinite banner-two-shape-3;
}

.banner-two-shape-4::after {
    animation: 8s linear 2s infinite banner-two-shape-3;
}

.banner-two-shape-4-inner::before {
    animation: 8s linear 4s infinite banner-two-shape-3;
}

.banner-two-shape-4-inner::after {
    animation: 8s linear 6s infinite banner-two-shape-3;
}

.banner-two-shape-5 {
    position: absolute;
    width: 250px;
    height: 280px;
    top: 268px;
    right: 174px;
    background: url(../images/shapes/banner-two-shape-img-5.jpg)no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    z-index: 1;
}

.banner-two-shape-6 {
    position: absolute;
    width: 203px;
    height: 80px;
    top: 518px;
    right: 144px;
    background-color: var(--zoso-text-light);
    border-radius: 10px;
    display: flex;
    z-index: 1;
    padding: 12px 20px;
}

.banner-two-shape-6 i {
    margin-top: 8px;
    margin-right: 15px;
    min-width: 25px;
    max-width: 25px;
    max-height: 25px;
    position: relative;
    border-radius: 50%;
    background-color: var(--zoso-text-primary-two);
    font-size: 11px;
}

.banner-two-shape-6 i::before {
    position: absolute;
    color: var(--zoso-text-light);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.banner-two-shape-6 p {
    font-size: 18px;
    font-weight: 500;
    color: var(--zoso-text-dark);
    line-height: 27px;
    letter-spacing: -0.54px;
}

.banner-two-shape-7 {
    position: absolute;
    width: 220px;
    height: 220px;
    top: 192px;
    left: 196px;
    border: 1px solid rgba(255, 255, 255, .04);
    border-radius: 50%;
    z-index: 1;
}

.banner-two-shape-8 {
    position: absolute;
    width: 220px;
    height: 220px;
    bottom: 146px;
    right: 270px;
    border: 1px solid rgba(255, 255, 255, .04);
    border-radius: 50%;
    z-index: 1;
}

.banner-two-content-box .section-two-details {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 46px;
}

.banner-two-content-box .section-two-details p {
    color: rgba(255, 255, 255, 0.75);
    font-family: var(--bs-font-lexend-deca);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
}

/***********************
* Partener-one
***********************/
.partener-one {
    padding-top: 130px;
    padding-bottom: 130px;
}

.partener-one .container-fluid {
    max-width: 1717px;
}

.partener-one-title p {
    font-family: var(--bs-font-DM-Sans);
    color: var(--zoso-text-light);
    font-size: 24px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: -0.48px;
}

.partener-one-title p span {
    background: linear-gradient(92deg, #FC466B 0%, #3F5EFB 100.14%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 24px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: -0.48px;
}

.partener-one-slider {
    margin-top: 63px;
}

.partener-one-img-box {
    text-align: center;
}

.partener-one-img-box img {
    opacity: 0.20;
    transition: all 0.3s;
}

.partener-one-img-box img:hover {
    opacity: 1;
}

.partener-one .owl-carousel .owl-item img {
    width: auto;
}

/***********************
* Features-two
***********************/
.features-two {
    padding-bottom: 130px;
}

.features-two-left-box .section-two-title-title {
    width: 80%;
}

.section-two-details p {
    color: rgba(255, 255, 255, 0.65);
    font-family: var(--bs-font-lexend-deca);
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}

.features-two-left-box .section-two-details {
    width: 70%;
}

.features-two-left-info-box {
    display: flex;
    margin-top: 52px;
    margin-right: 20px;
}

.features-two-left-info {
    position: relative;
    padding: 25px 30px;
    border-radius: 12px;
    transition: all 0.3s;
    background: transparent;
    z-index: 9;
}

.features-two-left-info::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) -2.5%, rgba(255, 255, 255, 0.01) 103.5%);
    transition: opacity 0.3s ease-out;
    z-index: -1;
    opacity: 0;
}

.features-two-left-info:hover::after {
    opacity: 1;
}

.features-two-left-info-icon {
    margin-bottom: 20px;
}

.features-two-left-info-icon i {
    font-size: 40px;
    background: linear-gradient(92deg, #FC466B 0%, #3F5EFB 100.14%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.features-two-left-info-title h5 {
    font-family: var(--bs-font-DM-Sans);
    color: var(--zoso-text-light);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.54px;
    margin-bottom: 8px;
}

.features-two-left-info-detail p {
    color: rgba(255, 255, 255, 0.65);
    font-family: Lexend Deca;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

.features-two-right-box {
    position: relative;
    width: 100%;
    height: auto;
    z-index: 9;
}

.features-two-right-box-shape {
    position: absolute;
    width: 225px;
    height: 141px;
    top: 77px;
    left: -138px;
    background: url(../images/shapes/features-two-right-box-shape.png)no-repeat;
    background-size: contain;
    z-index: 99;
}

.features-two-right-box::after {
    content: "";
    position: absolute;
    width: 400px;
    height: 555px;
    border: 1px solid var(--zoso-text-light-three);
    border-radius: 200px 200px 0 0;
    top: -30px;
    left: -30px;
    z-index: -1;
}

@keyframes up-down-animation {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(30px);
    }

    100% {
        transform: translateY(0);
    }
}

.features-two-right-img-box {
    width: 400px;
    height: 555px;
    overflow: hidden;
    border-radius: 200px 200px 0 0;
}

.features-two-right-img {
    position: relative;
    width: 400px;
    height: 555px;
    margin-top: 30px;
}

.features-two-right-img .scroll_item_select_img img {
    /* border-radius: 200px 200px 0 0; */
    object-fit: cover;
    top: -150px;
}

/***********************
* Features-three
***********************/
.features-three {
    padding-bottom: 130px;
    background: linear-gradient(0deg, rgba(69, 93, 245, 0.07) 0.36%, rgba(15, 19, 31, 0.00) 28.72%);
    overflow: hidden;
    z-index: 9;
}

.features-three-left-box,
.cta-two-left-box {
    display: flex;
    position: relative;
}

.features-three-left-box-shape-1 {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 263px;
    top: 0;
    background: url(../images/shapes/features-three-left-box-shape-1.png)no-repeat;
    background-size: contain;
    animation: 4s linear infinite features-three-left-box-shape;
}

@keyframes features-three-left-box-shape {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.4);
    }

    100% {
        transform: scale(1);
    }
}

.features-three-left-box-shape-2 {
    position: absolute;
    content: "";
    width: 62px;
    height: 71px;
    left: 143px;
    bottom: 0;
    background: url(../images/shapes/features-three-left-box-shape-2.png)no-repeat;
    background-size: contain;
    animation: 4s linear infinite features-three-left-box-shape;
}

.features-three-left-box-shape-3 {
    position: absolute;
    content: "";
    width: 35px;
    height: 38px;
    left: 182px;
    bottom: 48px;
    background: url(../images/shapes/features-three-left-box-shape-3.png)no-repeat;
    background-size: contain;
    animation: 4s linear 1s infinite features-three-left-box-shape;
}

.features-three-left-box-shape-4 {
    position: absolute;
    content: "";
    width: 35px;
    height: 38px;
    left: 192px;
    bottom: -6px;
    background: url(../images/shapes/features-three-left-box-shape-4.png)no-repeat;
    background-size: contain;
    animation: 4s linear 2s infinite features-three-left-box-shape;
}

.features-three-left-img-2 {
    margin-left: 20px;
}

.features-three-left-img-box img {
    width: 250px;
    height: 430px;
    border-radius: 125px;
    transition: all 0.5s;
    transform: scale(1.1);
}

.features-three-left-img-1 {
    margin-bottom: 115px;
    position: relative;
    border-radius: 125px;
    overflow: hidden;
    z-index: 9;
}

.features-three-left-img-1-shape,
.features-three-left-img-2-shape {
    position: relative;
}

.features-three-left-img-1-shape::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: -25px;
    bottom: -12px;
    border: 1px solid var(--zoso-text-light-three);
    border-radius: 125px;
    z-index: -1;
    animation: 5s linear infinite up-down-animation;
}

.features-three-left-img-2 {
    margin-top: 115px;
    position: relative;
    border-radius: 125px;
    overflow: hidden;
    z-index: 9;
}

.features-three-left-img-2-shape::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    right: -25px;
    bottom: -19px;
    border: 1px solid var(--zoso-text-light-three);
    border-radius: 125px;
    z-index: -1;
    animation: 5s linear infinite up-down-animation;
}

.features-three-left-img-1 img:hover,
.features-three-left-img-2 img:hover {
    transform: scale(1);
}

.features-three-right-box {
    margin-left: 20px;
}

.features-three-right-info-box {
    margin-top: 50px;
    padding-top: 50px;
    border-top: 1px solid var(--zoso-text-light-three);
}

.features-three-right-info-title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.features-three-right-info-title i {
    margin-right: 20px;
    min-width: 25px;
    min-height: 25px;
    position: relative;
    border-radius: 50%;
    background-color: var(--zoso-text-primary-two);
    font-size: 11px;
}

.features-three-right-info-title h4 {
    color: #FFF;
    font-family: var(--bs-font-DM-Sans);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.72px;
}

.features-three-right-info-title i::before {
    position: absolute;
    color: var(--zoso-text-light);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.features-three-right-info-detail {
    width: 95%;
}

.features-three-right-info-detail p {
    color: rgba(255, 255, 255, 0.65);
    font-family: var(--bs-font-lexend-deca);
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}

.features-three-right-info-box .features-three-right-info+.features-three-right-info {
    margin-top: 40px;
}

/***********************
* Service-two
***********************/
.service-two {
    padding-top: 130px;
    padding-bottom: 130px;
}

.service-two-title-box .section-two-title-title {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
}

.service-two-title-box {
    margin-bottom: 80px;
}

.service-two-single {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 41px;
    padding-top: 43px;
    border-bottom: 1px solid var(--zoso-text-light-three);
}

.service-two-single-number span {
    color: rgba(255, 255, 255, 0.35);
    font-family: Lexend Deca;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    transition: all 0.3s;
}

.service-two-single-icon {
    margin-left: 150px;
}

.service-two-single-icon i {
    position: relative;
    font-size: 50px;
    background: var(--zoso-text-primary-gradient);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s;
}

.service-two-single:hover:hover .service-two-single-icon i {
    background-image: var(--zoso-gradient-one);
}

.service-two-single-title-box {
    margin-left: 50px;
}

.service-two-single-title-box h4 {
    display: inline-block;
    background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 100%/0px 1px no-repeat;
    color: var(--zoso-text-light);
    text-decoration: none;
    font-family: var(--bs-font-DM-Sans);
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: -0.2px;
    margin-bottom: 7px;
    transition: all 0.3s;
}

.service-two-single:hover:hover .service-two-single-title-box h4 {
    background-size: 100% 1px;
}

.service-two-single:hover:hover .service-two-single-number span {
    color: var(--zoso-text-light);
}

.service-two-single-title-box p {
    color: rgba(255, 255, 255, 0.65);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

.service-two-single-btn {
    margin-left: auto;
    transition: all 0.3s;
}

.service-two-single-btn a {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 45px;
    overflow: hidden;
    border-radius: 50%;
    background: var(--zoso-text-light-three);
    transition: all 0.3s;
    z-index: 9;
}

.service-two-single-btn a i {
    position: absolute;
    z-index: 9;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: var(--zoso-text-light);
}

.service-two-single-btn a:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-image: linear-gradient(92deg, #3F5EFB 0.14%, #FC466B 100%);
    transition: opacity 0.3s ease-out;
    z-index: 0;
    opacity: 0;
}

.service-two-single:hover .service-two-single-btn a::after {
    opacity: 1;
}

/* service-two-hover-image */

.service-two-hover-item-box,
.peoject-one-hover-item-box {
    position: absolute;
    top: 10%;
    left: 185px;
    opacity: 0;
    pointer-events: none;
    transform: translate(-100%, -50%);
    overflow: hidden;
    transform: scale(0.8);
    transition: all 0.5s ease-out;
    z-index: 999;
}

.service-two-hover-item-box {
    width: 250px;
    height: 270px;
}

.peoject-one-hover-item-box {
    width: 255px;
    height: 250px;
}

.service-two-hover-item-box-img,
.peoject-one-hover-item-box-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s ease-out;
}

.service-two-hover-item-box-img {
    border-radius: 135px 135px 0 0;
}

.service-two-hover-item-box {
    opacity: 0;
    transform: translate(-50%, -50%);
    left: 858px;
    transition: opacity 0.3s, transform 0.3s, left 0.3s;
}

.service-two-hover-item-box:hover {
    opacity: 1;
    transform: translate(-100%, -50%);
    left: 575px;
}

.peoject-one-hover-item-box-img {
    border-radius: 20px;
}

/***********************
* Fact-one
***********************/
.fact-one {
    position: relative;
}

.fact-one-left-box .section-two-title .section-two-title-title {
    width: 75%;
}

.fact-one-left-box .section-two-details {
    width: 85%;
}

.fact-one-left-inner-box {
    margin-top: 50px;
    position: relative;
}

.fact-one-left-inner-box::after {
    position: absolute;
    content: "";
    width: 1px;
    height: 100%;
    left: 38%;
    top: 0;
    transform: translateX(-50%);
    background-color: var(--zoso-text-light-three);
}

.fact-one-left-inner-title {
    padding: 25px 0;
    position: relative;
}

.fact-one-left-inner-title h5 {
    color: #FFF;
    font-family: var(--bs-font-DM-Sans);
    font-size: 45px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.3px;
    margin-bottom: 8px;
    transition: all 0.3s;
    display: inline-block;
}

.fact-one-left-inner-title p {
    color: rgba(255, 255, 255, 0.55);
    font-family: Lexend Deca;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.fact-one-left-inner-title h5 i {
    position: absolute;
    left: 0;
    top: 30px;
    font-size: 50px;
    background: var(--zoso-gradient-one);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s;
    font-weight: 700;
    opacity: 0;
    animation-delay: 2s;
}

.fact-one-left-inner-title h5:hover i {
    opacity: 1;
}

.fact-one-left-inner-title h5:hover {
    color: var(--zoso-text-primary-gradient);
    padding-left: 60px;
}

.fact-one-right-box {
    position: relative;
    width: 470px;
    height: 530px;
}

.fact-one-img-box {
    position: relative;
    width: 470px;
    height: 530px;
    overflow: hidden;
    z-index: 9;
    border-radius: 235px 235px 0 0;
}

.fact-one-img-box .scroll_item_select_img img {
    top: -430px;
    object-fit: cover;
}

.fact-one-right-box::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: -30px;
    top: -30px;
    border: 1px solid var(--zoso-text-light-three);
    border-radius: 235px 235px 0 0;
    z-index: -1;
}

/***********************
* Case-one
***********************/
.case-one {
    padding-top: 130px;
    padding-bottom: 130px;
}

.case-one .container-fluid {
    max-width: 1785px;
}

.case-one-title-box {
    margin-bottom: 70px;
}

.case-one-title-box .section-two-title .section-two-title-title {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
}

.case-one-single {
    position: relative;
    z-index: 9;
}

.case-one-single-img-box {
    margin-right: 70px;
}

.case-one-single-img-box img {
    border-radius: 16px;
    width: 100% !important;
}

.case-one-single-details-box {
    position: relative;
    background-color: #131826;
    margin-left: 200px;
    margin-top: -184px;
    border-radius: 16px;
    padding: 35px 45px;
    border-radius: 16px;
    transition: all 0.3s;
    z-index: 10;
}

.case-one-single-details-box:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background-image: linear-gradient(92deg, #3F5EFB 0.14%, #FC466B 100%);
    transition: opacity 0.3s ease-out;
    z-index: -1;
    opacity: 0;
}

.case-one-single-text p {
    font-family: Lexend Deca;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    background: linear-gradient(92deg, #fc466b 0%, #3F5EFB 100.14%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 13px;
    transition: all 0.3s;
}

.case-one-single-title h5 {
    color: var(--zoso-text-light);
    font-family: var(--bs-font-DM-Sans);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.72px;
    padding-bottom: 30px;
    border-bottom: 1px solid var(--zoso-text-light-three);
    margin-bottom: 25PX;
    transition: all 0.3s;
}

.case-one-single-btn .read-more-btn {
    color: var(--zoso-text-light-two);
}

.case-one-single-btn .read-more-btn i {
    color: var(--zoso-text-light-two);
}

.case-one-single:hover .case-one-single-details-box::after {
    opacity: 1;
}

.case-one-single:hover .case-one-single-text p {
    background: linear-gradient(92deg, #ffffff00 0%, #ffffff00 100.14%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: var(--zoso-text-light);
    transition: all 0.3s;
}

.case-one-single:hover .case-one-single-title h5 {
    border-bottom: 1px solid var(--zoso-text-light);
}

.case-one-single:hover .read-more-btn {
    color: var(--zoso-text-light);
}

.case-one-single:hover .read-more-btn i {
    color: var(--zoso-text-light);
}

.case-one-slider .owl-nav.disabled+.owl-dots {
    margin-top: 70px;
}

.case-one-slider .owl-dots .owl-dot span {
    width: 5px;
    height: 5px;
    margin: 5px 5px;
    background: linear-gradient(92deg, #FC466B 0%, #3F5EFB 100.14%);
    -webkit-text-fill-color: transparent;
    display: block;
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    transition: opacity .2s ease;
    border-radius: 30px;
}

.case-one-slider .owl-dots .owl-dot.active span,
.case-one-slider .owl-dots .owl-dot:hover span {
    background: linear-gradient(92deg, #FC466B 0%, #3F5EFB 100.14%);
}

.case-one-slider .owl-dots .owl-dot.active {
    background: linear-gradient(#0F110F, #0F110F) padding-box, linear-gradient(92deg, #FC466B 0%, #3F5EFB 100.14%) border-box;
    color: #FFF;
    text-decoration: none;
}

/***********************
* Testimonial-two
***********************/
.testimonial-two {
    padding-bottom: 130px;
    position: relative;
    background: linear-gradient(0deg, rgba(69, 93, 245, 0.07) 0.36%, rgba(15, 19, 31, 0.00) 28.72%);
    overflow: hidden;
}

.testimonial-two-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 70px;
}

.testimonial-two-top-clients h4 {
    color: var(--zoso-text-light);
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 25px;
}

.testimonial-two-top .section-two-title .section-two-title-title {
    width: 65%;
}

.testimonial-two-top .section-two-title .section-two-title-title h2 {
    margin-bottom: 0;
}

.testimonial-two-top-clients-list {
    display: flex;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.testimonial-two-top-clients-list li {
    border-radius: 50%;
    width: 65px;
}

.testimonial-two-top-clients-list li+li {
    margin-left: -10px;
}

.testimonial-two-top-clients-list li img {
    border-radius: 50%;
}

.testimonial-two-detail {
    width: 90%;
}

.testimonial-two-slider {
    position: relative;
}

.testimonial-two-single-img-box {
    position: relative;
    background: var(--zoso-gradient-one);
    width: 350px;
    height: 350px;
    border-radius: 50%;
    overflow: hidden;
    z-index: 9;
}

.testimonial-two-single-img-box::after {
    content: "";
    position: absolute;
    background: url(../images/shapes/testimonial-two-single-img-shape.png);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
}

.testimonial-two-single-img-box img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.testimonial-two-detail-box {
    margin-left: -26px;
}

.testimonial-two-detail-star-box {
    margin-bottom: 41px;
}

.testimonial-two-detail-star-box i {
    color: var(--zoso-text-yellow);
    font-size: 23px;
}

.testimonial-two-detail-star-box i+i {
    padding-left: 5px;
}

.testimonial-two-detail-text-box p {
    color: var(--zoso-text-light);
    font-size: 22px;
    font-family: var(--bs-font-DM-Sans);
    font-weight: 500;
    line-height: 35px;
    padding-bottom: 40px;
    border-bottom: 1px solid var(--zoso-text-light-three);
    margin-bottom: 42px;
}

.testimonial-two-detail-title-box h4 {
    color: var(--zoso-text-light);
    font-family: var(--bs-font-DM-Sans);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.66px;
}

.testimonial-two-detail-title-box h4 span {

    color: var(--zoso-text-light-two);
    font-family: var(--bs-font-DM-Sans);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.48px;
}

.testimonial-two-detail .owl-theme .owl-nav {
    margin-top: 0;
    position: absolute;
    right: -193px;
    top: 50%;
    transform: translate(-50%, -50%);
    display: grid;
}

.testimonial-two-detail .owl-carousel .owl-nav button.owl-next,
.testimonial-two-detail .owl-carousel .owl-nav button.owl-prev {
    width: 50px;
    height: 50px;
    margin: 10px 0;
    border: 1px solid var(--zoso-text-light-three);
    display: inline-block;
    border-radius: 50%;
    position: relative;
    transition: all 0.3s;
}

.testimonial-two-detail .owl-carousel .owl-nav button.owl-next i,
.testimonial-two-detail .owl-carousel .owl-nav button.owl-prev i {
    color: var(--zoso-text-light-two);
    font-size: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.testimonial-two-detail .owl-carousel .owl-nav button.owl-next i {
    transform: translate(-50%, -50%) rotate(180deg);
}

.testimonial-two-detail .owl-theme .owl-nav [class*=owl-]:hover {
    background:
        linear-gradient(#0F110F, #0F110F) padding-box,
        linear-gradient(92deg, #FC466B 0%, #3F5EFB 100.14%) border-box;
    color: #FFF;
    text-decoration: none;
}

.testimonial-two-detail .owl-theme .owl-nav [class*=owl-]:hover i {
    background: linear-gradient(92deg, #FC466B 0%, #3F5EFB 100.14%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

/***********************
* Cta-two
***********************/
.cta-two {
    padding-top: 130px;
    padding-bottom: 130px;
    position: relative;
    overflow: hidden;
}

.cta-two-right-box .section-two-title .section-two-title-title {
    width: 83%;
}

.cta-two-right-btn {
    margin-top: 55px;
}

.cta-two-right-btn a.read-more-btn {
    margin-left: 40px;
    color: var(--zoso-text-light);
}

.cta-two-right-btn a.read-more-btn i {
    color: var(--zoso-text-light);
}

.cta-two-right-btn a.read-more-btn:hover,
.cta-two-right-btn a.read-more-btn:hover i {
    color: var(--zoso-text-primary);
}

.cta-two-left-box {
    justify-content: end;
    position: relative;
    margin-right: 10px;
}

.cta-two-left-img-1 {
    margin-bottom: 103px;
    position: relative;
    overflow: hidden;
    border-radius: 125px;
    z-index: 9;
}

.cta-two-left-img-box img {
    width: 250px;
    height: 370px;
    transition: all 0.5s;
    transform: scale(1.1);
}

.cta-two-left-img-1-shape,
.cta-two-left-img-2-shape {
    position: relative;
}

.cta-two-left-img-1-shape::after {
    content: "";
    position: absolute;
    width: 95%;
    height: 100%;
    left: -26px;
    top: -21px;
    border: 1px solid var(--zoso-text-light-three);
    border-radius: 125px;
    z-index: -1;
    animation: 5s linear infinite up-down-animation;
}

.cta-two-left-img-2 {
    margin-left: 20px;
    margin-top: 103px;
    position: relative;
    border-radius: 125px;
    overflow: hidden;
    z-index: 9;
}

.cta-two-left-img-2-shape::after {
    content: "";
    position: absolute;
    width: 95%;
    height: 100%;
    right: -24px;
    bottom: -16px;
    border: 1px solid var(--zoso-text-light-three);
    border-radius: 125px;
    z-index: -1;
    animation: 5s linear infinite up-down-animation;
    animation-direction: reverse;
}

.cta-two-left-img-1 img:hover,
.cta-two-left-img-2 img:hover {
    transform: scale(1);
}

.cta-two-left-box-shape-1 {
    position: absolute;
    width: 100px;
    height: 100px;
    right: 173px;
    top: 0;
    background: url(../images/shapes/features-three-left-box-shape-1.png)no-repeat;
    background-size: contain;
    animation: 4s linear infinite features-three-left-box-shape;
}

.cta-two-left-box-shape-2 {
    position: absolute;
    width: 62px;
    height: 71px;
    right: 309px;
    bottom: 0;
    background: url(../images/shapes/features-three-left-box-shape-2.png)no-repeat;
    background-size: contain;
    animation: 4s linear infinite features-three-left-box-shape;
}

.cta-two-left-box-shape-3 {
    position: absolute;
    width: 35px;
    height: 38px;
    right: 295px;
    bottom: 48px;
    background: url(../images/shapes/features-three-left-box-shape-3.png)no-repeat;
    background-size: contain;
    animation: 4s linear 1s infinite features-three-left-box-shape;
}

.cta-two-left-box-shape-4 {
    position: absolute;
    width: 35px;
    height: 38px;
    right: 284px;
    bottom: -6px;
    background: url(../images/shapes/features-three-left-box-shape-4.png)no-repeat;
    background-size: contain;
    animation: 4s linear 2s infinite features-three-left-box-shape;
}

/***********************
* Footer-two
***********************/
.site-footer-two {
    background: transparent;
    overflow: hidden;
    background: linear-gradient(0deg, rgba(69, 93, 245, 0.07) 0.36%, rgba(15, 19, 31, 0.00) 28.72%);
    position: relative;
}

.footer-main-two {
    padding-top: 100px;
    padding-bottom: 100px;
    border-top: 1px solid var(--zoso-text-light-three);
}

.footer-widget-link-two {
    margin-left: 0;
}

.footer-widget-address-two {
    margin-left: 54px;
}

.footer-bottom-two {
    border-top: 1px solid transparent;
    padding-top: 0;
    padding-bottom: 0;
}

.footer-bottom-two .footer-bottom-inner {
    border-top: 1px solid var(--zoso-text-light-three);
    padding-top: 40px;
    padding-bottom: 40px;
}

.footer-widget-address-two .footer-widget-address-details li i {
    background: linear-gradient(92deg, #FC466B 0%, #3F5EFB 100.14%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 16px;
    line-height: 30px;
    width: 20px;
}

.footer-widget-newsletter-two .newsletter-email-box input[type="email"] {
    position: relative;
    width: 100%;
    padding: 20px 25px;
    background-color: var(--zoso-text-light);
    border: none;
    border-radius: 60px;
    line-height: 1;
    outline: none;
}

.footer-widget-newsletter-two .newsletter-email-box .newsletter-send-box {
    margin-left: -146px;
    color: var(--zoso-text-light);
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    display: inline-flex;
    align-items: center;
    z-index: 9;
    background: var(--zoso-gradient-one);
    border-radius: 60px;
    width: auto;
    height: auto;
    padding: 14px 32px;
    border: none;
    outline: none;
    transition: all 0.3s;
}

.footer-widget-newsletter-two .newsletter-email-box .newsletter-send-box i {
    color: var(--zoso-text-light);
    font-size: 13px;
    position: relative;
    top: 0;
    left: 0;
    margin-left: 10px;
    transform: translate(0, 0) rotate(0deg);
    transition: all 0.3s;
}

.footer-widget-newsletter-two .newsletter-email-box .newsletter-send-box:hover i {
    transform: translate(0, 0) rotate(-45deg);
}

.footer-widget-link-two .footer-widget-link-details li a {
    position: relative;
    font-family: Lexend Deca;
    font-size: 16px;
    color: #ffffff8c;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    transition: all 0.2s;
    -webkit-background-clip: text;
    background-clip: text;
}

.footer-widget-link-two .footer-widget-link-details li a:hover {
    background-image: linear-gradient(92deg, #FC466B 0.14%, #3F5EFB 100%);
    color: transparent;
    font-weight: 400;
}

.footer-bottom-inner-two {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.footer-bottom-inner-two .copyright {
    margin-left: auto;
}

.footer-bottom-inner-two .footer-bottom-inner-item {
    margin-left: 80px;
}

.footer-bottom-inner-two .footer-bottom-inner-item-two li a {
    background: transparent;
}

.footer-bottom-inner-two .copyright p {
    color: var(--zoso-text-light);
}

.footer-bottom-inner-two .copyright p a {
    color: var(--zoso-text-light);
}

.footer-bottom-inner-two .copyright p a:hover {
    color: var(--zoso-text-primary);
}

/***********************
* Header-three
***********************/
.main-header-three {
    padding-top: 35px;
    padding-bottom: 35px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

.main-header-three .container-fluid {
    max-width: 1790px;
}

.main-header-three.sticky-fixed {
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: 0 7px 50px 0 rgba(0, 0, 0, .1);
    background-color: #151515;
    position: fixed;
    transform: translateY(0);
}

.main-header-three.sticky-fixed .main-menu-three-search-box i {
    top: 14px;
}

.main-menu-three {
    display: flex;
    align-items: center;
}

.main-menu-three .main-menu-logo {
    margin-left: 70px;
}

.main-menu-three-left-box {
    margin-left: auto;
    display: flex;
    align-items: center;
    margin-right: 40px;
    position: relative;
}

.main-menu-three-search-box input {
    padding: 0 0 22px 28px;
    border: none;
    outline: none;
    line-height: 11px;
    color: var(--zoso-text-light);
    background-color: transparent;
    border-bottom: 1px solid var(--zoso-text-light-three);
}

.main-menu-three-search-box i {
    position: absolute;
    color: var(--zoso-text-light);
    left: 0;
    top: 6px;
}

.main-menu-three-plus-box {
    display: flex;
    align-items: center;
}

.main-menu-three-plus-box button {
    display: inline-block;
    position: relative;
    margin-left: 30px;
    width: 46px;
    height: 46px;
    border-radius: 8px;
    background-color: var(--zoso-text-green);
    color: var(--zoso-text-dark);
    outline: none;
    border: none;
}

.main-menu-three-plus-box button i {
    position: absolute;
    font-size: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/***********************
* Banner-three
***********************/
.banner-three {
    position: relative;
    padding-top: 193px;
    padding-bottom: 177px;
    background: url(../images/background/banner-three-bg.png);
    background-size: cover;
    background-position-y: bottom;
    background-position-x: right;
    overflow: hidden;
}

.banner-three-shape-1 {
    position: absolute;
    width: 300px;
    height: 160px;
    left: 808px;
    top: 0;
    background: url(../images/shapes/banner-three-shape-1.png)no-repeat;
    background-size: contain;
}

.banner-three-shape-2 {
    position: absolute;
    width: 300px;
    height: 300px;
    left: 475px;
    bottom: -220px;
    background: url(../images/shapes/banner-three-shape-2.png)no-repeat;
    background-size: contain;
}

.banner-three-number {
    position: absolute;
    left: -50px;
    bottom: 110px;
    display: flex;
    align-items: center;
    transform: rotate(-90deg);
}

.banner-three-call-icon {
    position: relative;
    width: 30px;
    height: 30px;
    background-color: var(--zoso-text-green);
    border-radius: 50%;
    margin-right: 10px;
}

.banner-three-call-icon i {
    position: absolute;
    font-size: 14px;
    color: var(--zoso-text-dark);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
}

.banner-three-number a {
    color: var(--zoso-text-light);
    font-family: var(--bs-font-DM-Sans);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.54px;
}

.banner-three .section-three-title {
    margin-bottom: 20px;
}


.banner-three .section-three-title .section-three-title-text {
    margin-bottom: 15px;
}

.banner-three .section-three-title .section-three-title-text p {
    color: #F5CF6F;
    font-family: Lexend Deca;
    font-size: 20px;
    padding: 11px 15px;
    display: inline-block;
    background-color: #222325;
    border-radius: 7px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
}

.banner-three .section-three-title .section-three-title-title h1 {
    color: var(--zoso-text-light);
    font-family: var(--bs-font-DM-Sans);
    font-size: 90px;
    font-weight: 700;
    line-height: 99px;
    letter-spacing: -0.3px;
}

.banner-three .section-three-details {
    margin-bottom: 54px;
}

.banner-three .section-three-details p {
    color: var(--zoso-text-light);
    font-family: Lexend Deca;
    font-size: 22px;
    font-weight: 500;
    line-height: 28px;
}

.banner-three .section-three-title-title span {
    display: inline-block;
    font-style: italic;
    font-weight: 400;
    background: linear-gradient(81deg, #F5CF6F 0%, #00C39A 10.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.main-btn-three {
    display: inline-flex;
    position: relative;
    padding: 17px 40px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    color: var(--zoso-text-dark);
    background-image: var(--zoso-gradient-two);
    border-radius: 12px;
}

.main-btn-three i {
    color: var(--zoso-text-dark);
    font-size: 11px;
    font-weight: 600;
    transition: all 0.3s;
}

.main-btn-three:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-image: linear-gradient(92deg, #00C39A 0.14%, #F5CF6F 100%);
    transition: opacity 0.3s ease-out;
    z-index: -1;
    opacity: 0;
}

.main-btn-three:hover:after {
    opacity: 1;
}

.banner-three-right {
    position: relative;
    margin-left: 30px;
    z-index: 9;
}

.banner-three-right-shape-1,
.banner-three-right-shape-2 {
    position: absolute;
    width: 424px;
    height: 612px;
    left: -20px;
    top: -50px;
    border: 1px solid var(--zoso-text-light-three);
    border-radius: 50%;
    z-index: -1;
}

.banner-three-right-shape-1 {
    animation: 6s linear infinite banner-three-right-shape-1;
}

.banner-three-right-shape-2 {
    animation: 8s linear infinite reverse banner-three-right-shape-1;
}

@keyframes banner-three-right-shape-1 {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.banner-three-right-img-box {
    margin-right: -106px;
}

/***********************
* About-two
***********************/
.about-two {
    padding-top: 130px;
    padding-bottom: 130px;
    position: relative;
}

.section-three-title {
    margin-bottom: 28px;
}

.section-three-title .section-three-title-text {
    margin-bottom: 20px;
}

.section-three-title .section-three-title-text p {
    color: var(--zoso-text-dark);
    padding: 4px 15px;
    display: inline-block;
    background-color: var(--zoso-text-yellow-gradient);
    border-radius: 7px;
    font-family: Lexend Deca;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
}

.section-three-title .section-three-title-title h2 {
    color: var(--zoso-text-dark);
    font-family: var(--bs-font-DM-Sans);
    font-size: 45px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: -0.3px;
}

.section-three-details {
    margin-bottom: 40px;
}

.section-three-details p {
    color: var(--zoso-secondary-grey);
    font-family: Lexend Deca;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}

.about-two-right {
    margin-left: 70px;
}

.about-two-right-list-box {
    margin-bottom: 50px;
}

.about-two-right-list-detali {
    display: flex;
    align-items: center;
}

.about-two-right-list-detali i {
    position: relative;
    display: inline-block;
    width: 25px;
    height: 25px;
    font-size: 11px;
    background-color: var(--zoso-text-green);
    border-radius: 50%;
    margin-right: 20px;
}

.about-two-right-list-detali i::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--zoso-text-dark);
}

.about-two-right-list-detali p {
    color: var(--zoso-secondary-grey);
    font-family: var(--bs-font-Inter);
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
}

.about-two-right-list-box .about-two-right-list-detali+.about-two-right-list-detali {
    margin-top: 20px;
}

.about-two-right .section-three-title .section-three-title-title {
    width: 90%;
}


.about-two-right .section-three-details {
    width: 85%;
}

.about-two-left {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 9;
}

.about-two-left-img-box {
    overflow: hidden;
    width: 380px;
    height: 493px;
    border-radius: 190px 190px 0 0;
}

.about-two-left-img {
    position: relative;
    margin-left: 18px;
    z-index: 9;
    width: 380px;
    border-radius: 190px 190px 0 0;
    height: 493px;
}

.about-two-left::after {
    content: "";
    position: absolute;
    width: 380px;
    height: 493px;
    border: 1px solid var(--zoso-text-dark-two);
    border-radius: 190px 190px 0 0;
    top: -28px;
    left: -26px;
    z-index: -1;
}

.about-two-left-inner-clients-box {
    z-index: 9;
    position: absolute;
    bottom: -15px;
    right: 30px;
    padding: 23px 30px 30px 30px;
    background-color: var(--zoso-text-green);
    border-radius: 16px;
}

.about-two-left-inner-clients-box h4 {
    color: var(--zoso-text-dark);
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 13px;
}

.about-two-left-inner-clients-list {
    display: flex;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.about-two-left-inner-clients-list li {
    border-radius: 50%;
    width: 45px;
}

.about-two-left-inner-clients-list li img {
    border-radius: 50%;
    width: 45px;
    height: 45px;
}

.about-two-left-inner-clients-list li+li {
    margin-left: -10px;
}

.about-two-bottom-box-one {
    margin-top: 130px;
}

.about-two-category-box {
    display: flex;
    align-items: center;
}

.about-two-category-title {
    font-family: var(--bs-font-DM-Sans);
    font-size: 45px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: -1.35px;
    margin-bottom: 0;
    margin-right: 50px;
}

.about-two-category-img {
    width: 350px;
    height: 120px;
    border-radius: 40px;
}

.about-two-bottom-box-one .owl-carousel .owl-item img,
.about-two-bottom-box-two .owl-carousel .owl-item img {
    display: block;
    width: 350px;
}

.about-two-bottom-box-two {
    margin-top: 70px;
}

.about-two-bottom-box-two .about-two-category-box {
    flex-direction: row-reverse;
}

/***********************
* Service-three
***********************/
.service-three {
    position: relative;
    background-color: var(--zoso-text-yellow-10);
    padding-top: 130px;
    padding-bottom: 130px;
}

.service-three-tabs-box {
    padding-top: 50px;
}

.service-three-img {
    position: relative;
}

.service-three-detail {
    position: absolute;
    width: 210px;
    bottom: -36px;
    right: 30px;
    padding: 17px 30px 17px;
    background-color: var(--zoso-text-green);
    border-radius: 12px;
    transition: all 0.3s;
}

.service-three-detail .service-three-detail-text {
    color: var(--zoso-text-dark);
    font-family: Lexend Deca;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    padding-bottom: 14px;
    border-bottom: 1px solid rgba(15, 17, 15, 0.2);
    margin-bottom: 15px;
}

.service-three-img img {
    width: 270px;
    height: 300px;
    border-radius: 12px;
    transition: all 0.3s;
}

.service-three-img:hover img {
    transform: rotate(5deg);
}

.service-three-img:hover .service-three-detail {
    transform: translateY(10px);
}

.service-three-tabs-left,
.service-three-tabs-right {
    display: flex;
}

.service-three-tabs-right {
    margin-left: 35px;
}

.service-three-link {
    display: grid;
    align-items: center;
    margin-left: 50px;
    margin-bottom: 0 !important;
}

.service-three-link span {
    color: #696969;
    font-family: Lexend Deca;
    display: block;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.32px;
    margin-bottom: 10px;
}

.service-three-link .service-three-nav-link {
    padding: 0;
    outline: none;
    border: none;
    background-color: transparent;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.3px;
    background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 100%/0px 1px no-repeat;
    transition: all 500ms ease;
}

.service-three-link .service-three-nav-link:hover,
.service-three-link .service-three-nav-link.active {
    background-size: 100% 1px;
}

/***********************
* Video-one
***********************/
.video-one {
    position: relative;
    background-color: var(--zoso-text-yellow-10);
    padding-bottom: 130px;
}

.video-one-left-clients-box {
    display: flex;
    align-items: center;
}

.video-one-left-clients-list {
    display: flex;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.video-one-left-clients-list li {
    border-radius: 50%;
    width: 55px;
}

.video-one-left-clients-list li img {
    border-radius: 50%;
    width: 55px;
    height: 55px;
}

.video-one-left-clients-list li+li {
    margin-left: -10px;
}

.video-one-left-clients-box p {
    color: var(--zoso-text-dark);
    font-family: Lexend Deca;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin-left: 30px;
}

.video-one-right {
    display: flex;
    justify-content: end;
}

.video-one-video-box {
    width: 630px;
    height: 400px;
    position: relative;
    overflow: hidden;
    border-radius: 40px;
}

.video-one-video-box .video-one-video-img .scroll_item_select_img img {
    object-fit: cover;
    top: -200px;
}

.video-one-video-btn a {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: var(--zoso-gradient-two);
}

.video-one-video-btn i {
    position: absolute;
    font-size: 14px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: var(--zoso-text-dark);
    z-index: 9;
}

/***********************
* Process-one
***********************/
.process-one {
    position: relative;
    background-color: #151515;
    padding-top: 130px;
    padding-bottom: 130px;
    overflow: hidden;
    z-index: 99;
}

.process-one-shape-1 {
    position: absolute;
    width: 32px;
    height: 32px;
    left: 132px;
    top: 152px;
    z-index: -1;
}

.process-one-shape-1-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 32px;
    height: 32px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.75);
}

.process-one-shape-2 {
    position: absolute;
    width: 95px;
    height: 95px;
    left: 124px;
    bottom: 161px;
    z-index: -1;
}

.process-one-shape-2-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 95px;
    height: 95px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.75);
}

.process-one-shape-3 {
    position: absolute;
    width: 300px;
    height: 300px;
    left: 535px;
    top: -190px;
    z-index: -1;
}

.process-one-shape-3-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 300px;
    height: 300px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.75);
}

.process-one-shape-4 {
    position: absolute;
    width: 60px;
    height: 46px;
    top: 168px;
    right: 148px;
    z-index: 0;
}

.process-one-shape-4-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 60px;
    height: 46px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.75);
}

.process-one-shape-5 {
    position: absolute;
    width: 100px;
    height: 100px;
    right: 138px;
    bottom: 50px;
    z-index: 0;
}

.process-one-shape-5-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100px;
    height: 100px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.75);
}

.process-one .section-three-title .section-three-title-title h2 {
    color: var(--zoso-text-light);
}

.process-one-step {
    padding-top: 50px;
    margin-bottom: 130px;
    overflow: hidden;
}

.process-one-step-details {
    position: relative;
    z-index: 9;
}

.process-one-step-details::after {
    position: absolute;
    content: "";
    width: 300px;
    height: 1px;
    top: 133px;
    left: 0;
    background-color: var(--zoso-text-light-three);
    z-index: -1;
}

.process-one-step-details span {
    color: #5A5A5A;
    padding: 5px 15px;
    border: 1px solid #5A5A5A;
    border-radius: 30px;
    display: inline-block;
    font-family: Lexend Deca;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.2px;
    margin-bottom: 18px;
}

.process-one-step-details h5 {
    color: var(--zoso-text-light);
    font-family: var(--bs-font-DM-Sans);
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.6px;
    margin-bottom: 25px;
}

.process-one-step .process-one-step-details-icon {
    position: relative;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.process-one-step .process-one-step-details-icon i {
    color: var(--zoso-text-dark);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 35px;
}

.process-one-step .process-one-step-one-details-icon {
    background-color: var(--zoso-text-green);
}

.process-one-step .process-one-step-two-details-icon {
    background-color: var(--zoso-text-yellow);
}

.process-one-step .process-one-step-three-details-icon {
    background-color: var(--zoso-text-blue);
}

.process-one-step .process-one-step-four-details-icon {
    background-color: #46C5FC;
}

.process-one-step-details p {
    color: var(--zoso-text-light-two);
    font-family: Lexend Deca;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}

.process-one-bottom-left-img-box {
    padding-top: 50px;
    position: relative;
}

.process-one-bottom-left-img-box-shape-1 {
    position: absolute;
    width: 75px;
    height: 70px;
    bottom: -27px;
    left: 109px;
    background: url(../images/shapes/whychoose-one-left-img-shape-1.png);
    background-size: contain;
}

.process-one-bottom-left-img-box-shape-2 {
    position: absolute;
    width: 70px;
    height: 70px;
    top: 24px;
    right: 155px;
    background: url(../images/shapes/whychoose-one-left-img-shape-2.png);
    background-size: contain;
}

.process-one-bottom-left-img-1 {
    margin-bottom: 72px;
    width: 270px;
}

.process-one-bottom-left-img-2 {
    margin-top: 72px;
}


.process-one-bottom-right-info {
    position: relative;
}


.process-one-bottom-right-info {
    width: 370px;
    padding: 26px 40px 34px;
    border-radius: 12px;
    background-color: var(--zoso-text-light-three);
}

.process-one-bottom-right-info-1 {
    margin-left: auto;
    margin-right: 50px;
    margin-bottom: 50px;

}

.process-one-bottom-right-info-2 {
    margin-left: auto;
    margin-right: 100px;
    margin-bottom: 50px;

}

.process-one-bottom-right-info-3 {
    margin-left: auto;

}

.process-one-bottom-right-info-icon {
    position: absolute;
    top: -20px;
    right: 40px;
    width: 80px;
    height: 80px;
    border-radius: 12px;
    background: linear-gradient(130deg, var(--zoso-text-yellow-gradient) 16.42%, var(--zoso-text-green) 80.31%);
    z-index: 1;
}

.process-one-bottom-right-info-icon i {
    color: var(--zoso-text-dark);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
}

.process-one-bottom-right-info h2 {
    color: var(--zoso-text-light);
    font-family: var(--bs-font-DM-Sans);
    font-size: 45px;
    font-weight: 700;
    line-height: 59px;
    letter-spacing: -1.35px;
}

.process-one-bottom-right-info p {
    color: var(--zoso-text-light-two);
    font-family: Lexend Deca;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
}

/***********************
* Peoject-one
***********************/
.peoject-one {
    position: relative;
    padding-top: 130px;
    padding-bottom: 130px;
    overflow: hidden;
}

.peoject-one-shape-1 {
    position: absolute;
    width: 300px;
    height: 300px;
    top: -32px;
    right: 272px;
    background: url(../images/shapes/peoject-one-shape-1.png) no-repeat;
    background-size: contain;
    z-index: 0;
}

.peoject-one-title-box .section-three-title .section-three-title-title {
    width: 50%;
}

.peoject-one-info-box {
    padding-top: 45px;
}

.peoject-one-single {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 32px;
    padding-top: 35px;
    border-bottom: 1px solid var(--zoso-text-dark-two);
}

.peoject-one-info-box .peoject-one-single:first-child {
    border-top: 1px solid var(--zoso-text-dark-two);
}

.peoject-one-single .peoject-one-single-number span {
    font-family: Lexend Deca;
    color: #696969;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.2px;
    display: inline-block;
    margin-right: 90px;
}

.peoject-one-single:hover .peoject-one-single-title-box a {
    background-size: 100% 1px;
}

.peoject-one-single-title-box a {
    display: inline-block;
    background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 100%/0px 1px no-repeat;
    color: var(--zoso-text-dark);
    transition: all 500ms ease;
    text-decoration: none;
    font-family: var(--bs-font-DM-Sans);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: -0.72px;
    margin-bottom: 7px;
    transition: all 0.3s;
}

.peoject-one-single-btn {
    margin-left: auto;
}

.peoject-one-single-btn a {
    color: #696969;
    font-family: var(--bs-font-DM-Sans);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    transition: all 0.3s;
    display: flex;
    align-items: center;
}

.peoject-one-single-btn a i {
    font-size: 11px;
    margin-left: 10px;
}

.peoject-one-single:hover .peoject-one-single-btn a {
    color: var(--zoso-text-dark);
}

/***********************
* Partener-two
***********************/
.partener-two {
    position: relative;
    padding-bottom: 130px;
    padding-top: 30px;
    overflow: hidden;
}

.partener-two-img-box {
    position: relative;
    z-index: 9;
    width: 370px;
    height: 475px;
    margin-left: 30px;
    border-radius: 185px 185px 0 0;
}

.partener-two-img-box {
    position: relative;

}

.partener-two-img {
    position: relative;
    width: 370px;
    height: 475px;
    overflow: hidden;
    z-index: 9;
    border-radius: 235px 235px 0 0;
}

.partener-two-img .scroll_item_select_img img {
    top: -550px;
    object-fit: cover;
}

.partener-two-info-box .section-three-title .section-three-title-title {
    width: 80%;
}

.partener-two-img-box::after {
    content: "";
    position: absolute;
    width: 370px;
    height: 475px;
    border: 1px solid var(--zoso-text-dark-two);
    border-radius: 190px 190px 0 0;
    top: -30px;
    left: -30px;
    z-index: -1;
}

.partener-two-info-img {
    padding: 35px 0;
}

/***********************
* Testimonial-three
***********************/
.testimonial-three {
    position: relative;
    background-color: var(--zoso-text-yellow-10);
    padding-top: 130px;
    padding-bottom: 130px;
}

.testimonial-three-shape-1 {
    position: absolute;
    width: 32px;
    height: 32px;
    left: 185px;
    top: 208px;
    z-index: 0;
}

.testimonial-three-shape-1-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 32px;
    height: 32px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.75);
}

.testimonial-three-shape-2 {
    position: absolute;
    width: 100px;
    height: 95px;
    left: 62px;
    bottom: 308px;
    background-size: contain;
    z-index: 0;
}

.testimonial-three-shape-2-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100px;
    height: 95px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.75);
}

.testimonial-three-shape-3 {
    position: absolute;
    width: 100px;
    height: 95px;
    right: 167px;
    top: 243px;
    background-size: contain;
    z-index: 0;
}

.testimonial-three-shape-3-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100px;
    height: 95px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.75);
}

.testimonial-three-shape-4 {
    position: absolute;
    width: 100px;
    height: 95px;
    right: 134px;
    bottom: 364px;
    background-size: contain;
    z-index: 0;
}

.testimonial-three-shape-4-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100px;
    height: 95px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.75);
}

.testimonial-three-single-img-box {
    position: relative;
    border-radius: 135px 135px 0 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.testimonial-three-single-img-box img {
    width: 270px;
    height: 287px;
    object-fit: cover;
    border-radius: 135px 135px 0 0;
    transition: all 0.5s;
    transform: scale(1.1);
}

.testimonial-three-single-img-box-shape:hover .testimonial-three-single-img-box img {
    transform: scale(1);
}

.testimonial-three-single-img-box-shape {
    position: relative;
    margin-left: 61px;
    width: 270px;
    height: 287px;
    z-index: 2;
}

.testimonial-three-single-img-box-shape::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid var(--zoso-text-dark);
    border-radius: 135px 135px 0 0;
    top: -10px;
    left: -10px;
    z-index: 1;
    transition: all 0.3s;
}

.testimonial-three-single-img-box-shape:hover::after {
    top: -20px;
}

.testimonial-three-single,
.testimonial-three-btn {
    margin-top: 67px;
}

.testimonial-three-detail-box {
    margin-right: 110px;
}

.testimonial-three-single-two .testimonial-three-detail-box {
    margin-right: 0;
    margin-left: 110px;
}

.testimonial-three-star-icon {
    margin-bottom: 30px;
}

.testimonial-three-star-icon i {
    font-size: 22px;
    color: var(--zoso-text-green);
}

.testimonial-three-star-icon i+i {
    margin-left: 5px;
}

.testimonial-three-single-detaile {
    padding-bottom: 30px;
    border-bottom: 1px solid var(--zoso-text-dark-two);
    margin-bottom: 30px;
}

.testimonial-three-single-detaile p {
    color: var(--zoso-text-dark);
    font-size: 22px;
    font-weight: 500;
    line-height: 35px;
}

.testimonial-three-single-title h3 {
    color: var(--zoso-text-dark);
    font-size: 22px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: -0.3px;
}

.testimonial-three-single-title h3 span {
    color: var(--zoso-text-dark);
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.3px;
}

.transparent-btn {
    display: inline-flex;
    position: relative;
    padding: 17px 40px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    color: var(--zoso-text-dark);
    background: transparent;
    border: 2px solid var(--zoso-text-dark-two);
    border-radius: 12px;
}

.transparent-btn-two {
    display: inline-flex;
    position: relative;
    padding: 17px 40px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    color: var(--zoso-text-light);
    background: transparent;
    border: 1.5px solid var(--zoso-text-light-three);
    border-radius: 12px;
    transition: all 0.3s;
}

.transparent-btn-two:hover {
    background: var(--zoso-text-dark-blue);
    border: 1.5px solid var(--zoso-text-dark-blue);
}

.transparent-btn-two i {
    color: var(--zoso-text-light);
}

/***********************
* Blog-three
***********************/
.blog-three {
    padding-top: 130px;
    padding-bottom: 130px;
}

.blog-three-title-box {
    display: flex;
    align-items: end;
    justify-content: space-between;
    margin-bottom: 40px;
}

.blog-three-title-box .section-three-title {
    margin-bottom: 0;
}

.blog-three-single-box {
    margin-top: 30px;
    display: flex;
    align-items: center;
    padding: 30px 40px 30px 30px;
    border: 1px solid var(--zoso-text-dark-two);
}

.blog-three-single-img-box {
    border: 1px solid var(--zoso-text-dark-two);
    border-radius: 130px 130px 0 0;
    transition: all 0.3s;
}

.blog-three-single-img-box img {
    min-width: 200px;
    height: 229px;
    object-fit: cover;
    border-radius: 130px 130px 0 0;
    transition: all 0.3s;
}

.blog-three-single-box:hover .blog-three-single-img-box img {
    transform: translateY(10px);
}

.blog-three-single-box:hover .blog-three-single-img-box {
    transform: translateY(-5px);
}

.blog-three-single-detail {
    margin-left: 35px;
}

.blog-three-single-detail .blog-three-date {
    color: #696969;
    font-family: Lexend Deca;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 10px;
}

.blog-three-single-detail .blog-three-title {
    padding-bottom: 27px;
    border-bottom: 1px solid var(--zoso-text-dark-two);
    margin-bottom: 30px;
}

.blog-three-single-detail .blog-three-title a {
    display: inline;
    color: var(--font-title-color, #0F110F);
    font-family: var(--bs-font-DM-Sans);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.6px;
    background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 100% / 0px 1px no-repeat;
    transition: all 0.3s;
}

.blog-three-single-box:hover .blog-three-title a {
    background-size: 100% 1px
}

.blog-three-single-detail .blog-three-user-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.blog-three-single-detail .blog-three-user {
    display: flex;
    align-items: center;
}

.blog-three-single-detail .blog-three-user img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.blog-three-single-detail .blog-three-user a {
    color: #696969;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    margin-left: 10px;
}

/***********************
* Cta-three
***********************/
.cta-three {
    position: relative;
    padding-bottom: 130px;
}

.cta-three-title-box .section-three-details p {
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
}

.cta-three-title-box {
    position: relative;
}

.cta-three-shape-1 {
    position: absolute;
    width: 142px;
    height: 135px;
    left: 49px;
    top: 0;
    z-index: -1;
}

.cta-three-shape-1-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 142px;
    height: 135px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.75);
}

.cta-three-shape-2 {
    position: absolute;
    width: 152px;
    height: 128px;
    right: 92px;
    bottom: 0;
    z-index: -1;
}

.cta-three-shape-2-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 152px;
    height: 128px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.75);
}

/***********************
* Footer-three
***********************/
.site-footer-three {
    background-image: none;
    background-color: #151515;
}

.footer-widget-social-three li a {
    background-color: var(--zoso-text-green);
    position: relative;
}

.footer-widget-social-three li a i {
    z-index: 9;
}

.footer-widget-social-three li a::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-image: var(--zoso-gradient-two);
    transition: opacity 0.3s ease-out;
    z-index: 0;
    opacity: 0;
}

.footer-widget-social-three li a:hover::after {
    opacity: 1;
}

.footer-widget-link-three .footer-widget-link-details-three li a {
    -webkit-background-clip: text;
    background-clip: text;
}

.footer-widget-link-three .footer-widget-link-details-three li a:hover {
    background-image: var(--zoso-gradient-two);
    color: transparent;
    font-weight: 400;
}

.footer-widget-address-three .footer-widget-address-details-three li i {
    color: var(--zoso-text-green);
}

.footer-widget-newsletter-three .newsletter-email-box .newsletter-send-box {
    background: var(--zoso-gradient-two);
}

.footer-bottom-three {
    padding-top: 35px;
    padding-bottom: 35px;
}

/* page-4 */
/***********************
* Header-four
***********************/

.main-header-four .container-fluid {
    max-width: 1684px;
}

.main-menu-four {
    border-bottom: 1px solid var(--zoso-text-dark-two);
    margin-top: 0;
}

.main-header.sticky-fixed .main-menu {
    border-bottom: transparent;
}

.main-menu-four {
    justify-content: flex-start;
}

.main-menu-four-list {
    margin-left: 100px;
}

.main-menu-four-list>li {
    padding-top: 44px;
    padding-bottom: 44px;
    display: flex;
    align-items: center;
}

.main-menu-four-right {
    margin-left: auto;
}

.main-menu-right-number {
    display: flex;
    align-items: center;
    margin-right: 40px;
}

.main-menu-right-number a {
    color: var(--zoso-text-dark);
    font-family: var(--bs-font-DM-Sans);
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.4px;
}

.main-menu-right-number i {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    margin-right: 13px;
    background-color: var(--zoso-text-blue-gradient);
}

.main-menu-right-number i::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    color: var(--zoso-text-light);
}

.main-btn-four {
    display: inline-flex;
    position: relative;
    padding: 16px 40px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    color: var(--zoso-text-light);
    background-image: var(--zoso-gradient-three);
    border-radius: 12px;
}

.main-btn-four i {
    color: var(--zoso-text-light);
    font-size: 11px;
    font-weight: 600;
    transition: all 0.3s;
}

.main-btn-four:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-image: linear-gradient(130deg, #8358FF 15.93%, #01CBDF 72.14%);
    transition: opacity 0.3s ease-out;
    z-index: -1;
    opacity: 0;
}

.main-btn-four:hover:after {
    opacity: 1;
}

.main-header-five .main-menu-list>li>ul>li,
.main-header-five .main-menu-list>li>ul>li>ul>li {
    background-color: var(--zoso-bg-light);
}

.main-header-five .main-menu-list>li>ul>li:hover,
.main-header-five .main-menu-list>li>ul>li>ul>li:hover {
    background-color: var(--zoso-text-dark-blue);
}

/***********************
* Banner-four
***********************/
.banner-four {
    padding-top: 230px;
    padding-bottom: 170px;
    background-color: var(--zoso-text-yellow-two);
}

.banner-four-left {
    position: relative;
}

.banner-four-left-img-box {
    position: relative;
    width: 300px;
    height: 380px;
    margin-right: auto;
    margin-left: 70px;
}

.banner-four-left-img-box img {
    width: 100%;
    border-radius: 300px;
}

.banner-four-left-img-box::after {
    position: absolute;
    content: "";
    width: 300px;
    height: 380px;
    top: 0;
    left: 0;
    border: 1px solid var(--zoso-text-dark-two);
    border-radius: 300px;
    animation: 4s linear 2s infinite banner-four-left-img-box;
}

.banner-four-left-img-box::before {
    position: absolute;
    content: "";
    width: 300px;
    height: 380px;
    top: 0;
    left: 0;
    border: 1px solid var(--zoso-text-dark-two);
    border-radius: 300px;
    animation: 4s linear infinite banner-four-left-img-box;
}

@keyframes banner-four-left-img-box {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.1);
    }

    50% {
        transform: scale(1.2);
    }

    75% {
        transform: scale(1.3);
        opacity: 1;
    }

    100% {
        transform: scale(1.4);
        opacity: 0;
    }
}

.banner-four-left-shape-1 {
    position: absolute;
    width: 109px;
    height: 109px;
    top: -60px;
    left: 0;
    border-radius: 50%;
    background-color: var(--zoso-bg-light);
    animation: 4s linear infinite banner-four-left-shape-1;
}

.banner-four-left-shape-1 img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 65px;
    height: 65px;
}

.banner-four-left-shape-2 {
    position: absolute;
    width: 205px;
    height: 140px;
    bottom: -160px;
    left: 0;
}

@keyframes banner-four-left-shape-1 {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.banner-four-left-shape-3 {
    position: absolute;
    width: 171px;
    height: 143px;
    top: -50px;
    right: 70px;
}

.banner-four-left-shape-4 {
    position: absolute;
    width: 75px;
    height: 75px;
    bottom: -35px;
    right: 140px;
    animation: 4s linear 3s infinite banner-four-left-shape-1;
}

.banner-four-right {
    margin-left: 50px;
}

.banner-four .section-four-title {
    margin-bottom: 26px;
}

.banner-four .section-four-title .section-four-title-title h1 {
    color: var(--zoso-text-dark);
    font-family: var(--bs-font-DM-Sans);
    font-size: 65px;
    font-weight: 700;
    line-height: 77px;
    letter-spacing: -0.3px;
    margin-bottom: 0;
    position: relative;
}

.banner-four .section-four-details {
    margin-bottom: 35px;
    width: 90%;
}

.banner-four .section-four-details p {
    color: #696969;
    font-family: var(--bs-font-DM-Sans);
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: -0.6px;
}

.section-four-title-title-shape {
    position: relative;
    z-index: 9;
}

.section-four-title-title-shape::after {
    content: "";
    position: absolute;
    background: url(../images/shapes/banner-four-right-title-shape.png)no-repeat;
    background-size: contain;
    left: 0;
    bottom: 2px;
    width: 100%;
    height: 15px;
    z-index: -1;
}

.section-four-btn-box {
    position: relative;
}

.section-four-btn-box input {
    width: 100%;
    padding: 25px 51px;
    line-height: 11px;
    box-shadow: 0 10px 60px 0 rgba(138, 138, 138, 0.1);
    border: none;
    outline: none;
    border-radius: 12px;
}

.section-four-btn-box label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 25px;
}

.section-four-btn-box .main-btn-four {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    outline: none;
    border: none;
}

.section-four-title-inner {
    position: relative;
    display: inline-block;
    width: 90px;
    height: 55px;
    background-color: var(--zoso-text-light);
    border-radius: 40px;
    box-shadow: 0 6px 40px 0 var(--zoso-text-dark-two);
}

.section-four-title-inner::after {
    position: absolute;
    content: "";
    width: 45px;
    height: 45px;
    border-radius: 40px;
    background-color: var(--zoso-text-yellow);
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
    z-index: 1;
}

.section-four-title-inner::before {
    position: absolute;
    content: "";
    width: 35px;
    height: 10px;
    background: url(../images/shapes/section-four-title-inner-img.png);
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 2;
}

/***********************
* Features-four
***********************/
.features-four {
    padding-top: 130px;
    padding-bottom: 130px;
}

.section-four-title {
    margin-bottom: 28px;
}

.section-four-title .section-four-title-text {
    margin-bottom: 20px;
}

.section-four-title .section-four-title-text p {
    color: var(--zoso-text-dark);
    padding: 4px 15px;
    display: inline-block;
    background-color: var(--zoso-text-yellow-gradient);
    border-radius: 7px;
    font-family: Lexend Deca;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
}

.section-four-title .section-four-title-title h2 {
    color: var(--zoso-text-dark);
    font-family: var(--bs-font-DM-Sans);
    font-size: 45px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: -0.3px;
}

.section-four-details {
    margin-bottom: 40px;
}

.section-four-details p {
    color: var(--zoso-secondary-grey);
    font-family: Lexend Deca;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}

.features-four .section-four-details p {
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
}

.section-four-title-title h2 .section-four-title-title-inner {
    position: relative;
    background: var(--zoso-gradient-three);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section-four-title-title h2 .section-four-title-title-inner::after {
    content: "";
    position: absolute;
    background: url(../images/shapes/section-four-title-shape.png)no-repeat;
    background-size: contain;
    left: 0;
    bottom: 4px;
    width: 100%;
    height: 10px;
    z-index: 1;
}

.blog-four-title-box .section-four-title-title h2 .section-four-title-title-inner::after {
    content: "";
    position: absolute;
    background: url(../images/shapes/section-four-title-shape-two.png)no-repeat;
    background-size: contain;
    left: 0;
    bottom: 4px;
    width: 100%;
    height: 10px;
    z-index: 1;
}

.features-four-title-box .section-four-title .section-four-title-title {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.features-four-box {
    padding: 30px 30px 30px 40px;
    border-radius: 16px;
    margin-top: 30px;
    transition: all 0.3s;
}

.features-four-box-1 {
    background-color: var(--zoso-bg-green);
    box-shadow: 0 7px 30px 0 rgba(96, 203, 161, 0.5);
}

.features-four-box-1:hover {
    box-shadow: none;
}

.features-four-box-2 {
    background-color: var(--zoso-bg-green-two);
    box-shadow: 0 7px 30px 0 rgba(147, 213, 225, 1);
}

.features-four-box-2:hover {
    box-shadow: none;
}

.features-four-box-3 {
    background-color: var(--zoso-bg-blue);
    box-shadow: 0 7px 30px 0 rgba(202, 155, 249, 1);
}

.features-four-box-3:hover {
    box-shadow: none;
}

.features-four-box-4 {
    background-color: var(--zoso-text-yellow);
    box-shadow: 0 7px 30px 0 rgba(233, 209, 117, 1);
}

.features-four-box-4:hover {
    box-shadow: none;
}

.features-four-icon-one {
    margin-bottom: 10px;
}

.features-four-icon-one i {
    font-size: 24px;
}

.features-four-title-box h3 {
    color: var(--zoso-text-dark);
    font-family: var(--bs-font-DM-Sans);
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.6px;
    margin-bottom: 30px;
}

.features-four-icon-two {
    margin-bottom: 25px;
}

.features-four-icon-two i {
    font-size: 50px;
}

.features-four-detail-box p {
    color: var(--zoso-text-dark);
    font-family: Lexend Deca;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 25px;
}

.features-four-btn {
    position: relative;
    display: inline;
}

.features-four-btn a {
    color: var(--zoso-text-dark);
    font-family: var(--bs-font-DM-Sans);
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    opacity: 0;
    transition: all 0.3s;
    padding-right: 10px;
}

.features-four-btn i {
    position: absolute;
    color: var(--zoso-text-dark);
    font-size: 16px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s;
}

.features-four-box:hover .features-four-btn a {
    opacity: 1;
}

.features-four-box:hover .features-four-btn i {
    left: 100%;
    font-size: 14px;
}

/***********************
* Joinus-two
***********************/
.joinus-two {
    padding-bottom: 130px;
}

.joinus-two-left-box {
    margin-right: 95px;
}

.joinus-two-left-box .section-four-title-title h2 .section-four-title-title-inner::after {
    background: url(../images/shapes/section-four-title-shape-two.png);
    background-size: cover;
}

.joinus-two-left-list-detali {
    display: flex;
    align-items: center;
}

.joinus-two-left-list-detali i {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    font-size: 12px;
    background-color: rgba(131, 88, 255, 0.15);
    border-radius: 50%;
    margin-right: 15px;
}

.joinus-two-left-list-detali i::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--zoso-text-blue-gradient);
    z-index: 9;
}

.joinus-two-left-list-detali p {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.3px;
    font-family: var(--bs-font-DM-Sans);
    color: var(--zoso-text-dark);
}

.joinus-two-left-list-box .joinus-two-left-list-detali+.joinus-two-left-list-detali {
    margin-top: 20px;
}

.joinus-two-left-btn-box {
    margin-top: 50px;
}

.joinus-two-right-box {
    margin-left: 100px;
}

.joinus-two-right-img-top,
.joinus-two-right-img-bottom {
    position: relative;
}

.joinus-two-right-img-top .joinus-two-right-img-one {
    width: 225px;
    height: 180px;
    object-fit: cover;
    border-radius: 90px 90px 0 90px;
}

.joinus-two-right-img-top::after {
    content: "";
    position: absolute;
    width: 77px;
    height: 55px;
    left: -29px;
    top: 26px;
    background: url(../images/shapes/frame.png);
    background-size: contain;
    z-index: 1;
    animation: 2s linear infinite alternate shapeup;
}

@keyframes shapeup {
    0% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(30px);
    }
}

.joinus-two-right-img-top::before {
    content: "";
    position: absolute;
    width: 70px;
    height: 70px;
    right: -58px;
    bottom: -28px;
    background: url(../images/shapes/frame2.png);
    background-size: contain;
    z-index: 1;
    animation: 2s linear 1s infinite alternate shapeup;
}

.joinus-two-right-img-top .joinus-two-right-img-two {
    position: relative;
    width: 225px;
    height: 180px;
    object-fit: cover;
    border-radius: 0 90px 90px 0;
    margin-left: 15px;
}

.joinus-two-right-img-inner,
.joinus-two-right-img-bottom {
    margin-top: 20px;
}

.joinus-two-right-img-inner .joinus-two-right-img-three {
    position: relative;
    width: 225px;
    height: 180px;
    object-fit: cover;
    border-radius: 90px 0 0 90px;
}

.joinus-two-right-img-inner .joinus-two-right-img-four {
    position: relative;
    width: 225px;
    height: 180px;
    object-fit: cover;
    border-radius: 0 90px 90px 90px;
    margin-left: 15px;
}

.joinus-two-right-img-bottom .joinus-two-right-img-five {
    position: relative;
    width: 470px;
    height: 180px;
    object-fit: cover;
    border-radius: 90px 90px 90px 90px;
}

.joinus-two-right-img-bottom::before {
    content: "";
    position: absolute;
    width: 60px;
    height: 55px;
    left: -45px;
    top: -21px;
    background: url(../images/shapes/frame3.png);
    background-size: contain;
    z-index: 1;
    animation: 2s linear 1s infinite alternate shapeup;
}

.joinus-two-right-img-bottom::after {
    content: "";
    position: absolute;
    width: 77px;
    height: 55px;
    right: -20px;
    bottom: 18px;
    background: url(../images/shapes/frame4.png);
    background-size: contain;
    z-index: 1;
    animation: 2s linear infinite alternate shapeup;
}

/***********************
* Business-grow-one
***********************/
.business-grow-and-process {
    background-color: #262C2E;
}

.business-grow-one {
    position: relative;
    padding-top: 155px;
    padding-bottom: 130px;
    background-color: #262C2E;
    z-index: 9;
}

.business-grow-one-shape {
    position: absolute;
    width: 63px;
    height: 60px;
    left: 137px;
    bottom: 87px;
    background: url(../images/shapes/testimonial-four-shape-2.png);
    background-size: cover;
    z-index: -1;
}

.business-grow-one-right-box {
    margin-left: 55px;
}

.business-grow-one-right-box .section-four-title .section-four-title-title {
    width: 80%;
}

.business-grow-one-right-box .section-four-title .section-four-title-title h2,
.business-grow-one-right-box .section-four-details p {
    color: var(--zoso-text-light);
}

.business-grow-one-right-box .section-four-title-title h2 .section-four-title-title-inner::after,
.process-two-left-box .section-four-title-title h2 .section-four-title-title-inner::after {
    background: url(../images/shapes/section-four-title-shape-two.png) no-repeat;
    background-size: contain;
}

.business-grow-one-right-box .section-four-details {
    margin-bottom: 60px;
}

.business-grow-one-left-img-top {
    position: relative;
    display: flex;
    z-index: 9;
}

.business-grow-one-left-img-top::after {
    position: absolute;
    content: "";
    width: 77px;
    height: 65px;
    background: url(../images/shapes/business-grow-one-left-img-top-shape.png)no-repeat;
    background-size: contain;
    left: -35px;
    top: -8px;
    z-index: -1;
}

.business-grow-one-left-img-one {
    position: relative;
    width: 100px;
    height: 100px;
    margin-top: 55px;
    margin-left: 30px;
    margin-right: 70px;
}

.business-grow-one-left-img-one::after {
    position: absolute;
    content: "";
    width: 250px;
    height: 250px;
    background: url(../images/shapes/business-grow-one-left-img-one-shape-1.png) no-repeat;
    background-size: contain;
    left: 0;
    top: 14px;
    z-index: -1;
}

.business-grow-one-left-img-one img {
    border-radius: 10px;
}

.business-grow-one-left-img-two img {
    width: 270px;
    height: 365px;
    object-fit: cover;
    border-radius: 16px;
}

.business-grow-one-left-img-bottom {
    position: relative;
    display: flex;
    margin-top: -150px;
    z-index: 9;
}

.business-grow-one-left-img-three img {
    width: 270px;
    height: 300px;
    object-fit: cover;
    border-radius: 16px;
}

.business-grow-one-left-img-four {
    position: relative;
    display: inline;
    width: 170px;
    height: 170px;
    margin-left: 95px;
    margin-top: 93px;
    background-image: var(--zoso-gradient-three);
    border-radius: 50%;
}

.business-grow-one-left-img-four img:nth-child(1) {
    position: absolute;
    top: 50%;
    left: 50%;
    animation: 25s linear infinite business-grow-one-left-img-four;
}

@keyframes business-grow-one-left-img-four {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}

.business-grow-one-left-img-four img:nth-child(2) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.business-grow-one-left-img-two {
    position: relative;
}

.business-grow-one-left-img-two::after {
    content: "";
    position: absolute;
    width: 77px;
    height: 55px;
    right: -54px;
    top: 187px;
    background: url(../images/shapes/frame.png)no-repeat;
    background-size: contain;
    z-index: 1;
    transform: rotate(-50deg);
    animation: 2s linear infinite alternate shapeup-2;
}

@keyframes shapeup-2 {
    0% {
        transform: translateY(-10px)rotate(-50deg);
    }

    100% {
        transform: translateY(30px)rotate(-50deg);
    }
}

.business-grow-one-left-img-three {
    position: relative;
}

.business-grow-one-left-img-three::after {
    content: "";
    position: absolute;
    width: 77px;
    height: 55px;
    left: -47px;
    bottom: 67px;
    background: url(../images/shapes/frame4.png);
    background-size: contain;
    z-index: 1;
    transform: rotate(-50deg);
    animation: 2s linear infinite alternate shapeup;
}

/***********************
* Process-two
***********************/
.process-two {
    position: relative;
    padding-bottom: 130px;
    background-color: #262C2E;
    z-index: 9;
}

.process-two-shape {
    position: absolute;
    width: 52px;
    height: 50px;
    right: 139px;
    bottom: 149px;
    background: url(../images/shapes/footer-four-shape-4.png);
    background-size: cover;
    z-index: -1;
}

.process-two-left-box .section-four-title .section-four-title-title h2,
.process-two-left-box .section-four-details p {
    color: var(--zoso-text-light);
}

.process-two-left-box .section-four-title {
    margin-bottom: 35px;
    width: 75%;
}

.process-two-left-inner-box {
    display: flex;
    width: 81%;
}

.process-two-left-inner-number h4 {
    position: relative;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: var(--zoso-bg-cyan-gradient);
    display: inline-block;
    margin-top: 5px;
}

.process-two-left-inner-number h4 span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    font-weight: 700;
}

.process-two-left-inner-title-box {
    margin-left: 30px;
}

.process-two-left-inner-title-box h4 {
    color: var(--zoso-text-light);
    font-family: var(--bs-font-DM-Sans);
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.3px;
    margin-bottom: 10px;
}

.process-two-left-inner-title-box p {
    color: var(--zoso-text-light);
    font-family: Lexend Deca;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}

.process-two-left-box .process-two-left-inner-box+.process-two-left-inner-box {
    margin-top: 45px;
    padding-top: 45px;
    border-top: 1px solid var(--zoso-text-light-three);
}

.process-two-right-inner {
    position: relative;
    z-index: 9;
}

.process-two-right-inner::after {
    position: absolute;
    content: "";
    width: 470px;
    height: 600px;
    right: 0;
    top: -60px;
    background: url(../images/shapes/process-two-right-shape-1.png) no-repeat;
    background-size: contain;
    z-index: -1;
}

.process-two-right-inner::before {
    position: absolute;
    content: "";
    width: 540px;
    height: 600px;
    left: -20px;
    top: -60px;
    background: url(../images/shapes/process-two-right-shape-2.png) no-repeat;
    background-size: contain;
    z-index: -1;
}

.process-two-right-detail {
    display: inline-flex;
    align-items: center;
    padding: 10px 50px 10px 10px;
    border-radius: 40px;
}

.process-two-right-detail i {
    position: relative;
    width: 55px;
    height: 55px;
    margin-right: 21px;
    border-radius: 50%;
}

.process-two-right-detail i::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
}

.process-two-right-detail h4 {
    color: var(--zoso-text-dark);
    font-family: var(--bs-font-DM-Sans);
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.66px;
}

.process-two-right-detail-one {
    background-color: var(--zoso-text-yellow);
    margin-left: 57px;
    margin-bottom: 48px;
    transform: rotate(-5.5deg);
}

.process-two-right-detail-one i {
    background-color: #D6BF6A;
}

.process-two-right-detail-two {
    background-color: var(--zoso-text-green);
    margin-bottom: 48px;
    transform: rotate(0.81deg);
}

.process-two-right-detail-two i {
    background-color: #01A885;
}

.process-two-right-detail-three {
    background-color: var(--zoso-bg-blue);
    margin-left: 115px;
    margin-bottom: 48px;
    transform: rotate(-5.42deg);
}

.process-two-right-detail-three i {
    background-color: #BD80FA;
}

.process-two-right-detail-four {
    background-color: var(--zoso-bg-green-two);
    margin-left: 32px;
    transform: rotate(4.32deg);
}

.process-two-right-detail-four i {
    background-color: #76BCC9;
}

/* package-one */

.package-one {
    padding-top: 130px;
    padding-bottom: 130px;
    position: relative;
}

.package-one-title-box .section-four-title .section-four-title-title {
    width: 45%;
    margin-left: auto;
    margin-right: auto;
}

.package-one-title-box .section-four-details p {
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
}

.package-one-single {
    padding: 40px 45px;
    border: 1px solid var(--zoso-text-dark-two);
    border-radius: 12px;
    background-color: var(--zoso-bg-grey);
    transition: all 0.3s;
    margin-top: 30px;
}

.package-one-single-icon {
    margin-bottom: 26px;
}

.package-one-single-icon i {
    font-size: 65px;
    color: var(--zoso-text-blue-gradient);
}

.package-one-single-title {
    margin-bottom: 7px;
}

.package-one-single-title h3 {
    color: var(--zoso-text-dark);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
}

.package-one-single-detail {
    padding-bottom: 21px;
    border-bottom: 1px solid var(--zoso-text-dark-two);
    margin-bottom: 30px;
}

.package-one-single-detail p {
    color: var(--font-paragraph-color, #696969);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

.package-one-single-list {
    margin-bottom: 25px;
}

.package-one-single-list li {
    display: flex;
    align-items: center;
}

.package-one-single-list li i {
    font-size: 15px;
    font-weight: 400;
    margin-right: 10px;
}

.package-one-single-list li+li {
    margin-top: 25px;
}

.package-one-single-list li p {
    color: #545F71;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.package-one-single-price {
    margin-bottom: 10px;

}

.package-one-single-price h2 {
    color: var(--zoso-text-dark);
    font-size: 65px;
    font-weight: 700;
    line-height: 77px;
    letter-spacing: -0.3px;
}

.package-one-single-price h2 span {
    color: var(--zoso-text-dark);
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.3px;
}

.package-one-single-btn .main-btn-four {
    background-image: linear-gradient(130deg, #8358FF 15.93%, #8358FF 72.14%);
}

.package-one-single:hover {
    box-shadow: 0 10px 60px 0 rgba(131, 88, 255, 0.15);
    border: 1px solid transparent;
    background-color: var(--zoso-text-light);
}

/***********************
* Partener-three
***********************/
.partener-three {
    padding-bottom: 130px;
}

.partener-three-title-box .section-four-title .section-four-title-title h2 .section-four-title-title-inner::after {
    background: url(../images/shapes/section-four-title-shape-two.png);
}

.partener-three-img-box .partener-three-img {
    text-align: center;
    padding: 50px 0;
}

.partener-three-img-box {
    position: relative;
}

.partener-three-img-box::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background-color: var(--zoso-text-dark-two);
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.partener-three-img-box::before {
    position: absolute;
    content: "";
    width: 1px;
    height: 82%;
    background-color: var(--zoso-text-dark-two);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.partener-three-img-box-line::after {
    position: absolute;
    content: "";
    width: 1px;
    height: 82%;
    background-color: var(--zoso-text-dark-two);
    top: 50%;
    right: 25%;
    transform: translate(-50%, -50%);
}

.partener-three-img-box-line::before {
    position: absolute;
    content: "";
    width: 1px;
    height: 82%;
    background-color: var(--zoso-text-dark-two);
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
}

/***********************
* Testimonial-four
***********************/
.testimonial-four {
    padding-top: 130px;
    padding-bottom: 130px;
    position: relative;
    background-color: var(--zoso-bg-grey);
    overflow: hidden;
    z-index: 99;
}

.testimonial-four-shape-1 {
    position: absolute;
    width: 415px;
    height: 415px;
    left: -122px;
    top: -88px;
    background: url(../images/shapes/testimonial-four-shape-1.png);
    background-size: cover;
    z-index: -1;
}

.testimonial-four-shape-2 {
    position: absolute;
    width: 84px;
    height: 80px;
    left: 95px;
    top: 275px;
    background: url(../images/shapes/testimonial-four-shape-2.png);
    background-size: cover;
    z-index: -1;
}

.testimonial-four-shape-3 {
    position: absolute;
    width: 300px;
    height: 300px;
    right: 210px;
    top: -26px;
    background: url(../images/shapes/testimonial-four-shape-3.png);
    background-size: cover;
    z-index: -1;
}

.testimonial-four-shape-4 {
    position: absolute;
    width: 620px;
    height: 620px;
    right: 41px;
    bottom: -237px;
    background: url(../images/shapes/testimonial-four-shape-4.png);
    background-size: cover;
    z-index: -1;
}

.testimonial-four-shape-5 {
    position: absolute;
    width: 53px;
    height: 50px;
    right: 51px;
    bottom: 191px;
    background: url(../images/shapes/testimonial-four-shape-5.png);
    background-size: cover;
    z-index: -1;
}

.testimonial-four-title-box .section-four-title .section-four-title-title {
    width: 40%;
}

.testimonial-four-title-box .section-four-title .section-four-title-title h2 .section-four-title-title-inner {
    background: var(--zoso-text-dark);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

}

.testimonial-four-title-box .section-four-title .section-four-title-title h2 .section-four-title-title-inner::after {
    background: url(../images/shapes/section-four-title-shape-three.png)no-repeat;
    background-size: contain;
}

.testimonial-four-detail {
    margin-top: 110px;
    position: relative;
}

.testimonial-four-detail-img-one {
    position: absolute;
    width: 200px;
    height: 330px;
    top: -46px;
    left: 0;
    border-radius: 135px;
    z-index: 1;
}

.testimonial-four-detail-one-img {
    position: absolute;
    bottom: -46px;
    left: 0;
    z-index: 2;
    width: 215px;
    height: 330px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.7);
}

.testimonial-four-detail-one-img img,
.testimonial-four-detail-two-img img {
    border-radius: 135px;
}

.testimonial-four-detail-img-two {
    position: absolute;
    width: 200px;
    height: 330px;
    bottom: 20px;
    right: 0;
    border-radius: 135px;
    background-clip: content-box;
    z-index: 1;
}

.testimonial-four-detail-two-img {
    position: absolute;
    top: 20px;
    left: 0;
    z-index: 2;
    width: 215px;
    height: 330px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.7);
}

.testimonial-four-slider {
    max-width: 55%;
    margin-left: auto;
    margin-right: auto;
}

.testimonial-four-single-star-box {
    margin-bottom: 27px;
}

.testimonial-four-single-star-box i {
    color: var(--zoso-text-green);
    font-size: 23px;
}

.testimonial-four-single-star-box i+i {
    padding-left: 5px;
}

.testimonial-four-single-text-box p {
    color: var(--zoso-text-dark);
    font-size: 35px;
    font-family: var(--bs-font-DM-Sans);
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 21px;
}

.testimonial-four-single-title-box h4 {
    color: var(--zoso-text-dark);
    font-size: 22px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: -0.3px;
}

.testimonial-four-single-title-box h4 span {
    color: var(--zoso-text-dark);
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.3px;
}

.testimonial-three-thumb .swiper-slide img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    transition: all 0.3s;
    border: 3px solid transparent;
}

.testimonial-three-thumb {
    margin-top: 60px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.swiper-wrapper .swiper-slide-thumb-active img {
    transform: scale(1.6);
    border: 2px solid var(--zoso-text-dark);
}

.swiper-wrapper {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

/***********************
* Blog-four
***********************/
.blog-four {
    padding-top: 130px;
    padding-bottom: 130px;
}

.blog-four-title-box .section-four-title .section-four-title-title {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.blog-four-single {
    margin-top: 30px;
}

.blog-four-content-box {
    position: relative;
    z-index: 1;
    background-color: var(--zoso-bg-light);
}

.blog-four-img-box img {
    width: 370px;
    height: 300px;
    border-radius: 12px;
    object-fit: cover;
}

.blog-four-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    padding-bottom: 11px;
    border-bottom: 1px solid var(--zoso-text-dark-two);
    margin-bottom: 17px;
}

.blog-four-date p {
    font-size: 16px;
    color: var(--zoso-secondary-grey);
    font-weight: 400;
    line-height: 30px;
}

.blog-four-date a {
    background: var(--zoso-gradient-three);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    font-family: Lexend Deca;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
}

.blog-four-title {
    margin-bottom: 28px;
}

.blog-four-title h4 a {
    font-size: 24px;
    color: var(--zoso-text-dark);
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.66px;
    background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 100%/0px 1px no-repeat;
    transition: all 0.3s;
}

.blog-four-user-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.blog-four-single:hover .blog-four-title h4 a {
    background-size: 100% 1px;
}

.blog-four-single:hover .blog-four-user-btn .read-more-btn {
    background: var(--zoso-gradient-three);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

/***********************
* Footer-four
***********************/
.site-footer-four {
    position: relative;
    z-index: 991;
}

.site-footer-four-shape-1 {
    position: absolute;
    width: 84px;
    height: 80px;
    left: 61px;
    top: 133px;
    background: url(../images/shapes/footer-four-shape-1.png);
    background-size: cover;
    z-index: -1;
}

.site-footer-four-shape-2 {
    position: absolute;
    width: 310px;
    height: 113px;
    left: 0;
    bottom: 0;
    background: url(../images/shapes/footer-four-shape-2.png);
    background-size: cover;
    z-index: -1;
}

.site-footer-four-shape-3 {
    position: absolute;
    width: 65px;
    height: 65px;
    right: 152px;
    top: 40px;
    background: url(../images/shapes/footer-four-shape-3.png);
    background-size: cover;
    z-index: -1;
}

.site-footer-four-shape-4 {
    position: absolute;
    width: 52px;
    height: 50px;
    right: 84px;
    bottom: 103px;
    background: url(../images/shapes/footer-four-shape-4.png);
    background-size: cover;
    z-index: -1;
}


.site-footer-four {
    background: none;
    background-color: #262C2E;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    position: relative;
}

.footer-widget-about-four-title {
    color: var(--zoso-text-light);
    font-family: var(--bs-font-DM-Sans);
    font-size: 45px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: -1.35px;
    width: 50%;
}

.footer-widget-about-details-four {
    margin-top: 30px;
}

.footer-widget-about-details-four li {
    display: flex;
    align-items: center;
}

.footer-widget-about-details-four li i {
    color: var(--zoso-bg-cyan-gradient);
}

.footer-widget-about-details-four li+li {
    margin-top: 15px;
}

.footer-widget-about-details li a {
    color: var(--zoso-text-light);
    background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 100%/0px 1px no-repeat;
    line-height: 34px;
    font-size: 24px;
    margin-left: 13px;
    transition: all 0.3s;
}

.footer-widget-about-details li a:hover {
    background-size: 100% 1px;
}

.footer-widget-address-four-title {
    color: rgba(255, 255, 255, 0.45);
    font-family: var(--bs-font-DM-Sans);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.6px;
    margin-bottom: 22px;
}

.footer-widget-address-details-four {
    color: var(--zoso-text-light);
    font-family: var(--bs-font-DM-Sans);
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 45px;
    margin-bottom: 28px;
}

.footer-widget-social-four li a {
    background-color: var(--zoso-bg-cyan-gradient);
    position: relative;
    z-index: 9;
}

.footer-widget-social-four li a i {
    z-index: 9;
}

.footer-widget-social-four li a::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-image: var(--zoso-gradient-three);
    transition: opacity 0.3s ease-out;
    z-index: 0;
    opacity: 0;
}

.footer-widget-social-four li a:hover::after {
    opacity: 1;
}

.footer-bottom-inner-four .copyright p a:hover {
    color: var(--zoso-bg-cyan-gradient);
}

.footer-main-four {
    position: relative;
    z-index: 99;
}

.footer-main-four::after {
    position: absolute;
    content: "";
    width: 1px;
    height: 100%;
    background-color: var(--zoso-text-light-three);
    left: 50%;
    top: 0;
    transform: translateX(-50%);
}

/* Page-five */
/***********************
* Header-five
***********************/
.page-wrapper-five {
    background-color: #131418;
}


.main-header-five .container-fluid {
    max-width: 1644px;
}

.main-header-five.sticky-fixed {
    box-shadow: 0 7px 50px 0 rgba(0, 0, 0, .1);
    background-color: var(--zoso-text-dark);
    position: fixed;
    transform: translateY(0);
    z-index: 999;
}

.main-menu-five {
    justify-content: flex-start;
}

.main-menu-five-list>li>a,
.main-menu-five-list .menu-item-children>i::before {
    color: var(--zoso-text-light);
}

.main-menu-five-list>li.current>a,
.main-menu-five-list>li:hover>a {
    background-size: 100% 1px;
}

.main-menu-five-list>li:hover>a,
.main-menu-five-list>li.current>a,
.main-menu-five-list>li.current>i::before,
.main-menu-five-list>li:hover>i::before {
    color: var(--zoso-text-yellow);
}

/***********************
* Banner-five
***********************/
.banner-five {
    position: relative;
    padding-top: 208px;
    background: url(../images/background/banner-five-bg.png);
    background-color: #131418;
    background-position: center;
    background-size: contain;
    background-size: cover;
    overflow: hidden;
}

.banner-five-info-box {
    margin-bottom: 180px;
}

.main-menu-five-list {
    margin-left: 103px;
}

.banner-five-left .section-five-title .section-five-title-text {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.banner-five-left .section-five-title .section-five-title-title h1 {
    color: var(--zoso-text-light);
    font-family: var(--bs-font-DM-Sans);
    font-size: 65px;
    font-weight: 600;
    line-height: 77px;
    letter-spacing: 1.95px;
    margin-bottom: 54px;
}

.banner-five-left .section-five-title .section-five-title-text img {
    width: 37px;
    height: 40px;

}

.banner-five-left .section-five-title .section-five-title-text p {
    color: var(--zoso-text-yellow);
    background-color: transparent;
    font-family: var(--bs-font-DM-Sans);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.66px;
    margin-left: 15px;
}

.main-btn-five {
    display: inline-flex;
    position: relative;
    padding: 16px 40px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    color: var(--zoso-text-light);
    background-color: var(--zoso-text-dark-blue);
    border-radius: 12px;
}

.main-btn-five i {
    color: var(--zoso-text-light);
    font-size: 11px;
    font-weight: 600;
    transition: all 0.3s;
}

.banner-five-right-btn-box {
    display: flex;
    align-items: center;
}

.banner-five-right-video {
    display: flex;
    align-items: center;
    margin-left: 30px;
}

.banner-five-right-video .video-play {
    display: inline-block;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: var(--zoso-text-light);
    position: relative;
    margin-right: 20px;
}

.banner-five-right-video .video-play i {
    position: absolute;
    font-size: 13px;
    color: var(--zoso-text-dark);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.banner-five-right-video .banner-five-right-link {
    color: var(--zoso-text-light);
    background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 100%/0px 1px no-repeat;
    font-family: var(--bs-font-DM-Sans);
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    transition: all 0.3s;
}

.banner-five-right-video .banner-five-right-link:hover {
    color: var(--zoso-text-yellow);
    background-size: 100% 1px;
}

.banner-five-right {
    position: relative;
    z-index: 9;
}

.banner-five-right-inner-img {
    margin-top: 77px;
    margin-left: 110px;
    position: relative;
    width: 170px;
    height: 170px;
    background-color: var(--zoso-text-dark-blue);
    border-radius: 50%;
}

.banner-five-right-inner-img img:nth-child(1) {
    position: absolute;
    top: 50%;
    left: 50%;
    animation: 25s linear infinite business-grow-one-left-img-four;
    z-index: 9;
}

.banner-five-right-inner-img img:nth-child(2) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.banner-five-right .banner-five-right-img-box {
    position: absolute;
    top: -285px;
    left: 195px;
    width: 600px;
    height: 800px;
    z-index: -1;
}

.banner-five-right .banner-five-right-img-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0 0 300px 300px;
}

.banner-five-right-img-box {
    position: relative;
    width: 470px;
    height: 530px;
    overflow: hidden;
    z-index: 9;
    border-radius: 0 0 300px 300px;
}

.banner-five-right-img-box .scroll_item_select_img img {
    object-fit: cover;
}

/***********************
* Quoye-one
***********************/
.quoye-one {
    position: relative;
    padding-top: 22px;
    padding-bottom: 22px;
    background-color: var(--zoso-text-dark-blue);
    margin-left: -10px;
    margin-right: -10px;
    transform: rotate(-4deg);
    z-index: 99;
    margin-bottom: 80px;
}

.quoye-one::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: var(--zoso-text-dark-blue);
    z-index: 9;
}

.quoye-one-shape {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: var(--zoso-text-yellow);
    transform: rotate(6deg);
    z-index: -2;
}

.quoye-one .container-fluid {
    max-width: 1490px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 99;
}

.quoye-one-details {
    display: flex;
    justify-content: space-between;
}

.quoye-one-details-item {
    display: flex;
    align-items: center;
}

.quoye-one-details-item i {
    font-size: 30px;
    color: var(--zoso-text-light);
}

.quoye-one-details-item h4 {
    color: var(--zoso-text-light);
    font-family: var(--bs-font-DM-Sans);
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.6px;
    margin-left: 20px;
}

/***********************
* About-three
***********************/
.about-three {
    padding-top: 104px;
    padding-bottom: 130px;
    background-color: #131418;
}

.section-five-title .section-five-title-text {
    margin-bottom: 20px;
}

.section-five-title .section-five-title-text p {
    color: var(--zoso-text-dark);
    padding: 4px 15px;
    display: inline-block;
    background-color: var(--zoso-text-yellow-gradient);
    border-radius: 7px;
    font-family: Lexend Deca;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
}

.section-five-title .section-five-title-title {
    margin-bottom: 40px;
}

.section-five-title .section-five-title-title h2 {
    color: var(--zoso-text-light);
    font-family: var(--bs-font-DM-Sans);
    font-size: 45px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 0.3px;
}

.section-five-details p {
    color: rgba(255, 255, 255, 0.65);
    font-family: var(--bs-font-lexend-deca);
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}

.about-three-title-box .section-five-title .section-five-title-title {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.about-three-info {
    padding-top: 50px;
}

.about-three-left-box {
    display: flex;
    position: relative;
}

.about-three-left-box-shape-1 {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 280px;
    top: -20px;
    background: url(../images/shapes/about-three-left-box-shape-1.png)no-repeat;
    background-size: contain;
    animation: 4s linear infinite about-three-left-box-shape;
}

.about-three-left-box-shape-2 {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 217px;
    bottom: 30px;
    background: url(../images/shapes/about-three-left-box-shape-2.png)no-repeat;
    background-size: contain;
    animation: 4s linear infinite about-three-left-box-shape;
}

.about-three-right-box {
    margin-left: 70px;
}

.about-three-right-box .section-five-title .section-five-title-title {
    margin-bottom: 28px;
}

.about-three-right-box .section-five-title .section-five-title-title h3 {
    color: var(--zoso-text-light);
    font-size: 27px;
    font-weight: 700;
    line-height: 40.5px;
    letter-spacing: -0.81px;
}

.about-three-right-box .section-five-details {
    margin-bottom: 46px;
}

.about-three-left-img-1 {
    margin-bottom: 90px;
    position: relative;
    z-index: 9;
    overflow: hidden;
    border-radius: 150px;
}

.about-three-left-img-1 img,
.about-three-left-img-2 img {
    animation: 5s linear infinite alternate about-three-left-img-1;
}

@keyframes about-three-left-img-1 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.3);
    }
}

.about-three-left-img-box img {
    width: 270px;
    height: 475px;
    border-radius: 150px;
    object-fit: cover;
}

.about-three-left-img-2 {
    margin-top: 90px;
    margin-left: 30px;
    position: relative;
    z-index: 9;
    overflow: hidden;
    border-radius: 150px;
}


.about-three-right-range-detail h4 {
    color: #FFF;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.3px;
    margin-bottom: 14px;
}

.about-three-right-range-box .about-three-right-range-detail:first-child {
    margin-bottom: 51px;
}

.about-three-right-range-box .about-three-right-range-detail:first-child input[type="range" i] {
    accent-color: var(--zoso-text-yellow);
    width: 100%;
}

.about-three-right-range-box .about-three-right-range-detail:nth-child(2) input[type="range" i] {
    accent-color: var(--zoso-text-dark-blue);
    width: 100%;
}

/***********************
* Partener-four
***********************/
.partener-four .container-fluid {
    max-width: 1724px;
    margin-left: auto;
    margin-right: auto;
}

.partener-four-title {
    margin-bottom: 63px;
}

.partener-four-title p {
    color: var(--zoso-bg-light);
    font-family: var(--bs-font-DM-Sans);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: -0.48px;
}

.partener-four-title p span {
    color: var(--zoso-text-yellow);
}

.partener-four-single {
    position: relative;
    width: 200px;
    height: 200px;
    border: 1px dashed var(--zoso-text-light);
    border-radius: 50%;
    filter: brightness(0.3);
    transition: all 0.3s;
}

.partener-four-single:hover {
    filter: brightness(1);
}

.partener-four-single .partener-four-img-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.partener-four-single .partener-four-img-box img {
    max-width: 125px;
    width: 125px;
    height: 80px;
    object-fit: contain;
}

/***********************
* Service-four
***********************/
.service-four {
    padding-top: 130px;
    padding-bottom: 130px;
}

.service-four-title-box .section-five-title-title {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
}

.service-four-single {
    padding: 22px 30px 31px;
    border-radius: 20px;
    margin-top: 30px;
    transition: all 0.3s;
}

.service-four-single-one {
    background-color: var(--zoso-text-yellow);
}

.service-four-single-two {
    background-color: var(--zoso-text-blue);
}

.service-four-single-three {
    background-color: var(--zoso-text-green);
}

.service-four-single-one:hover {
    box-shadow: 0 0 30px 0 var(--zoso-text-yellow);
}

.service-four-single-two:hover {
    box-shadow: 0 0 30px 0 var(--zoso-text-blue);
}

.service-four-single-three:hover {
    box-shadow: 0 0 30px 0 var(--zoso-text-green);
}

.service-four-tag {
    margin-bottom: 23px;
}

.service-four-tag h6,
.service-four-title h4 {
    color: var(--zoso-text-dark);
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.3px;
}

.service-four-title {
    margin-top: 32px;
    margin-bottom: 8px;
}

.service-four-text p {
    color: var(--zoso-text-dark);
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}

/***********************
* Features-five
***********************/
.features-five {
    padding-top: 130px;
    background: url(../images/background/features-five-bg.png);
    background-color: var(--zoso-text-dark-blue);
    background-position-y: center;
    background-size: cover;
    overflow: hidden;
}

.features-five-left {
    width: 80%;
}

.features-five-left .section-five-details p {
    color: var(--zoso-text-yellow);
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 45px;
}

.features-five-right-btn-box {
    display: flex;
    align-items: center;
    margin-bottom: 60px;
}

.features-five-right-btn-box .features-five-right-btn .main-btn-five {
    background-color: var(--zoso-text-yellow);
    color: var(--zoso-text-dark);
}

.features-five-right-btn-box .features-five-right-btn .main-btn-five i::before {
    color: var(--zoso-text-dark);
}

.features-five-right-video {
    display: flex;
    align-items: center;
    margin-left: 30px;
}

.features-five-right-video .video-play {
    display: inline-block;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: var(--zoso-text-light);
    position: relative;
    margin-right: 20px;
}

.features-five-right-video .video-play i {
    position: absolute;
    font-size: 13px;
    color: var(--zoso-text-dark);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.features-five-right-video .features-five-right-link {
    color: var(--zoso-text-light);
    background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 100%/0px 1px no-repeat;
    font-family: var(--bs-font-DM-Sans);
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    transition: all 0.3s;
}

.features-five-right-video .features-five-right-link:hover {
    color: var(--zoso-text-yellow);
    background-size: 100% 1px;
}


.features-five-left-clients-box {
    display: flex;
    align-items: center;
}

.features-five-left-clients-list {
    display: flex;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.features-five-left-clients-list li {
    border-radius: 50%;
    width: 55px;
}

.features-five-left-clients-list li img {
    border-radius: 50%;
    width: 55px;
    height: 55px;
}

.features-five-left-clients-list li+li {
    margin-left: -15px;
}

.features-five-left-clients-box p {
    color: var(--zoso-text-light);
    font-family: Lexend Deca;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin-left: 30px;
}

.features-five .container-fluid {
    max-width: 1490px;
    margin-left: auto;
    margin-right: auto;
}

/***********************
* Quoye-two
***********************/
.quoye-two {
    position: relative;
    margin-top: 130px;
    padding-top: 22px;
    padding-bottom: 22px;
    background-color: var(--zoso-text-yellow);
    margin-left: -10px;
    margin-right: -10px;
    z-index: 99;
}

.quoye-two-details {
    display: flex;
    justify-content: space-between;
}

.quoye-two-details-item {
    display: flex;
    align-items: center;
}

.quoye-two-details-item i {
    font-size: 30px;
    color: var(--zoso-text-dark);
}

.quoye-two-details-item h4 {
    color: var(--zoso-text-dark);
    font-family: var(--bs-font-DM-Sans);
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.3px;
    margin-left: 20px;
}

.features-five-right {
    position: relative;
}

.fact-one-right-box {
    position: relative;

}

.features-five-right-img-box {
    position: relative;
    width: 430px;
    height: 540px;
    margin-left: 100px;
    overflow: hidden;
    z-index: 9;
    border-radius: 235px 235px 0 0;
}

.features-five-right-img-box .scroll_item_select_img img {
    top: -280px;
    object-fit: cover;
}

.features-five-right-shape-1 {
    position: absolute;
    width: 192px;
    height: 205px;
    left: -30px;
    top: 118px;
    background: url(../images/shapes/features-five-right-shape-1.png)no-repeat;
    background-size: contain;
    animation: 4s linear infinite about-three-left-box-shape;
    z-index: 11;
}

.features-five-right-shape-2 {
    position: absolute;
    width: 161px;
    height: 160px;
    left: -22px;
    bottom: 62px;
    background: url(../images/shapes/features-five-right-shape-2.png)no-repeat;
    background-size: contain;
    animation: 4s linear infinite about-three-left-box-shape;
    z-index: 1;
}

.features-five-right-shape-3 {
    position: absolute;
    width: 150px;
    height: 160px;
    right: -20px;
    bottom: 19px;
    background: url(../images/shapes/features-five-right-shape-3.png)no-repeat;
    background-size: contain;
    animation: 4s linear infinite about-three-left-box-shape;
    z-index: 10;
}

/***********************
* Case-two
***********************/

.case-two {
    padding-top: 130px;
    padding-bottom: 130px;
}

.case-two-slider {
    padding-top: 30px;
}

.case-two-single-box {
    display: flex;
    align-items: center;
    padding: 5px;
    background-color: #1A1B21;
    border: 1px solid var(--zoso-text-light-three);
    border-radius: 16px;
}

.case-two-single-img-box {
    width: 100%;
}

.case-two-single-img-box img {
    max-width: 237px;
    max-height: 377px;
    border-radius: 16px;
}

.case-two-slider .owl-item img {
    display: block;
    width: 237px;
}

.case-two-single-detail-box {
    margin-left: 45px;
    margin-right: 45px;
}

.case-two-single-detail-box span {
    display: inline-block;
    margin-bottom: 11px;
    color: var(--zoso-text-yellow);
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
}

.case-two-single-detail-box h4 {
    margin-bottom: 33px;
    color: var(--zoso-text-light);
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.3px;
}

.case-two-single-detail-box p {
    color: rgba(255, 255, 255, 0.65);
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 26px;
}

.case-two .owl-theme .owl-nav.disabled+.owl-dots,
.testimonial-five .owl-theme .owl-nav.disabled+.owl-dots,
.testimonial-six .owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 70px;
}

.case-two-slider .owl-dots .owl-dot span,
.testimonial-five-slider .owl-dots .owl-dot span,
.testimonial-six-slider .owl-dots .owl-dot span {
    width: 5px;
    height: 5px;
    margin: 5px 5px !important;
    -webkit-text-fill-color: transparent;
    display: block;
    transition: opacity .2s ease;
    border-radius: 30px;
}

.case-two-slider .owl-dots .owl-dot span,
.testimonial-five-slider .owl-dots .owl-dot span {
    background-color: rgba(255, 255, 255, 0.2);
}

.testimonial-six-slider .owl-dots .owl-dot span {
    background-color: rgba(15, 17, 15, 0.20);
}

.case-two-slider .owl-dots .owl-dot.active,
.testimonial-five-slider .owl-dots .owl-dot.active {
    border: 1px solid var(--zoso-text-yellow);
    color: #FFF;
    text-decoration: none;
}

.testimonial-six-slider .owl-dots .owl-dot.active {
    border: 1px solid var(--zoso-text-dark);
    color: #FFF;
    text-decoration: none;
}

.case-two-slider .owl-dots .owl-dot.active span,
.testimonial-five-slider .owl-dots .owl-dot.active span {
    background-color: var(--zoso-text-yellow);
}

.testimonial-six-slider .owl-dots .owl-dot.active span {
    background-color: var(--zoso-text-dark);
}


/***********************
* Process-three
***********************/
.process-three {
    padding-bottom: 149px;
    position: relative;
    overflow: hidden;
}

.process-three-left .section-five-title {
    margin-right: 100px;

}

.process-three-left-img {
    position: relative;
    margin-right: 100px;
    margin-top: 63px;
    height: 340px;
}

.process-three-left-img>img {
    border-radius: 0 50% 50% 50%;
    object-fit: cover;
    width: 270px;
    height: 250px;
}

.process-three-left-inner-img {
    position: absolute;
    right: 0;
    bottom: 0;
}

.process-three-left-inner-img>img {
    border-radius: 50% 50% 0 50%;
    object-fit: cover;
    width: 270px;
    height: 250px;
}

.process-three-left-img-shape-1 {
    position: absolute;
    background: url(../images/shapes/whychoose-one-left-img-shape-1.png)no-repeat;
    background-size: contain;
    width: 50px;
    height: 45px;
    left: 100px;
    bottom: 10px;
}

.process-three-left-img-shape-2 {
    position: absolute;
    background: url(../images/shapes/whychoose-one-left-img-shape-2.png)no-repeat;
    background-size: contain;
    width: 60px;
    height: 55px;
    right: 100px;
    top: 10px;
}

.process-three-right {
    position: relative;
}

.process-three-right::after {
    content: "";
    position: absolute;
    background: url(../images/shapes/whychoose-one-right-shape-1.png)no-repeat;
    background-size: contain;
    width: 100px;
    height: 200px;
    left: 0;
    bottom: 50px;
    z-index: 9;
}

.process-three-right::before {
    content: "";
    position: absolute;
    background: url(../images/shapes/whychoose-one-right-shape-2.png)no-repeat;
    background-size: contain;
    width: 100px;
    height: 200px;
    right: 0;
    top: 50px;
    z-index: 9;
}

.process-three-right-info {
    position: relative;
    width: 370px;
    padding: 58px 58px 40px 40px;
    border-radius: 15px;
    background-color: #1A1B21;
}

.process-three-right .process-three-right-info:nth-child(1) {
    margin-top: 22px;
}

.process-three-right .process-three-right-info:nth-child(2) {
    margin-top: 52px;
    margin-left: 93px;
}

.process-three-right .process-three-right-info:nth-child(3) {
    margin-top: 52px;
    margin-left: auto;
}

.process-three-right-info span {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -22px;
    left: 40px;
    width: 50px;
    height: 50px;
    font-size: 22px;
    font-weight: 700;
    color: var(--zoso-text-dark);
    line-height: 30px;
    letter-spacing: -0.3px;
}

.process-three-right-info h5 {
    color: var(--zoso-text-light);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.2px;
}

.process-three-right .process-three-right-info:nth-child(1) span {
    background: url(../images/shapes/process-three-right-shape-1.png);
}

.process-three-right .process-three-right-info:nth-child(2) span {
    background: url(../images/shapes/process-three-right-shape-2.png);
}

.process-three-right .process-three-right-info:nth-child(3) span {
    background: url(../images/shapes/process-three-right-shape-3.png);
}

/***********************
* Fact-two
***********************/
.fact-two {
    padding-bottom: 130px;
}

.fact-two-single {
    padding: 50px 0;
    text-align: center;
    border: 1px dashed #ffffff33;
    border-radius: 135px;
    transition: all 0.3s;
}

.fact-two-single:hover {
    border: 1px dashed var(--zoso-text-light);
}

.fact-two-single-top-icon {
    position: relative;
    width: 100px;
    height: 100px;
    background-color: var(--zoso-text-yellow);
    border-radius: 50%;
    margin-bottom: 32px;
    margin-left: auto;
    margin-right: auto;
}

.fact-two-single-top-icon-one {
    background-color: var(--zoso-text-yellow);
}

.fact-two-single-top-icon-two {
    background-color: var(--zoso-text-blue);
}

.fact-two-single-top-icon-three {
    background-color: var(--zoso-text-green);
}

.fact-two-single-top-icon-four {
    background-color: var(--zoso-text-primary);
}

.fact-two-single-top-icon i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    color: var(--zoso-text-dark);
    z-index: 9;
}

.fact-two-single-title h2 {
    color: var(--White, #FFF);
    font-size: 55px;
    font-style: normal;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 1px;
    margin-bottom: 23px;
}

.fact-two-single-detail p {
    color: var(--zoso-text-light-two);
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 35px;
}

.fact-two-single-bottom-icon i {
    font-size: 24px;
}

.fact-two-single-bottom-icon-one i {
    color: var(--zoso-text-yellow);
}

.fact-two-single-bottom-icon-two i {
    color: var(--zoso-text-blue);
}

.fact-two-single-bottom-icon-three i {
    color: var(--zoso-text-green);
}

.fact-two-single-bottom-icon-four i {
    color: var(--zoso-text-primary);
}

/***********************
* Testimonial-five 
* Testimonial-six
***********************/
.testimonial-five,
.testimonial-six {
    padding-bottom: 130px;
    position: relative;
}

.testimonial-six-shape-1 {
    position: absolute;
    background: url(../images/shapes/service-one-shape-4.png)no-repeat;
    background-size: cover;
    width: 70px;
    height: 85px;
    top: 168px;
    left: 75px;
}

.testimonial-six-shape-2 {
    position: absolute;
    background: url(../images/shapes/features-one-shape-3.png)no-repeat;
    background-size: cover;
    width: 70px;
    height: 85px;
    bottom: 142px;
    right: 122px;
}

.testimonial-six-title-box .section-three-details p {
    font-size: 18px;
    font-weight: 500;
}

.testimonial-five-slider,
.testimonial-six-slider {
    padding-top: 30px;
}

.testimonial-five-single-box,
.testimonial-six-single-box {
    padding: 40px;
    background-color: #1A1B21;
    border-radius: 12px;
}


.testimonial-five-single-title-box,
.testimonial-six-single-title-box {
    display: flex;
    align-items: center;
    margin-bottom: 26px;
}

.testimonial-five-single-title-img,
.testimonial-six-single-title-img {
    width: 40px;
    height: 40px;
    margin-right: 20px;
}

.testimonial-five-single-title-img img,
.testimonial-six-single-title-img img {
    border-radius: 50%;
}

.testimonial-five-single-title h4,
.testimonial-six-single-title h4 {
    font-family: Lexend Deca;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
}

.testimonial-five-single-title h4 {
    color: var(--zoso-bg-light);
}

.testimonial-six-single-title h4 {
    color: var(--zoso-bg-dark);
}

.testimonial-five-single-title h4 span,
.testimonial-six-single-title h4 span {
    font-family: var(--bs-font-DM-Sans);
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: -0.48px;
}

.testimonial-five-single-title h4 span {
    color: rgba(255, 255, 255, 0.65);
}

.testimonial-six-single-title h4 span {
    color: var(--zoso-bg-dark);
}

.testimonial-five-single-detail p,
.testimonial-six-single-detail p {
    color: var(--zoso-text-light);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 23px;
}

.testimonial-five-single-detail p {
    color: var(--zoso-text-light);
}

.testimonial-six-single-detail p {
    color: var(--zoso-text-dark);
}

.testimonial-five-single-box .star-icon i,
.testimonial-six-single-box .star-icon i {
    color: var(--zoso-text-yellow);
    font-size: 12px;
}

.testimonial-five-single-box .star-icon i {
    color: var(--zoso-text-yellow);
}

.testimonial-six-single-box .star-icon i {
    color: var(--zoso-text-d);
}

.testimonial-five-single-box .star-icon i+i,
.testimonial-six-single-box .star-icon i+i {
    margin-left: 3px;
}

.testimonial-six-single-box-one {
    background-color: rgba(152, 150, 255, 0.15);
}

.testimonial-six-single-box-two {
    background-color: rgba(233, 209, 117, 0.15);
}

.testimonial-six-single-box-three {
    background-color: rgba(102, 237, 255, 0.15);
}

/***********************
* Cta-four
***********************/
.cta-four {
    position: relative;
    padding-top: 130px;
    padding-bottom: 130px;
    background: url(../images/background/features-five-bg.png);
    background-color: var(--zoso-text-dark-blue);
    background-position-y: center;
    background-size: cover;
    z-index: 99;
}

.cta-four-shape-1 {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 132px;
    bottom: 88px;
}

.cta-four-shape-img-one {
    position: absolute;
    top: 19px;
    left: 4px;
    z-index: 2;
    width: 50px;
    height: 50px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.75);
}

.cta-four-shape-2 {
    position: absolute;
    width: 100px;
    height: 100px;
    right: 100px;
    top: 63px;
}

.cta-four-shape-img-two {
    position: absolute;
    top: 19px;
    left: 4px;
    z-index: 2;
    width: 100px;
    height: 100px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.75);
}

.cta-four-detail-box {
    position: relative;
}

.cta-four-detail-box-shape-1 {
    position: absolute;
    width: 200px;
    height: 280px;
    top: -46px;
    left: -87px;
    border-radius: 135px;
}

.cta-four-detail-box-shape-img-one {
    position: absolute;
    top: 15px;
    left: 10px;
    z-index: 2;
    width: 200px;
    height: 280px;
    object-fit: cover;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(.75);
}

.cta-four-detail-box-shape-img-two {
    position: absolute;
    top: 15px;
    left: 10px;
    z-index: 2;
    width: 200px;
    height: 280px;
    object-fit: cover;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(.75);
}

.cta-four-detail-box-shape-2 {
    position: absolute;
    width: 200px;
    height: 280px;
    bottom: -58px;
    right: -100px;
    background-size: cover;
    border-radius: 135px;
    background-clip: content-box;
}

.cta-four-detail-box-shape-1 img {
    border-radius: 135px;
}

.cta-four-detail-box-shape-img-one img,
.cta-four-detail-box-shape-img-two img {
    width: 200px;
    height: 280px;
    object-fit: cover;
    object-position: top;
}

.cta-four-detail-box-shape-3 {
    position: absolute;
    bottom: -32px;
    left: -145px;
    width: 180px;
    height: 250px;
    border: 1.5px solid var(--zoso-text-light-three);
    border-radius: 135px;
    z-index: -1;
}

.cta-four-detail-box-shape-4 {
    position: absolute;
    width: 200px;
    height: 280px;
    top: -29px;
    right: 0;
    border: 1.5px solid var(--zoso-text-light-three);
    border-radius: 135px;
    z-index: -1;
}

.cta-four-detail-box .section-five-title .section-five-title-title {
    margin-bottom: 34px;
    margin-left: auto;
    margin-right: auto;
    width: 48%;
}

.cta-four-detail-box .section-five-details {
    margin-bottom: 45px;
}

.cta-four-detail-box .section-five-details p {
    color: var(--zoso-text-light);
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
}

.cta-four-btn .main-btn-five {
    background-color: var(--zoso-text-yellow);
    color: var(--zoso-text-dark);
}

.cta-four-btn .main-btn-five i::before {
    color: var(--zoso-text-dark);
}


.footer-widget-social-five li a {
    background-color: rgba(255, 255, 255, 0.3);
}

.footer-widget-social-five li a i {
    color: var(--zoso-text-light);
    transition: all 0.3s;
}

.footer-widget-social-five li a:hover i {
    color: var(--zoso-text-dark);
}

/****************************
* Page-header 
*****************************/
.page-header {
    position: relative;
    display: block;
    z-index: 1;
    padding-top: 231px;
    padding-bottom: 68px;
}

.page-header-bg {
    position: absolute;
    background: url(../images/background/page-header-bg-1.png);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: top;
    background-size: cover;
    z-index: -1;
}

.page-header-shape-1 {
    position: absolute;
    background: url(../images/shapes/banner-one-shape-1.png) no-repeat;
    background-size: cover;
    width: 25px;
    height: 25px;
    left: 11%;
    top: 35%;
    animation: 4s linear infinite banner-one-shape-1;
}

.page-header-shape-2 {
    position: absolute;
    background: url(../images/shapes/banner-one-shape-3.png) no-repeat;
    background-size: cover;
    width: 222px;
    height: 175px;
    top: 0;
    right: 0;
    opacity: 0.2;
}

.page-header-shape-3 {
    position: absolute;
    background: url(../images/shapes/banner-one-shape-4.png) no-repeat;
    background-size: cover;
    width: 662px;
    height: 190px;
    top: 0;
    right: 0;
    opacity: 0.4;
}

.page-header-shape-4 {
    position: absolute;
    background: url(../images/shapes/banner-one-left-shape.png) no-repeat;
    background-size: cover;
    width: 60px;
    height: 46px;
    top: 26%;
    right: 25%;
    animation: 5s linear infinite banner-one-left-shape;
}

.page-header-inner {
    padding-bottom: 144px;
    border-bottom: 1px solid var(--zoso-text-dark-two);
}

.page-header-inner h2 {
    color: var(--zoso-text-dark);
    font-family: var(--bs-font-DM-Sans);
    font-size: 65px;
    font-weight: 700;
    line-height: 77px;
    letter-spacing: -0.3px;
    margin-bottom: 18px;
}

.page-header-inner-title {
    position: relative;
    display: inline-block;
    margin-bottom: 0;
}

.page-header-inner-title li a {
    position: relative;
    display: inline-block;
    font-family: var(--bs-font-DM-Sans);
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.6px;
    color: var(--zoso-secondary-grey);
    background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 100% / 0px 1px no-repeat;
    transition: all .3s ease;
}

.page-header-inner-title li a:hover {
    color: var(--zoso-text-dark);
    background-size: 100% 1px;
}

.page-header-inner-title li+li {
    margin-left: 15px;
    padding-left: 25px;
    position: relative;
}

.page-header-inner-title li+li::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border-top: 2px solid var(--zoso-text-dark);
    border-right: 2px solid var(--zoso-text-dark);
    top: 50%;
    left: 0;
    transform: translateY(-50%) rotate(45deg);
}

.page-header-inner-title li {
    position: relative;
    display: inline-block;
    font-weight: 500;
    transition: all 500ms ease;
}

.page-header-inner-title li h4 {
    color: var(--zoso-text-blue-gradient);
    font-family: var(--bs-font-DM-Sans);
    font-size: 20px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.6px;
}

/***********************
* About-page
***********************/
.about-page {
    position: relative;
    padding-top: 58px;
    padding-bottom: 130px;
    background: url(../images/background/about-pagr-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    z-index: 99;
}

.about-page-shape {
    position: absolute;
    width: 100%;
    height: 462px;
    top: 331px;
    left: 0;
    background: url(../images/shapes/aboutus-shape-1.png)no-repeat;
    background-size: cover;
    z-index: -1;
}

.about-four-top {
    margin-bottom: 100px;
}

.about-four-top-title-box p,
.section-text p {
    color: var(--font-paragraph-color, #696969);
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 13px;
}


.about-four-top-title-box h2 {
    width: 90%;
    color: var(--font-title-color, #0F110F);
    font-size: 45px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 0.3px;
}

.about-four-top-detail-box p {
    color: var(--font-paragraph-color, #696969);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

.about-four-top-detail-box p+p {
    margin-top: 22px;
}

.about-four-top-detail-box .main-btn {
    margin-top: 31px;
}




[data-scroll] {
    will-change: transform;
}


.about-five {
    padding-top: 125px;
}

.about-four-title-box .section-title {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
}

.about-five-single-box {
    padding: 40px 63px 31px;
    background-color: var(--zoso-text-light);
    border-radius: 16px;
    margin-top: 30px;
}

.about-five-single-icon-box {
    position: relative;
    width: 85px;
    height: 85px;
    border-radius: 50%;
    margin-bottom: 32px;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.3s;
}

.about-five-single-icon-box i {
    position: absolute;
    content: "";
    font-size: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--zoso-text-dark);
    transition: all 0.3s;
}

.about-five-single-title-box h3 {
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.6px;
    margin-bottom: 14px;
    padding: 0 18px;
}

.about-five-single-detail-box p {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.2px;
}

.about-five-single-icon-box.icon-box-1 {
    background-color: var(--zoso-text-blue);
}

.about-five-single-icon-box.icon-box-2 {
    background-color: var(--zoso-text-yellow);
}

.about-five-single-icon-box.icon-box-3 {
    background-color: var(--zoso-bg-cyan);
}

.about-five-single-icon-box.icon-box-4 {
    background-color: var(--zoso-bg-purple);
}

.about-five-single-icon-box.icon-box-5 {
    background-color: var(--zoso-text-primary-four);
}

.about-five-single-icon-box.icon-box-6 {
    background-color: var(--zoso-bg-green-three);
}

/***********************
* Video-two
***********************/
.video-two {
    position: relative;
    overflow: hidden;
}

.video-two-img-box {
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.video-two-img-box .scroll_item_select_img img {
    object-fit: cover;
    top: -100px;
}

.video-two-btn {
    position: relative;
    max-width: 100%;
    min-height: 700px;
}

.video-two-btn .video-play {
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: var(--zoso-text-light);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.video-two-btn .video-play i {
    position: absolute;
    font-size: 22px;
    color: var(--zoso-text-dark);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/***********************
* Team-member
***********************/
.team-member-one {
    position: relative;
    padding-top: 130px;
    padding-bottom: 130px;
}


.team-member-one-title-box .section-four-title-title {
    width: 65%;
    margin: 0 auto;
}


.team-member-one-single-box {
    margin-top: 30px;
}

.team-member-one-single-img {
    position: relative;
    margin-bottom: 26px;
}

.team-member-one-single-img img {
    width: 100%;
    max-height: 350px;
    border-radius: 135px;
}

.team-member-one-single-img .team-member-one-single-img-tag {
    position: absolute;
    width: 50px;
    height: 50px;
    right: 15px;
    bottom: 15px;
    margin-top: -60px;
    background-color: var(--zoso-text-light);
    border-radius: 50%;
    box-shadow: 0px 10px 60px 0 rgba(152, 150, 255, 0.50);
}

.team-member-one-single-img-tag>i {
    font-size: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s;
}

.team-member-social {
    position: absolute;
    width: auto;
    height: auto;
    display: block;
    list-style: none;
    padding-left: 0;
    background-color: var(--zoso-text-light);
    border-radius: 30px;
    padding: 15px 15px;
    right: 0;
    bottom: 100%;
    transform: scaleY(0) translateZ(-100px);
    transform-origin: bottom center;
    transition: all 0.3s;
    z-index: 9;
}

.team-member-one-single-img .team-member-one-single-img-tag:hover .team-member-social {
    transform: scaleY(1) translateZ(0px);
}

.team-member-one-single-img-tag:hover>i {
    transform: translate(-50%, -50%) rotate(45deg);
}

.team-member-social li+li {
    padding-top: 12px;
}

.team-member-social li a i {
    color: var(--zoso-text-dark);
    transition: all 0.3s;
    font-size: 18px;
}

.team-member-social li a:hover i {
    color: var(--zoso-secondary-grey);
}

.team-member-one-single-title a {
    display: block;
    color: var(--zoso-text-dark);
    font-family: var(--bs-font-DM-Sans);
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    text-align: center;
    letter-spacing: -0.72px;
    margin-bottom: 3px;
}

.team-member-one-single-detail {
    color: var(--zoso-secondary-grey);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}

.team-member-one-btn {
    margin-top: 60px;
}

/***********************
* Cta-five
***********************/
.cta-five {
    position: relative;
    padding-top: 130px;
    padding-bottom: 130px;
    background: url(../images/background/cta-five-bg.png);
    background-color: var(--zoso-text-yellow);
    background-position-y: center;
    background-size: cover;
    z-index: 99;
    max-width: 1400px;
    margin: 0 auto;
}

.cta-five .container-fluid {}

.cta-five .cta-four-shape-1 {
    width: 30px;
    height: 30px;
    left: 85px;
    bottom: 93px;
    background: none;
}

.cta-four-shape-1-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 30px;
    height: 30px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.7);
}


.cta-five .cta-four-shape-2 {
    right: 50px;
    top: 83px;
    width: 100px;
    height: 100px;
    background: none;
    background-size: contain;
}

.cta-four-shape-2-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100px;
    height: 100px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.7);
}

.cta-five-detail-box .cta-four-detail-box-shape-1 {
    width: 180px;
    height: 250px;
    top: -26px;
    left: 7px;
}

.cta-five-detail-box .cta-four-detail-box-shape-2 {
    width: 180px;
    height: 250px;
    bottom: -48px;
    right: 23px;
}


.cta-five-detail-box .cta-four-detail-box-shape-3 {
    width: 180px;
    height: 250px;
    bottom: -38px;
    left: -63px;
    border: 1.5px solid var(--zoso-text-dark-two);
}

.cta-five-detail-box .cta-four-detail-box-shape-4 {
    width: 180px;
    height: 250px;
    top: -9px;
    right: -32px;
    border: 1.5px solid var(--zoso-text-dark-two);
}

.cta-five-detail-box .section-five-title .section-five-title-title h2 {
    color: var(--zoso-text-dark);
}

.cta-five-detail-box .section-five-details p {
    color: var(--zoso-text-dark);
}

.cta-four-btn .main-black-btn:hover {
    background-color: var(--zoso-text-blue-gradient);
    border: 1px solid var(--zoso-text-blue-gradient);
    color: var(--zoso-text-light);
}

.cta-four-btn .main-black-btn:hover i {
    color: var(--zoso-text-light);
}

/***********************
* Partener-five
***********************/
.partener-five {
    padding-top: 130px;
    padding-bottom: 130px;
}

.partener-five .container-fluid {
    max-width: 1724px;
    margin-left: auto;
    margin-right: auto;
}

.partener-five-title {
    margin-bottom: 63px;
}

.partener-five-title p {
    color: var(--zoso-text-dark);
    font-family: var(--bs-font-DM-Sans);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: -0.48px;
}


.partener-five-single {
    position: relative;
    width: 200px;
    height: 200px;
    border: 1px dashed rgba(15, 17, 15, 0.3);
    border-radius: 50%;
    transition: all 0.3s;
}

.partener-five-single:hover {
    border: 1px dashed var(--zoso-text-dark);
}


.partener-five-single .partener-five-img-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.partener-five-single .partener-five-img-box img {
    max-width: 125px;
    width: 125px;
    height: 30px;
    object-fit: contain;
}

/***********************
* Features-six
***********************/
.features-six {
    padding-top: 53px;
}

.features-six-right-box .section-two-title-title h2,
.features-eight-right-box .section-two-title-title h2 {
    color: var(--zoso-text-dark);
}

.features-six-right-box .section-two-details p,
.features-eight-right-box .section-two-details p {
    color: var(--zoso-secondary-grey);
}

.features-eight-right-box .section-two-details p {
    font-size: 18px;
}

.features-six-right-box .features-three-right-info-box {
    border-top: 1px solid var(--zoso-text-dark-two);
}

.features-six-right-box .features-three-right-info .features-three-right-info-title i::before {
    color: var(--zoso-text-dark);
}

.features-six-right-box .features-three-right-info .features-three-right-info-title i {
    background-color: var(--zoso-text-yellow);
}

.features-six-right-box .features-three-right-info-title h4 {
    color: var(--zoso-text-dark);
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.66px;
}

.features-six-right-box .features-three-right-info-detail p {
    color: var(--zoso-secondary-grey);
}


.features-six .features-three-left-box-shape-1 {
    background: url(../images/shapes/features-six-right-shape-1.png)no-repeat;
    background-size: contain;
}

.features-six .features-three-left-box-shape-2 {
    background: url(../images/shapes/features-six-right-shape-2.png)no-repeat;
    background-size: contain;
}

.features-six .features-three-left-box-shape-3 {
    background: url(../images/shapes/features-six-right-shape-4.png)no-repeat;
    background-size: contain;
}

.features-six .features-three-left-box-shape-4 {
    background: url(../images/shapes/features-six-right-shape-4.png)no-repeat;
    background-size: contain;
}

/***********************
* Case-three
***********************/
.case-three {
    padding-top: 130px;
    padding-bottom: 130px;
}

.case-three .container-fluid {
    max-width: 1855px;
}

.case-three-title-box {
    margin-bottom: 70px;
}

.case-three-title-box .section-three-title .section-three-title-title {
    width: 35%;
    margin-left: auto;
    margin-right: auto;
}

.case-three .section-three-details p {
    font-size: 18px;
    font-weight: 500;
}


.case-three-single {
    position: relative;
    z-index: 9;
    margin: 0 35px 70px;
}

.case-three-single-img-box {
    margin-right: 70px;
}

.case-three-single-img-box img {
    border-radius: 16px;
    width: 100% !important;
}

.case-three-single-details-box {
    position: relative;
    background-color: var(--zoso-text-light);
    box-shadow: 0 10px 40px 0 rgba(152, 150, 255, 0.3);
    margin-left: 200px;
    margin-top: -184px;
    border-radius: 16px;
    padding: 35px 45px;
    transition: all 0.3s;
    z-index: 10;
}


.case-three-single:hover .case-three-single-details-box {
    box-shadow: none;
    background-color: var(--zoso-text-blue);
}


.case-three-single-text p {
    font-family: Lexend Deca;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    color: var(--zoso-secondary-grey);
    margin-bottom: 13px;
    transition: all 0.3s;
}

.case-three-single-title h5 {
    color: var(--zoso-text-dark);
    font-family: var(--bs-font-DM-Sans);
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.72px;
    padding-bottom: 30px;
    border-bottom: 1px solid var(--zoso-text-dark-two);
    margin-bottom: 25PX;
    transition: all 0.3s;
}


.case-three-single-btn .read-more-btn {
    color: var(--zoso-secondary-grey);
}

.case-three-single-btn .read-more-btn i {
    color: var(--zoso-secondary-grey);
}



.case-three-single:hover .case-three-single-text p {
    color: var(--zoso-text-dark);
}

.case-three-single:hover .read-more-btn {
    color: var(--zoso-text-dark);
}

.case-three-single:hover .read-more-btn i {
    color: var(--zoso-text-dark);
}

.case-three-slider .owl-nav.disabled+.owl-dots {
    margin-top: 0;
}

.case-three-slider .owl-dots .owl-dot span {
    width: 5px;
    height: 5px;
    margin: 5px 5px;
    background-color: rgba(15, 17, 15, 0.2);
    display: block;
    transition: opacity .2s ease;
    border-radius: 30px;
}

.case-three-slider .owl-dots .owl-dot.active span,
.case-three-slider .owl-dots .owl-dot:hover span {
    background-color: var(--zoso-text-blue);

}

.case-three-slider .owl-dots .owl-dot.active {
    border: 1px solid var(--zoso-text-blue);

}





.main-wrapper {
    position: relative;
}

.page-wrapper-bg-1 {
    position: absolute;
    background: url(../images/background/page-header-bg-2.png);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position-y: top;
    z-index: -1;
}

.page-wrapper-bg-3 {
    position: absolute;
    background: url(../images/background/page-header-bg-1.png);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position-y: top;
    z-index: -1;
}

.page-wrapper-bg-2 {
    position: absolute;
    background: url(../images/background/page-header-bg-3.png);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position-y: 0;
    z-index: -1;
}

.page-wrapper-bg-4 {
    position: absolute;
    background: url(../images/background/team-member-bg.png);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position-y: 100%;
    background-size: contain;
    z-index: -1;
}

/***********************
* Digital-market
***********************/
.digital-market-content {
    padding-top: 52px;
    position: relative;
}

.digital-market-content-title-box .section-three-title-title {
    width: 95%;
}

.digital-market-content-title-box .section-three-title-title h2 {
    font-size: 65px;
    line-height: 77px;
}

.digital-market-content-title-box .section-three-details p {
    font-size: 18px;
    font-weight: 500;
}

.digital-market-content-img-box {
    margin-top: 60px;
    width: 1170px;
    height: 620px;
    overflow: hidden;
    border-radius: 20px;
}

/***********************
* Features-seven
***********************/
.features-seven {
    position: relative;
    padding-top: 115px;
}

.features-seven-title-box {
    position: relative;
}

.features-seven-title-box .features-one-shape-1 {
    top: 43px;
    left: 98px;
}

.features-seven-title-box .features-one-shape-2 {
    top: 0;
    right: 147px;
}

.features-seven-detail-box .features-one-icon-box {
    margin-left: 0;
    margin-bottom: 34px;
}

.features-seven-detail-box .features-one-title-box h3 {
    margin-bottom: 10px;
}

.features-seven-detail-box .features-one-box {
    padding: 35px 45px;
    border: 1px solid var(--zoso-text-dark-two);
}

/***********************
* Features-eight
***********************/
.features-eight {
    background: none;
}

.features-eight-left-box .features-eight-left-box-shape-1 {
    position: absolute;
    width: 65px;
    height: 65px;
    right: 203px;
    top: 30px;
    background: url(../images/shapes/features-eight-left-box-shape-1.png)no-repeat;
    background-size: contain;
    animation: 4s linear infinite features-three-left-box-shape;
}

.features-eight-left-box .features-eight-left-box-shape-2 {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 217px;
    bottom: 30px;
    background: url(../images/shapes/about-three-left-box-shape-2.png)no-repeat;
    background-size: contain;
    animation: 4s linear infinite features-three-left-box-shape;
}

.features-eight-left-box .features-three-left-img-1,
.features-eight-left-box .features-three-left-img-2 {
    border-radius: 150px;
}

.features-eight-left-box .features-three-left-img-box img {
    width: 270px;
    height: 435px;
    border-radius: 150px;
}

.features-eight-right-info-box {
    margin-top: 45px;
}

.features-eight-right-info-box .features-eight-right-info+.features-eight-right-info {
    margin-top: 15px;
}

.features-eight-right-info {
    display: flex;
    align-items: center;
    padding: 25px 35px;
    background-color: var(--zoso-text-light);
    box-shadow: 0 10px 60px 0 rgba(152, 150, 255, 0.2);
    border-radius: 12px;
}

.features-eight-right-icon {
    position: relative;
    min-width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: var(--zoso-text-yellow);
    margin-right: 30px;
}

.features-eight-right-icon i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
}

.features-eight-right-info-title h5 {
    color: var(--zoso-text-dark);
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.6px;
    margin-bottom: 5px;
}

.features-eight-right-info-title p {
    color: var(--zoso-secondary-grey);
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}

/***********************
* Process-four
***********************/
.process-four {
    padding-bottom: 130px;
}

.process-four-detail-box {
    position: relative;
}

.process-four-detail-box::after {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    left: 32%;
    top: 63%;
    transform: translate(-50%, -50%);
    background: url(../images/shapes/process-four-shape.png)no-repeat;
    background-size: contain;
}

.process-four-detail-box::before {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    left: 67.5%;
    top: 63%;
    transform: translate(-50%, -50%);
    background: url(../images/shapes/process-four-shape.png)no-repeat;
    background-size: contain;
}

.process-four-single-box {
    position: relative;
    padding: 58px 45px 40px 40px;
    border-radius: 15px;
    border: 1px solid var(--zoso-text-dark-two);
    margin-top: 40px;
}

.process-four-single-box-one {
    background-color: var(--zoso-text-blue-two);
}

.process-four-single-box-two {
    background-color: var(--zoso-text-yellow-10);
}

.process-four-single-box-three {
    background-color: var(--zoso-bg-cyan-15);
}

.process-four-single-tag span {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -22px;
    left: 40px;
    width: 50px;
    height: 50px;
    font-size: 22px;
    font-weight: 700;
    color: var(--zoso-text-dark);
    line-height: 30px;
    letter-spacing: -0.3px;
}

.process-four-single-box-one span {
    background: url(../images/shapes/process-three-right-shape-1.png);
}

.process-four-single-box-two span {
    background: url(../images/shapes/process-three-right-shape-2.png);
}

.process-four-single-box-three span {
    background: url(../images/shapes/process-three-right-shape-3.png);
}

.process-four-single-box h5 {
    color: var(--zoso-text-dark);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.6px;
}

.process-four-single-box-one {
    margin-right: 30px;
}

.process-four-single-box-three {
    margin-left: 30px;
}

.process-four-single-box-two {
    margin-left: 15px;
    margin-right: 15px;
}

/***********************
* Joinus-three
***********************/
.joinus-three-left-box {
    margin-right: 85px;
    margin-left: 0;
}

.joinus-three-list-box .joinus-one-list-detali i {
    background-color: rgba(131, 88, 255, 0.15);
}

.joinus-three-list-box .joinus-one-list-detali i::before {
    color: var(--zoso-text-blue-gradient);
}

.joinus-three-right-box {
    position: relative;
}

.joinus-three-right-box:hover .joinus-three-right-img-two {
    transform: translateX(20px)translateY(-50%);
}

.joinus-three-right-box .joinus-three-right-img-one {
    height: 500px;
    width: 370px;
    position: relative;
    overflow: hidden;
    border-radius: 235px;
}

.joinus-three-right-box .joinus-three-right-img-one .photo {
    border-radius: 235px;
}

.joinus-three-right-box .joinus-three-right-img-one img {
    border-radius: 235px;
}

.joinus-three-right-box .joinus-three-right-img-two {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    height: auto;
    transition: all 0.3s;
}

.joinus-three-right-box .joinus-three-right-img-two img {
    width: 310px;
    height: 365px;
    box-shadow: 0px 10px 60px 0 rgba(131, 88, 255, 0.20);
    border-radius: 12px;
}

.joinus-three-info {
    padding-bottom: 130px;
    border-bottom: 1px solid var(--zoso-text-dark-two);
}

/***********************
* Services-details
***********************/
.services-details-content {
    padding-top: 52px;
    position: relative;
}

.services-details-content-shape-1 {
    position: absolute;
    top: 120px;
    left: 134px;
    width: 65px;
    height: 65px;
}

.services-details-content-shape-1-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 65px;
    height: 65px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.75);
}

.services-details-content-shape-2 {
    position: absolute;
    top: 120px;
    right: 100px;
    width: 150px;
    height: 90px;
}

.services-details-content-shape-2-img {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    width: 150px;
    height: 90px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.75);
}

.services-details-content-title-box .section-three-title-title {
    width: 90%;
}

.services-details-content-title-box .section-three-title-title h2 {
    font-size: 65px;
    line-height: 77px;
}

.services-details-content-title-box .section-three-details {
    width: 85%;
}

.services-details-content-title-box .section-three-details p {
    font-size: 22px;
    font-weight: 500;
}

.services-details-content-img-box {
    border-radius: 20px;
    width: 468px;
    height: 560px;
    margin: auto;
    overflow: hidden;
}

.services-details-content-img-box img {
    width: 100%;
}

/***********************
* About-six
***********************/
.about-six {
    position: relative;
    padding-top: 130px;
    padding-bottom: 130px;
    background: url(../images/background/about-pagr-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    z-index: 99;
}

.about-six-shape-1 {
    position: absolute;
    background: url(../images/shapes/testimonial-one-shape-4.png)no-repeat;
    background-size: cover;
    width: 49px;
    height: 60px;
    top: 12%;
    left: 24%;
    z-index: -1;
}

.about-six-shape-2 {
    position: absolute;
    background: url(../images/shapes/features-one-shape-2.png)no-repeat;
    background-size: cover;
    width: 45px;
    height: 50px;
    top: 120px;
    right: 25%;
}

.about-four-title-box .section-three-title-title {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.about-four-title-box .section-three-details p {
    font-size: 18px;
}

.about-six-single-box {
    border: 1px solid var(--zoso-text-dark-two);
}

.about-six-single-box .about-five-single-title-box h3 {
    font-size: 24px;
}

/***********************
* Process-five
***********************/
.process-five {
    padding-top: 130px;
}

.process-five-left .section-three-title-title {
    width: 75%;
}

.process-five-left .section-three-details {
    width: 80%;
}

.process-five-left .section-three-details p {
    font-size: 18px;
}




.process-five-right.process-three-right::before {
    background: url(../images/shapes/process-three-shape-1.png)no-repeat;
    background-size: contain;
}

.process-five-right.process-three-right::after {
    background: url(../images/shapes/process-three-shape-2.png)no-repeat;
    background-size: contain;
}

.process-five-right .process-three-right-info {
    border: 1px solid var(--zoso-text-dark-two);
}

.process-five-right .process-three-right-info h5 {
    color: var(--zoso-text-dark);
}

.process-five-right .process-three-right-info:nth-child(1) {
    background-color: var(--zoso-text-blue-two);
}

.process-five-right .process-three-right-info:nth-child(2) {
    background-color: var(--zoso-text-yellow-10);
}

.process-five-right .process-three-right-info:nth-child(3) {
    background-color: var(--zoso-bg-cyan-10);
}

/***********************
* Process-six
***********************/
.process-six {
    padding-bottom: 130px;
}

.process-six-right-detail {
    display: inline-flex;
    align-items: center;
    padding: 10px 10px 10px 10px;
    border-radius: 40px;
    width: 100%;
}

.process-six-right-detail-one i {
    background-color: #D6BF6A;
}

.process-six-right-detail i {
    position: relative;
    width: 55px;
    height: 55px;
    margin-right: 21px;
    border-radius: 50%;
}

.process-six-right-detail-one i {
    background-color: #D6BF6A;
}

.process-six-right-detail-two i {
    background-color: #01A885;
}

.process-six-right-detail-three i {
    background-color: #BD80FA;
}

.process-six-right-detail-four i {
    background-color: #76BCC9;
}

.process-six-right-detail i::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
}

.process-six-right-detail h4 {
    color: var(--zoso-text-dark);
    font-family: var(--bs-font-DM-Sans);
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.66px;
}

.process-six-right-detail-one {
    background-color: var(--zoso-text-yellow);
}

.process-six-right-detail-two {
    background-color: var(--zoso-text-green);
}

.process-six-right-detail-three {
    background-color: var(--zoso-bg-blue);
    margin-top: 30px;
}

.process-six-right-detail-four {
    background-color: var(--zoso-bg-green-two);
    margin-top: 30px;
}

.process-six-img-box {
    margin-left: 40px;
}

.process-six-img-box img {
    border-radius: 30px;
}


/***********************
* Page-Header-two
***********************/
.page-header-two {
    padding-bottom: 80px;
}

.page-header-two .page-header-inner {
    padding-bottom: 0;
    border-bottom: none;
}

.team-member-two {
    padding-top: 0;
}

.team-member-two .team-member-one-single-box {
    margin-top: 70px;
}

.testimonial-seven {
    padding-top: 130px;
}

/***********************
* Projects
***********************/
.projects-page {
    padding-bottom: 130px;
}

.projects-page .projects-single-box {
    margin-top: 60px;
}

.projects-single-box .projects-single-img {
    margin-bottom: 35px;
    border-radius: 12px;
    overflow: hidden;
}

.projects-single-box:hover .projects-single-img img {
    transform: scale(1.1);
}

.projects-single-box .projects-single-img img {
    border-radius: 12px;
    transition: all 0.3s;
}

.projects-single-detail span {
    display: inline-block;
    color: var(--zoso-secondary-grey);
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 12px;
}

.projects-single-detail h3 a {
    color: var(--zoso-text-dark);
    font-size: 27px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.81px;
    background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 100% / 0px 1px no-repeat;
    transition: all 0.3s;
}

.projects-single-detail h3 a:hover {
    background-size: 100% 1px;
}

.projects-view-more-btn {
    margin-top: 63px;
}




.project-details {
    padding-top: 50px;
    padding-bottom: 130px;
    position: relative;
}

.project-details-title-box .section-three-title-title {
    width: 60%;
}

.project-details-title-box .project-details-title-text span {
    display: inline-block;
    color: var(--zoso-secondary-grey);
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 19px;
    margin-bottom: 5px;
}

.project-details-title-box .section-three-details {
    margin-bottom: 75px;
}

.project-details-title-box .section-three-details p {
    font-size: 18px;
}

.project-details-img img {
    border-radius: 14px;
}

.project-details-info {
    padding-top: 80px;
    padding-bottom: 54px;
    border-bottom: 1px solid var(--zoso-text-dark-two);
    display: flex;
    justify-content: space-between;
}

.project-details-info-item span,
.project-details-bottom-single-details span {
    display: inline-block;
    color: var(--zoso-secondary-grey);
    font-family: Lexend Deca;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
}

.project-details-info-item h3 {
    color: var(--zoso-text-dark);
    font-family: var(--bs-font-DM-Sans);
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.66px;
}

.project-details-inner-title h2,
.project-details-inner-title-two h2 {
    color: var(--zoso-text-dark);
    letter-spacing: 1.35px;
    margin-bottom: 20px;
}

.project-details-inner-title h2 {
    margin-top: 70px;
}

.project-details-inner-title-two h2 {
    margin-top: 50px;
}

.project-details-text p,
.project-details-text-two p {
    color: var(--zoso-secondary-grey);
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}

.project-details-text-two p+p {
    margin-top: 20px;
}

.project-details-text-two {
    padding-bottom: 60px;
}

.project-details-img-box-img {
    margin-top: 70px;
}

.project-details-img-box-img img {
    border-radius: 12px;
}

.project-using-tools {
    margin-right: 105px;
}

.project-using-tools h2,
.project-results h2 {
    color: var(--zoso-text-dark);
    letter-spacing: 1.35px;
    margin-bottom: 20px;
}

.project-using-tools p,
.project-results p {
    color: var(--zoso-secondary-grey);
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}

.project-using-tools-list,
.project-results-chart {
    margin-top: 41px;
}

.project-using-tools-details {
    max-width: 100%;
    padding: 40px 0;
    background-color: var(--zoso-text-blue-two);
    border: 1px solid var(--zoso-text-dark-two);
    border-radius: 85px;
}

.project-using-tools-details h4 {
    margin-top: 25px;
}

.project-using-tools-details h4,
.project-results-chart-img h4 {
    color: var(--zoso-text-dark);
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: -0.54px;
}

.project-results-chart-img h4 {
    margin-bottom: 15px;
}

.project-using-tools-list .row {
    padding-left: 7.5px;
    padding-right: 7.5px;
    margin: 0 -15px;
}

.project-using-tools-list .row>* {
    padding-left: 7.5px;
    padding-right: 7.5px;
}

.project-results {
    margin-left: -10px;
}

.project-results-chart {
    display: flex;
}

.project-results-chart .project-results-chart-img {
    padding: 25px 40px;
    box-shadow: 0 10px 60px 0 rgba(152, 150, 255, 0.15);
    border-radius: 10px;
}

.project-results-chart .project-results-chart-img+.project-results-chart-img {
    margin-left: 25px;
}

.project-details-bottom {
    padding-top: 130px;
    margin-top: 130px;
    border-top: 1px solid var(--zoso-text-dark-two);
    border-bottom: 1px solid var(--zoso-text-dark-two);
    padding-bottom: 130px;
}

.project-details-bottom-single-box {
    display: flex;
    align-items: center;
}

.project-details-bottom-single-box-two {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.project-details-bottom-single-img img {
    border-radius: 12px;
    max-width: 200px;
}

.project-details-bottom-single-details {
    margin-left: 50px;
    margin-right: 25px;
}

.project-details-bottom-single-details span {
    margin-bottom: 11px;
}

.project-details-bottom-single-details h3 {
    color: var(--zoso-text-dark);
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.72px;
}

.project-details-bottom-single-box-two .project-details-bottom-single-details {
    margin-left: 25px;
    margin-right: 50px;
    text-align: end;
}

.related-projects {
    padding-top: 130px;
}

.related-projects .projects-single-box {
    margin-top: 60px;
}

.related-projects .section-three-title {
    margin-bottom: 0;
}

.faq-banner-img,
.pachage-banner-img,
.contact-banner-img {
    padding-top: 50px;
}

/***********************
* Faq
***********************/
.faq-box {
    padding-top: 130px;
    padding-bottom: 130px;
}

.faq-title-box .section-three-title {
    margin-bottom: 24px;
}

.faq-title-box .section-three-details p {
    font-size: 18px;
}

.faq-inner {
    margin: 0 100px;
    padding-top: 25px;
}

.faq-inner .accordion-button {
    padding: 23px 0;
    color: #0F110F;
    font-family: var(--bs-font-DM-Sans);
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.54px;
}

.faq-inner .accordion-button:not(.collapsed) {
    color: var(--twonet-primery) !important;
    background: transparent;
    padding: 23px 0;
}

.faq-inner .accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    border-bottom: 1px solid var(--zoso-text-dark-two);
    background-color: transparent;
}

.faq-inner .accordion-button:focus {
    border: none;
    border-color: transparent;
    box-shadow: none;
}

.faq-inner .accordion-flush .accordion-item:first-child {
    border-top: 1px solid var(--zoso-text-dark-two);
}

.faq-inner .accordion-flush .accordion-item:last-child {
    border-bottom: 1px solid var(--zoso-text-dark-two);
}

.faq-inner .accordion-body {
    padding: 0 0 22px;

}

.faq-inner .accordion-body p {
    color: var(--zoso-secondary-grey);
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    width: 83%;
}

.faq-inner .accordion {
    --bs-accordion-border-width: 0;
    --bs-accordion-bg: transparent;
}

.accordion-button::after {
    display: none;
}

.accordion-button i {
    position: absolute;
    top: 20px;
    right: 0;
    width: 30px;
    height: 30px;
    font-size: 14px;
    background-color: var(--zoso-text-yellow);
    border-radius: 50%;
    transform: rotate(0);
    transition: all 0.3s;
}

.accordion-button i::before {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.accordion-button:not(.collapsed) i {
    background-color: var(--zoso-text-blue);
    transform: rotate(90deg);
}


.cta-six {
    margin-bottom: 130px;
}

/***********************
* Blog-page
***********************/
.blog-page {
    padding-bottom: 130px;
    padding-top: 50px;
}

.blog-img-box img {
    border-radius: 12px;
}

.blog-more {
    padding-top: 30px;
    padding-bottom: 25px;
    border-bottom: 1px solid var(--zoso-text-dark-two);
}

.blog-more ul {
    display: flex;
    align-items: center;
}

.blog-more ul li+li {
    margin-left: 30px;
}

.blog-more ul li.blog-single-name {
    display: flex;
    align-items: center;
}

.blog-more ul li:last-child a,
.blog-more ul li:nth-child(2) p {
    font-family: Lexend Deca;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    color: var(--zoso-secondary-grey);
    transition: all 0.3s;
}

.blog-more ul li:first-child a:hover {
    color: var(--zoso-text-dark-blue);
}

.blog-more ul li:first-child a {
    font-family: Lexend Deca;
    font-size: 16px;
    font-weight: 700;
    line-height: 30px;
    color: var(--zoso-text-dark);
}

.blog-more ul li:nth-child(2) img {
    width: 25px;
    height: 25px;
    margin-right: 15px;
}

.blog-title h3,
.blog-quote-box h3 {
    color: var(--zoso-text-dark);
    font-size: 27px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: -0.81px;
    margin-top: 10px;
}

.blog-text p {
    font-size: 16px;
    color: var(--zoso-secondary-grey);
    font-weight: 400;
    line-height: 30px;
    margin-top: 13px;
    margin-bottom: 32px;
}

.blog-btn {
    margin-bottom: 50px;
}

.blog-quote-box {
    position: relative;
    padding: 35px 50px;
    margin-bottom: 50px;
    background-color: var(--zoso-text-yellow);
    border-radius: 12px;
}

.blog-quote-two-box {
    background: url(../images/shapes/blog-banner-bg-2.png)no-repeat;
    background-color: var(--zoso-text-blue);
    background-size: cover;
}

.blog-quote-box h3 {
    margin-bottom: 66px;
    line-height: 40.5px;
}

.blog-quote-box h3 span {
    position: relative;
}

.blog-quote-box i {
    position: absolute;
    top: 20%;
    right: -70px;
    font-size: 55px;
}

.blog-quote-box h5 {
    color: var(--zoso-text-dark);
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.6px;
    margin-bottom: 5px;
}

.blog-quote-box p {
    color: var(--zoso-text-dark);
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}

.page-numbers ul {
    display: flex;
    margin-top: 20px;
    margin-bottom: 0;
    align-items: center;
    list-style: none;
}

.page-numbers ul .page-numbers-list a {
    position: relative;
    width: 45px;
    height: 45px;
    background-color: var(--zoso-text-light);
    border-radius: 50%;
    border: 1px solid var(--zoso-text-dark-two);
    display: block;
    line-height: 0;
    text-decoration: none;
    transition: all 0.3s;
}

.page-numbers ul .page-numbers-list a span {
    color: var(--zoso-secondary-grey);
    font-size: 20px;
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s;
}

.page-numbers ul li+li {
    margin-left: 15px;
}

.page-numbers ul .page-numbers-list a:hover {
    background-color: var(--zoso-text-dark-blue-gradient);
    border: 1px solid var(--zoso-text-dark-blue-gradient);
}

.page-numbers ul .page-numbers-list a:hover span {
    color: var(--zoso-text-dark);
}

.page-numbers-prev a i {
    transform: rotate(180deg);
    display: block;
}

.widget-box {
    margin-bottom: 42px;
}

/***********************
* Sidebar
***********************/
.sidebar-search h4,
.sidebar-category h4,
.widget-post h4,
.sidebar-tags h4,
.shop-filter-price-title h4,
.sidebar-product-title h4 {
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.66px;
}

.sidebar-search h4 {
    margin-bottom: 18px;
}

.sidebar-category h4,
.sidebar-tags h4,
.sidebar-post h4 {
    margin-bottom: 20px;
}

.shop-filter-price-title h4 {
    margin-bottom: 28px;
}

.sidebar-product-title h4 {
    margin-bottom: 23px;
}

.blog-search,
.shop-search {
    position: relative;
}

.blog-search input,
.shop-search input {
    width: 100%;
    padding: 20px 25px;
    background-color: var(--zoso-text-blue-two);
    border: 1px solid var(--zoso-text-dark-two);
    border-radius: 12px;
    outline: none;
}

.blog-search i,
.shop-search i {
    position: absolute;
    right: 26px;
    top: 50%;
    transform: translateY(-50%);
}

.sidebar-category .btn-right-arrow {
    font-family: Lexend Deca;
    font-size: 18px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    line-height: 23px;
    color: var(--zoso-secondary-grey);
    transition: all 0.3s;
}

.sidebar-category .btn-right-arrow:hover {
    color: var(--zoso-text-dark-blue);
}

.sidebar-category .btn-right-arrow i {
    font-size: 14px;
}

.sidebar-category ul li+li {
    margin-top: 22px;
}

.sidebar-shop-category .btn-right-arrow {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}

.sidebar-shop-category ul li+li {
    margin-top: 12px;
}

.sidebar-post ul li+li {
    margin-top: 30px;
}

.sidebar-post ul li {
    position: relative;
    display: flex;
    align-items: center;
}

.sidebar-post ul li .sidebar-post-image {
    width: 100px;
    height: 100px;
    display: contents;
}

.sidebar-post ul li .sidebar-post-image img {
    border-radius: 12px;
}

.sidebar-post-content {
    margin-left: 30px;
}

.sidebar-post-content span {
    font-family: Lexend Deca;
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    padding-bottom: 7px;
    margin-bottom: 7px;
    color: var(--zoso-secondary-grey);
    border-bottom: 1px solid var(--zoso-text-dark-two);
}

.sidebar-post-content h6 a {
    font-size: 18px;
    display: inline;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: var(--zoso-text-dark);
    background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 100% / 0px 1px no-repeat;
    letter-spacing: -0.3px;
    transition: all 0.3s;
}

.sidebar-post-content h6 a:hover {
    background-size: 100% 1px;
}

.sidebar-tags .sidebar-tags-list {
    margin-left: -10px;
}

.sidebar-tags .sidebar-tags-list a {
    display: inline-block;
    padding: 10px 15px;
    color: var(--zoso-secondary-grey);
    font-family: Lexend Deca;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    border: 1px solid var(--zoso-text-dark-two);
    border-radius: 10px;
    background-color: transparent;
    margin-bottom: 10px;
    transition: all 0.3s;
}

.sidebar-tags .sidebar-tags-list a {
    margin-left: 10px;
}

.sidebar-tags .sidebar-tags-list a:hover {
    color: var(--zoso-text-dark);
    background-color: var(--zoso-text-blue);
    border: 1px solid var(--zoso-text-blue);
}

.sidebar-banner {
    background: url(../images/shapes/blog-banner-bg.png);
    background-size: cover;
    background-color: var(--zoso-text-blue);
    border-radius: 16px;
    position: relative;
    padding-top: 45px;
}

.sidebar-banner-box {
    margin-left: 30px;
    padding-bottom: 187px;
}

.sidebar-banner-box .sidebar-banner-box-title h5 {
    width: 58%;
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.3px
}

.sidebar-banner-box-btn {
    margin-top: 30px;
}

.sidebar-banner-box-btn .main-btn {
    padding: 10px 20px;
    background-color: var(--zoso-text-yellow);
    border: 1px solid var(--zoso-text-yellow);
    color: var(--zoso-text-dark);
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    border-radius: 12px;
}

.sidebar-banner-box-btn .main-btn i {
    color: var(--zoso-text-dark);
}

.sidebar-banner-box-shape-1 {
    position: absolute;
    background: url(../images/blog/sidebar-banner-box-shape-1.png) no-repeat;
    background-size: contain;
    width: 215px;
    height: 269px;
    bottom: 0;
    right: 13px;
}

.sidebar-banner-box-shape-2 {
    position: absolute;
    background: url(../images/shapes/features-one-shape-2.png) no-repeat;
    background-size: contain;
    width: 45px;
    height: 50px;
    top: 30px;
    right: 30px;
}

.sidebar-banner-box-shape-3 {
    position: absolute;
    background: url(../images/shapes/testimonial-one-shape-4.png) no-repeat;
    background-size: contain;
    width: 37px;
    height: 45px;
    bottom: 30px;
    left: 30px;
}

/***********************
* Blog-Detail-page
***********************/
.blog-detail-page,
.shop-page {
    padding-top: 50px;
    padding-bottom: 130px;
}

.shop-details {
    padding-top: 50px;
    padding-bottom: 65px;
}

.blog-detail-header-title-text {
    margin-bottom: 30px;
}

.blog-detail-header-title-text ul {
    display: inline-flex;
    align-items: center;
}

.blog-detail-header-title-text ul li:first-child a {
    font-size: 16px;
    font-weight: 700;
    line-height: 30px;
    font-family: Lexend Deca;
    color: var(--zoso-text-dark-blue);
}

.blog-detail-header-title-text ul li.blog-detail-single-name {
    display: flex;
    align-items: center;
}

.blog-detail-header-title-text ul li:nth-child(2) img {
    width: 25px;
    height: 25px;
    margin-right: 15px;
}

.blog-detail-header-title-text ul li:last-child a,
.blog-detail-header-title-text ul li:nth-child(2) p {
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    color: var(--zoso-secondary-grey);
    transition: all 0.3s;
}

.blog-detail-header-title-text ul li+li {
    margin-left: 30px;
}

.page-header-two .page-header-inner .blog-detail-header-title-title {
    font-size: 45px;
    line-height: 54px;
    letter-spacing: -0.3px;
    margin-bottom: 0;
}




.blog-detail-img-box img {
    border-radius: 12px;
    margin-bottom: 32px;
}

.blog-detail-title h3 {
    color: var(--zoso-text-dark);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.3px;
}

.blog-detail-text p {
    font-size: 16px;
    color: var(--zoso-secondary-grey);
    font-weight: 400;
    line-height: 30px;
    margin-top: 15px;
    margin-bottom: 32px;
    width: 95%;
}

.blog-quote-three-box {
    display: flex;
    padding: 30px 50px 24px;
    background-color: var(--zoso-text-yellow);
    border-radius: 12px;
    margin-bottom: 41px;
    width: 93%;
}

.blog-quote-three-icon {
    margin-right: 40px;
}

.blog-quote-three-icon i {
    font-size: 52px;
}

.blog-quote-three-title h3 {
    color: var(--zoso-text-dark);
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.72px;
    margin-bottom: 28px;
}

.blog-quote-three-title p {
    color: var(--zoso-text-dark);
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    padding-left: 70px;
    position: relative;
}

.blog-quote-three-title p::after {
    content: "";
    position: absolute;
    width: 40px;
    height: 2px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--zoso-text-dark);
}


.blog-details-tags-and-social {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 70px;
    padding-top: 20px;
    padding-bottom: 50px;
    border-bottom: 1px solid var(--zoso-text-dark-two);

}

.blog-details-tags-box {
    position: relative;
    display: flex;
    align-items: center;
}

.blog-details-tags-box h4,
.blog-details-social-box h4 {
    color: var(--zoso-text-dark);
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.6px;
}

.blog-details-tags-list {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 20px;
}


.blog-details-tags-list a {
    display: inline-block;
    padding: 7px 15px;
    color: var(--zoso-secondary-grey);
    font-family: Lexend Deca;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    border: 1px solid var(--zoso-text-dark-two);
    border-radius: 10px;
    background-color: transparent;
    transition: all 0.3s;
}

.blog-details-tags-list a:hover {
    color: var(--zoso-text-dark);
    background-color: var(--zoso-text-blue);
    border: 1px solid var(--zoso-text-blue);
}

.blog-details-tags-list a+a {
    margin-left: 10px;
}

.blog-details-social-box {
    position: relative;
    display: flex;
    align-items: center;
}

.blog-details-social {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 9;
    margin-left: 9px;
}

.blog-details-social li a {
    width: 40px;
    height: 40px;
    background-color: transparent;
    color: var(--zoso-secondary-grey);
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: all 0.3s ease;
    border: 1px solid rgba(15, 17, 15, 0.10);
    border-radius: 50%;
    text-decoration: none;
}

.blog-details-social li a:hover {
    background-color: var(--zoso-text-yellow);
    border: 1px solid var(--zoso-text-yellow);
}

.blog-details-social li a i {
    font-size: 14px;
    color: var(--zoso-secondary-grey);
}

.blog-details-social li a:hover i {
    color: var(--zoso-text-dark);
}



.blog-details-social li+li {
    margin-left: 10px;
}

/***********************
* Author
***********************/
.author {
    position: relative;
    display: flex;
    align-items: center;
    padding: 33px 40px 36px;
    border-radius: 10px;
    margin-bottom: 80px;
    background: var(--zoso-text-blue-two);
    border: 1px solid var(--olbuz-bg-1-5);
}

.author-img {
    position: relative;
    display: block;
    border-radius: 50%;
    max-width: 130px;
    width: 100%;
}

.author-img img {
    width: 100%;
    border-radius: 50%;
}

.author-content {
    position: relative;
    display: block;
    margin-left: 40px;
}

.author-content h4 {
    color: var(--zoso-text-dark);
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.3px;
    margin-bottom: 5px;

}

.author-content p {
    color: var(--zoso-secondary-grey);
    margin-bottom: 21px;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}

.author-social {
    position: relative;
    display: flex;
    align-items: center;
}

.author-social a i {
    color: var(--zoso-secondary-grey);
    transition: all 0.3s;
}

.author-social a i:hover {
    color: var(--zoso-text-dark);
    transition: all 0.3s;
}

.author-social a+a {
    margin-left: 25px;
}




.back-news {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 70px;
}

.back-news-single {
    position: relative;
    display: flex;
    align-items: center;
}

.back-news-img {
    position: relative;
    display: block;
}

.back-news-img img {
    max-width: 100px;
    border-radius: 10px;
}

.back-news-content {
    position: relative;
    display: block;
    margin-left: 25px;
    transition: all 500ms ease;
}

.back-news-content p {
    color: var(--zoso-secondary-grey);
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    border-bottom: 1px solid var(--zoso-text-dark-two);
}

.back-news-content h6 {

    display: inline-block;
    margin-top: 10px;
}

.back-news-content h6 a {
    background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 88%/0px 1px no-repeat;
    transition: all 500ms ease;
    text-decoration: none;
    color: var(--zoso-text-dark);
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.3px;
}

.back-news-content h6 a:hover {
    background-size: 100% 1px;
}

.back-news .back-news-single+.back-news-single {
    padding-left: 84px;
}

/***********************
* Comment
***********************/
.comment-title {
    color: var(--zoso-text-dark);
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.72px;
    margin-bottom: 39px;
}

.comment-single {
    display: flex;
    margin-bottom: 34px;
}

.comment-image {
    position: relative;
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.comment-image img {
    max-width: 100px;
    border-radius: 50%;
}

.comment-content {
    position: relative;
    margin-left: 35px;
}

.comment-title-inner {
    display: flex;
    align-items: center;
    margin-bottom: 11px;
}

.comment-title-inner h6 {
    color: var(--zoso-text-dark);
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.54px;
    margin-right: 20px;
}

.comment-title-inner span {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 0;
    color: var(--zoso-secondary-grey);
}

.comment-text {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    color: var(--zoso-secondary-grey);
}

.post-reply-inner {
    color: var(--zoso-text-dark);
    padding-right: 15px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: inline-flex;
    align-items: center;
    transition: all 0.3s;
}

.post-reply-inner i {
    margin-left: 10px;
    transition: all 0.3s;
    font-size: 16px;
}

.post-reply-inner:hover i {
    padding-left: 20px;
}

.comment-single-two {
    margin-left: 70px;
}



.comment-reply {
    padding-top: 40px;
}

.comment-form {
    padding: 50px;
    background-color: var(--zoso-text-blue-two);
    border-radius: 16px;
}

.comment-form>h3 {
    color: var(--zoso-text-dark);
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.72px;
    margin-bottom: 22px;
}

.comment-area .title,
.comment-respond .title {
    color: var(--zoso-text-dark);
    font-size: 27px;
    font-weight: 600;
    line-height: 41px;
    letter-spacing: -0.3px;
    margin-bottom: 30px;
}

.comment-respond-form {
    margin-top: 30px;
    padding: 50px 30px;
    background-color: var(--zoso-text-blue-two);
    border-radius: 7px;
}

.form-group input,
.form-group textarea {
    width: 100%;
    border: 1px solid transparent;
    border-radius: 7px;
    padding: 18px 20px;
    outline: none;
    background-color: var(--zoso-bg-light);
    margin-bottom: 30px;
}


.form-group textarea {
    resize: none;
    min-height: 150px;
}

/***********************
* Shop
***********************/
.product-search input {
    padding: 14px 20px;
    border-radius: 7px;
}

.shop-filter-price-box {
    position: relative;
    margin-bottom: 30px;
}

.sidebar-product ul li {
    display: flex;
    align-items: center;
}

.sidebar-product ul li+li {
    margin-top: 20px;
}

.sidebar-product-image {
    margin-right: 30px;
}

.sidebar-product-image img {
    border-radius: 7px;
}

.sidebar-product-content .product-rating-star i,
.product-box .product-rating-star i {
    color: #FF9900;
    font-size: 10px;
}

.comment-title-inner .product-rating-star i,
.rating-wrapper .reating-inner i {
    color: #FF9900;
    font-size: 14px;
}

.sidebar-product-content .product-rating-star i+i,
.product-box .product-rating-star i+i,
.comment-title-inner .product-rating-star i {
    padding-left: 2px;
}

.sidebar-product-content h5 a {
    color: var(--zoso-text-dark);
    display: block;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.54px;
    margin-bottom: 3px;
}

.sidebar-product-content h6,
.product-box .product-price h6 {
    color: var(--zoso-secondary-grey);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.48px;
}

.sidebar-banner-two .sidebar-banner-box-shape-2 {
    width: 30px;
    height: 35px;
}

.sidebar-banner-two .sidebar-banner-box-shape-3 {
    width: 29px;
    height: 35px;
}

.sidebar-banner-box-two {
    padding-bottom: 82px;
}

.sidebar-banner-box-two .sidebar-banner-box-title h5 {
    width: 80%;
}

.shop-title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
}

.shop-title-box h4 {
    color: var(--zoso-text-dark);
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.54px;
}

.shop-title-box #pet-select {
    background-color: var(--zoso-text-blue-two);
    border: 1px solid var(--zoso-text-dark-two);
    border-radius: 7px;
    padding: 9px 20px;
    line-height: 30px;
}

.shop-title-box #pet-select select {
    background-color: transparent;
    border: none;
    border-radius: 7px;
    padding: 0;
    outline: none;
    color: var(--zoso-secondary-grey);
    font-family: Lexend Deca;
    font-size: 16px;
    line-height: 30px;
}

.product-box {
    margin-bottom: 65px;
    transition: all 0.3s;
}

.product-img {
    position: relative;
    border-radius: 10px;
    margin-bottom: 30px;
}

.product-img img {
    border-radius: 10px;
    transition: all 0.3s;
}

.product-inner-box {
    position: absolute;
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(152, 150, 255, 0.10);
    border-radius: 10px;
    transition: all 0.5s;
}

.product-add-btn {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: scale(0, 0)translateX(-50%);
    transform-origin: left bottom;
    transition: all 0.5s;
}

.product-add-btn .main-btn {
    min-width: 173px;
    padding: 14px 30px;
}

.product-box:hover .product-img img {
    opacity: 0.3;
}

.product-box:hover .product-inner-box {
    height: 100%;
}

.product-box:hover .product-add-btn {
    transition-delay: 0.3s;
    transform: scale(1, 1) translateX(-50%);
}

.product-box .product-title h5 a {
    color: var(--zoso-text-dark);
    display: block;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.6px;
    margin-bottom: 3px;
}


/* shop-detaile */

.product-single-left-img img,
.product-single-left-bottom-img img {
    width: 100%;
}

.product-single-left-img img {
    border-radius: 12px;
}

.product-single-left-bottom {
    margin-top: 40px;
}

.product-single-left-bottom-img img {
    height: 140px;
    object-fit: cover;
    border-radius: 7px;
}

.product-single-right {
    padding-left: 66px;
}


.product-single-right .product-rating-star {
    margin-bottom: 9px;
}

.product-single-right .product-rating-star i {
    font-size: 18px;
    color: #FF9900;
}

.comment-respond p {
    color: var(--zoso-text-dark);
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    margin-bottom: 16px;
}

.product-single-right .product-rating .product-rating-star i+i {
    padding-left: 7px;
}

.product-single-right .product-name h2 {
    color: var(--zoso-text-dark);
    margin-bottom: 5px;
}

.product-single-right .product-price h5 {
    color: var(--zoso-secondary-grey);
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.72px;
    margin-bottom: 23px;
}

.product-single-right .product-details p,
.product-desc-wrapper .single-desc .desc-text1,
.product-desc-wrapper .single-desc .desc-text2 {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}

.product-single-right .product-details p {
    margin-bottom: 50px;
}


.product-single-right .cart {
    margin-bottom: 75px;
}

.product-categories {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
}

.product-categories h4,
.product-tag h4 {
    color: var(--font-title-color, #0F110F);
    font-family: Lexend Deca;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
}

.product-categories ul {
    padding-left: 30px;
    display: flex;
    align-items: center;
}

.product-categories ul li+li {
    margin-left: 30px;
}

.product-categories ul li a {
    color: var(--zoso-secondary-grey);
    font-family: Lexend Deca;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    transition: all 0.3s;
}

.product-categories ul li a:hover {
    color: var(--zoso-text-dark);
}

.product-categories p>span {
    margin-bottom: 0;
    font-size: 16px;
    color: var(--twonet-primery);
    display: inline;
}

.product-tag {
    display: flex;
    align-items: center;
}

.product-tag-btn {
    display: flex;
    list-style: none;
    margin-bottom: 0;
    padding-left: 29px;
}

.product-tag-btn li+li {
    padding-left: 10px;
}

.product-tag-btn li a {
    color: var(--zoso-text-dark);
    padding: 10px 15px;
    border: 2px solid var(--zoso-text-dark-two);
    font-family: Lexend Deca;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    border-radius: 10px;
    transition: all 0.3s;
}

.product-tag-btn li a:hover {
    color: var(--twonet-text-light);
    background-color: var(--zoso-text-yellow);
    border: 2px solid var(--zoso-text-yellow);
}

.product-desc-reviews {
    margin-top: 73px;
}

.product-desc-reviews-btn {
    margin-bottom: 30px;
    border-bottom: 2px solid var(--zoso-text-dark-two);
}

.product-desc-reviews-btn li+li {
    padding-left: 30px;
}

.product-desc-reviews-btn li .btn,
.product-desc-wrapper .single-desc h3 {
    color: var(--zoso-text-dark);
    display: flex;
    align-items: center;
    gap: 12px;
    font-family: var(--bs-font-DM-Sans);
    font-size: 24px;
    padding: 0 0 22px 0;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.72px;
    border: none;
    border-radius: 0;
    margin-bottom: -2px;
}

.product-desc-reviews-btn li .btn i {
    font-size: 16px;
    transition: all 0.3s;
}

.product-desc-reviews-btn li .btn.active i {
    transform: rotate(-45deg);
}

.product-desc-wrapper .single-desc .desc-text1 {
    margin-bottom: 62px;
    width: 86%;
}



.product-desc-wrapper .single-desc .desc-text2 {
    margin-bottom: 31px;
    width: 90%;
}

.product-desc-reviews-btn li .btn {
    border-bottom: 2px solid transparent;
    display: inline-flex;

}

.product-desc-reviews-btn .btn.active {
    border-bottom: 2px solid var(--zoso-text-dark);
}

.product-desc-reviews-btn .btn.active i::before {
    color: var(--twonet-text-light);
}


.product-desc-advantage li+li {
    padding-top: 15px;
}

.product-desc-advantage li i {
    color: var(--zoso-text-blue);
    padding-right: 15px;
    font-size: 20px;
}

.qty-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 15px;
}

.qty-container .qty-btn-minus,
.qty-container .qty-btn-plus {
    position: absolute;
    border: 1px solid transparent;
    background-color: transparent;
    font-size: 12px;
    transition: 0.3s;
    padding: 0;
    color: #74787C;
}

.qty-container .qty-btn-minus:hover,
.qty-container .qty-btn-plus:hover {
    color: var(--zoso-text-primary-four);
}

.qty-container .qty-btn-minus {
    left: 25px;
}

.qty-container .qty-btn-plus {
    right: 25px;
}

.qty-container .input-qty {
    text-align: center;
    padding: 8.56px 30px;
    border: 2px solid var(--zoso-text-dark-two);
    border-radius: 5px;
    outline: none;
    max-width: 126px;
    color: var(--twonet-primery);
    font-weight: 700;
    font-size: 22px;
    transition: 0.3s;
}

.qty-container:hover .input-qty {
    border: 2px solid var(--zoso-text-primary-four);
}

.product-single-right .cart {
    display: flex;
    position: relative;
}

.related-products {
    margin-top: 120px;
    margin-bottom: 0px;
}

.related-products .section-main-title {
    margin-bottom: 58px;
}

/***********************
* Checkout
***********************/
.checkout-page {
    padding-top: 50px;
    padding-bottom: 130px;
}

.checkout-page .row>* {
    padding: 0 10px;
}

.checkout-page .row {
    margin-left: -10px;
    margin-right: -10px;
}

.input-text {
    padding: 16px 25px;
    font-family: Lexend Deca;
    color: var(--zoso-secondary-grey);
    background-color: var(--zoso-text-blue-two);
    outline: none;
    border: 1px solid var(--zoso-text-dark-two);
    width: 100%;
    border-radius: 5px;
    margin-bottom: 20px;
}

.checkout .checkout-row {
    margin-bottom: 60px;
}

.contact-details .contact-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.delivery-details>input {
    margin-bottom: 0 !important;
}

.contact-details .contact-title p {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}

.contact-details .contact-title .login {
    color: var(--zoso-text-dark);
    font-family: Lexend Deca;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
}

.contact-details input[type="checkbox"],
.add-apartment-details input[type="checkbox"],
.credit-card-inner input[type="checkbox"] {
    accent-color: var(--zoso-text-blue);
    margin-right: 5px;
}

.contact-checkbox-label {
    color: var(--zoso-secondary-grey);
    font-family: Lexend Deca;
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
}

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100%;
}

.bootstrap-select>.dropdown-toggle {
    padding: 0;
}

.bootstrap-select>.dropdown-toggle:focus {
    outline: none !important;
    border: none;
    background: transparent;
}

.bootstrap-select button:hover {
    background-color: transparent;
    border: none;
}

.dropdown-menu.show {
    transform: translate(0px, 54px) !important;
}

.form-control:focus {
    border-color: var(--zoso-text-blue);
    box-shadow: none;
}


.delivery-title h3 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.72px;
}

.contact-title h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.72px;
}

.add-apartment-title h3,
.shipping-method-title h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.54px;
    margin-bottom: 20px;
}

.payment-text p,
.shipping-method-text p {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}

.payment-detailes {
    margin-bottom: 40px;
}

.payment-title h3 {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.72px;
}

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:focus {
    background-color: var(--zoso-text-blue);
    color: var(--zoso-text-light);
}

.dropdown-item:hover {
    color: var(--zoso-text-dark);
    background-color: var(--zoso-text-blue-two);
}

.bootstrap-select>.dropdown-toggle:after {
    display: none;
}

.bootstrap-select>.dropdown-toggle::before {
    position: absolute;
    content: "";
    border-left: 2px solid var(--zoso-text-dark);
    border-top: 2px solid var(--zoso-text-dark);
    width: 8px;
    height: 8px;
    right: 5px;
    top: 48%;
    transform: translateY(-50%) rotate(-135deg);
}

.credit-card-box {
    background-color: var(--zoso-text-blue-two);
    border-radius: 5px;
    border: 1px solid var(--zoso-text-dark-two);
}

.credit-card-box .credit-card {
    background-color: var(--zoso-text-blue);
    padding: 18px 30px;
    border-radius: 5px 5px 0 0;
}

.credit-card-box .credit-card h6 {
    margin-bottom: 0;
    font-family: var(--bs-font-DM-Sans);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.6px;
}

.input-card-text {
    padding: 16px 25px;
    font-family: Lexend Deca;
    background-color: var(--zoso-text-light);
    outline: none;
    border: 1px solid var(--zoso-text-dark-two);
    width: 100%;
    border-radius: 5px;
    margin-bottom: 20px;
}

.credit-card-detaile {
    padding: 30px;
}

.dropdown-toggle.btn-light {
    background-color: transparent;
    border: none;
}

.bootstrap-select .dropdown-menu.inner {
    margin-top: -50px !important;
    padding-bottom: 50px;
}

.add-apartment-card-details .add-apartment-title h6 {
    color: var(--zoso-text-dark);
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    margin-bottom: 20px;
}

.add-apartment-card-details .input-card-text {
    margin-bottom: 0;
}

.checkout-page .sidebar {
    padding-left: 6px;
}

/***********************
* Cart
***********************/
.cart-item {
    padding: 30px 30px;
    background-color: var(--zoso-text-light);
    box-shadow: 0px 10px 60px 0px rgba(99, 99, 99, 0.10);
    border-radius: 10px;
    border: 1px solid #bdbdbd30;
}

.cart-item-list {
    margin-bottom: 40px;
}

.cart-item-list h5 {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.6px;
    margin-bottom: 21px;
}

.cart-item-list-single {
    display: flex;
    align-items: center;
}

.cart-item-product-img {
    width: 80px;
    height: 70px;
    margin-right: 25px;
}

.cart-item-product-img img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.cart-item-product-single p {
    margin-bottom: 3px;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    color: var(--zoso-text-dark);
}

.cart-item-product-single span {
    color: var(--zoso-secondary-grey);
    font-weight: 400;
}

.cart-item-list .cart-item-list-single+.cart-item-list-single {
    margin-top: 20px;
}

.checkout-sidebar .cart-item {
    border: 1px solid transparent;
}

.discount-code {
    position: relative;
}

.discount-code .input-card-text {
    margin-bottom: 0;
    padding: 17px 20px;
}

.discount-code button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.discount-code button.main-btn {
    padding: 9px 30px;
    border-radius: 4px;
    font-size: 16px;
    line-height: normal;
}

.grand-total {
    margin-top: 40px;
}

.grand-total ul {
    padding-left: 10px;
}

.grand-total ul li {
    display: flex;
    justify-content: space-between;
}

.grand-total ul li+li {
    margin-top: 10px;
}

.grand-total ul li p {
    color: var(--zoso-secondary-grey);
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}

.grand-total ul li span {
    color: var(--zoso-text-dark);
    font-family: Lexend Deca;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}

.grand-total ul .total h5,
.grand-total ul .total span {
    color: var(--zoso-text-dark);
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.54px;
}

.checkout-page .theme-btn {
    margin-top: 30px;
}

.main-header-five.sticky-fixed .main-menu-five .header-right-end span:nth-child(1),
.main-header-five.sticky-fixed .main-menu-five .header-right-end span:nth-child(2),
.main-header-five.sticky-fixed .main-menu-five .header-right-end span:nth-child(3) {
    background-color: var(--zoso-bg-light);
}



.page-wrapper-bg-4 {
    position: absolute;
    background: url(../images/background/page-header-bg-4.png)no-repeat;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position-y: top;
    background-size: contain;
    z-index: -1;
}

.cart-details {
    padding-top: 50px;
}

.cart-box,
.cart-total-box {
    background-color: var(--zoso-text-light);
    box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid var(--zoso-text-dark-two);
    border-radius: 12px;
}

.cart-title-box {
    padding: 24px 50px 24px 50px;
    border-bottom: 1px solid var(--zoso-text-dark-two);
}

.cart-product-box .cart-product-single-box,
.cart-product-box .cart-product-btn-box {
    padding: 40px 50px;
}

.cart-product-box .cart-product-single-box+.cart-product-single-box,
.cart-product-box .cart-product-btn-box {
    border-top: 1px solid var(--zoso-text-dark-two);
}

.cart-title-box h4 {
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.6px;
}

.cart-product-title {
    padding-left: 50px;
}

.cart-product-single-box .cart-product-remove a {
    position: relative;
    display: block;
    width: 45px;
    height: 45px;
    background-color: var(--zoso-text-light);
    border-radius: 50%;
    box-shadow: 0 10px 60px 0 #0000001a;
    transition: all 0.3s;
}

.cart-product-single-box .cart-product-remove a:hover {
    background-color: var(--zoso-text-blue);
    box-shadow: 0 10px 60px 0 #0000001a;
}

.cart-product-single-box .cart-product-remove a i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: var(--zoso-text-blue);
    font-size: 11px;
    transition: all 0.3s;
}

.cart-product-single-box .cart-product-remove a:hover i {
    color: var(--zoso-text-light);
}

.cart-product-single-box .cart-product-img img {
    max-width: 85px;
    height: 80px;
    object-fit: contain;
}

.cart-product-single-box .cart-product-name h5,
.cart-product-single-box .cart-product-subtotal h5 {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: -0.66px;
}

.cart-product-single-box .cart-product-price h5,
.cart-product-single-box .cart-product-quantity h5 {
    color: var(--zoso-secondary-grey);
    font-size: 22px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.66px;
}

.cart-product-coupon-box {
    position: relative;
}

.cart-product-coupon-box input[type="text"] {
    position: relative;
    width: 100%;
    padding: 25px 30px;
    background-color: var(--zoso-text-light);
    border: none;
    border-radius: 12px;
    box-shadow: 0 10px 60px 0 #0000001a;
    line-height: 1;
    outline: none;
}

.cart-product-coupon-box .coupon-btn {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}

.cart-product-coupon-btn {
    text-align: end;
}


.cart-total .cart-total-box {
    padding: 41px 50px;
    margin-top: 60px;
}

.cart-total-box h4 {
    line-height: 34px;
    letter-spacing: -0.72px;
    padding-bottom: 22px;
    border-bottom: 1px solid var(--zoso-text-dark-two);
}

.cart-total-detail {
    padding-top: 34px;
}

.cart-total-detail-subtotal,
.cart-total-detail-total {
    display: flex;
    justify-content: space-between;
}

.cart-total-detail-subtotal h5 {
    color: var(--zoso-secondary-grey);
    font-weight: 400;
    line-height: 26px;
}

.cart-total-detail-total {
    margin-top: 23px;
}

.cart-total-detail-total h5 {
    font-weight: 700;
    line-height: 26px;
}

.cart-total-box-btn {
    text-align: end;
    margin-top: 64px;
}



/************************
* Contact-page
************************/

.contact-page {
    padding-top: 130px;
    padding-bottom: 130px;
}

.contact-page-left {
    margin-right: 90px;
}

.contact-page-left .section-main-title h2 {
    font-size: 45px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: -1.35px;
    margin-bottom: 29px;
}

.contact-page-left .section-detail p {
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 31px;
}

.contact-info ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.contact-info ul .contact-inner {
    display: flex;
}

.contact-info ul .contact-inner .icon {
    margin-right: 40px;
    position: relative;
    padding-top: 5px;
}

.contact-info ul .contact-inner .icon i {
    font-size: 40px;
    color: var(--zoso-text-blue);
}

.contact-info ul li+li {
    margin-top: 20px;
}

.contact-info ul li {
    padding: 30px 40px;
    background-color: var(--zoso-bg-light);
    border-radius: 12px;
    box-shadow: 0 10px 60px 0 #0000001a;
}

.contact-info ul .contact-inner .text-box span {
    color: var(--zoso-secondary-grey);
    margin-bottom: 5px;
    font-family: Lexend Deca;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 3px;
}

.contact-info ul .contact-inner .text-box h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.72px;
}

.contact-page-form {
    position: relative;
    padding: 40px 50px 50px;
    background-color: var(--zoso-text-blue-two);
    border-radius: 10px;
    border: 1px solid var(--zoso-text-dark-two);
}

.contact-page-form-shape-one {
    position: absolute;
    background: url(../images/shapes/features-one-shape-2.png)no-repeat;
    background-size: cover;
    width: 45px;
    height: 50px;
    top: 40px;
    right: 60px;
}

.contact-page-form-shape-two {
    position: absolute;
    background: url(../images/shapes/testimonial-one-shape-4.png)no-repeat;
    background-size: cover;
    width: 49px;
    height: 60px;
    bottom: 50px;
    right: 257px;
}

.contact-page-form-text {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 30px
}

.contact-page-input-box textarea {
    resize: none;
    min-height: 150px;
    margin-bottom: 30px;
}

.contact-page-input-box label {
    margin-bottom: 10px;
    font-family: Lexend Deca;
    font-size: 16px;
    font-weight: 400;
    color: var(--zoso-text-dark);
    letter-spacing: -0.2px;
    line-height: 30px;
}

.input-contact-text {
    padding: 16px 25px;
    background-color: var(--zoso-bg-light);
    outline: none;
    border: 1px solid var(--zoso-text-dark-two);
    width: 100%;
    border-radius: 5px;
    margin-bottom: 30px;
    color: #B9B9B9;
}

.contact-page-form form .input-contact-text::placeholder {
    color: #B9B9B9;
}

.contact-page-form-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.72px;
    margin-bottom: 5px;
}

.google-map-one {
    position: relative;
    display: block;
    border: none;
    height: 780px;
    width: 100%;
}

tr,
td,
th {
    padding: 40px 0;
}

.cart-details table {
    width: 100%;
}



.cart-product-img-box {
    display: flex;
    align-items: center;
}

.cart-product-remove {
    margin-right: 30px;
}






.swift-up-text span {
    display: inline-block;
    overflow: hidden;
    animation: .3s swift-up ease-in-out forwards;
}

.swift-up-text i {
    font-style: normal;
    position: relative;
    top: 80px;
    animation: .5s swift-up ease-in-out forwards;
}

@keyframes swift-up {
    to {
        top: 0;
    }
}


.team-member-one-single-img {
    width: 100%;
    height: 350px;
}

.team-member-one-single-img .tile {

    border-radius: 135px;
    overflow: hidden;
}


.tiles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

}

.tile {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;

    overflow: hidden;
}

.photo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: transform .5s ease-out;
}



.animation_image_zoom {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transform-style: preserve-3d;
    transform: translate(-50%, -50%) perspective(600px) rotateY(20deg) rotateX(10deg);
}



.cta-four-detail-box-shape-1-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 180px;
    height: 250px;
    transform: translateZ(150px) translatex(-11px) translateY(-26px) scale(0.7);
}

.cta-four-detail-box-shape-1-img img,
.cta-four-detail-box-shape-2-img img {
    width: 180px;
    height: 250px;
    object-fit: cover;
    object-position: top;
}

.range-wrap {
    position: relative;
}

.bubble {
    background: var(--zoso-bg-light);
    color: var(--zoso-text-dark);
    padding: 9px;
    border: 2px solid var(--zoso-text-yellow);
    position: absolute;
    border-radius: 40px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: var(--font-title-color, #0F110F);
    text-align: center;
    font-family: Lexend Deca;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
}

.range {
    width: 100%;
}

.about-four-bottom-img {
    overflow: hidden;
    border-radius: 20px;
    height: 470px;
    width: 100%;
}

.scroll_item_select_img {
    height: 100%;
    width: 100%;
}

.scroll_item_select_img img {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
    object-fit: cover;
    transform: scale(1.5);
}

.range-slider {
    position: relative;
    width: 100%;
    height: 35px;
    text-align: center;
    margin-bottom: 45px;
}

.range-slider input {
    pointer-events: none;
    position: absolute;
    overflow: hidden;
    left: 0;
    top: 0;
    width: 100%;
    outline: none;
    height: 18px;
    margin: 0;
    padding: 0;
}

.range-slider input::-webkit-slider-thumb {
    pointer-events: all;
    position: relative;
    z-index: 1;
    outline: 0;
}

.range-slider input::-moz-range-thumb {
    pointer-events: all;
    position: relative;
    z-index: 10;
    -moz-appearance: none;
    width: 9px;
}

.range-slider input::-moz-range-track {
    position: relative;
    z-index: -1;
    background-color: var(--zoso-text-blue-two);
    border: 0;
}

.range-slider input:last-of-type::-moz-range-track {
    -moz-appearance: none;
    background: none transparent;
    border: 0;
}

.range-slider input[type=range]::-moz-focus-outer {
    border: 0;
}

.range-slider .rangeValue {
    width: 30px;
}

.range-slider .output {
    position: absolute;
    text-align: center;
    display: inline-block;
    font: bold 15px/30px Helvetica, Arial;
    bottom: -15px;
    left: 50%;
    transform: translate(-50%, 0);
}

.range-slider .output.outputOne {
    color: var(--zoso-secondary-grey);
    font-weight: 400;
    line-height: 24px;
    font-size: 18px;
}

.range-slider .output.outputTwo {
    color: var(--zoso-text-dark);
    line-height: 24px;
    font-size: 18px;
    font-weight: 700;
}

.range-slider .output.outputTwo {
    left: 100%;
}


.range-slider input[type=range] {
    -webkit-appearance: none;
    background: none;
}

.range-slider input[type=range]::-webkit-slider-runnable-track {
    height: 5px;
    border: none;
    border-radius: 3px;
    background: transparent;
}

.range-slider input[type=range]::-ms-track {
    height: 5px;
    background: transparent;
    border: none;
    border-radius: 3px;
}

.range-slider input[type=range]::-moz-range-track {
    height: 5px;
    background: transparent;
    border: none;
    border-radius: 3px;
}

.range-slider input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: var(--zoso-text-blue);
    margin-top: -5px;
    position: relative;
    z-index: 99;
}

.range-slider input[type=range]:focus {
    outline: none;
}

.full-range,
.incl-range {
    width: 100%;
    height: 5px;
    left: 0;
    border-radius: 5px;
    top: 8px;
    position: absolute;
    background-color: var(--zoso-text-blue-two);
}

.incl-range {
    background: var(--zoso-text-blue);
}



.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    /* background: #fff; */

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gap-cl {
    gap: 2rem;
}

.img-cl {
    padding: 3rem;
}

.col-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.slider-item-custom h4 {
    white-space: nowrap;
}

.s-slide {
    width: 500px !important;
    height: 500px !important;
}

.project-result-img {
    border: 3px solid red;
}




.orb {
    width: 600px;
    height: 600px;
    /* background: #999; */
    border-radius: 0.25rem;
    display: inline-block;
    margin: 0 0.5rem;
    transition: all .2s ease-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.orb img{
    border-radius: 16px;
}

.orb:hover {
    transform: scale(1.2);
    cursor: pointer;
}

.marquee {
    height: 550px;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
}

.marquee--inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300%;
    height: 100%;
    position: absolute;
    animation: marquee 20s linear infinite;
}

.marquee--inner:hover {
    animation-play-state: paused;
}

.marquee--inner span {
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    width: 100%;
}

@keyframes marquee {
    0% {
        left: 0;
    }

    100% {
        left: -100%;
    }
}