.gallery {
    columns: 3 auto;
    -webkit-columns: 3 auto;
    -moz-columns: 3 auto;
    padding: 1rem;
}

.gallery .video {
    -webkit-transition: all 400ms ease;
    transition: all 400ms ease;
    cursor: pointer;
    margin-bottom: 12px;
}

.gallery .video:hover {
    transform: scale(0.98);
}

.video-container {
    border-radius: 16px;
    overflow: hidden;
}

.model {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    overflow: hidden;
    z-index: 999;
}

.model .model-close-btn {
    position: fixed;
    top: 24px;
    right: 24px;
    padding: 10px;
    background-color: black;
    color: white;
    font-size: 2rem;
    cursor: pointer;
}

.model .video-container {
    width: auto;
    max-width: 75%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 2rem 0 2rem;
    margin: 2rem auto;
}

@media (max-width: 991px) {
    .gallery {
        columns: 2 auto;
        -webkit-columns: 2 auto;
        -moz-columns: 2 auto;
    }
}

@media (max-width: 480px) {
    .gallery {
        columns: 1 auto;
        -webkit-columns: 1 auto;
        -moz-columns: 1 auto;
    }
}